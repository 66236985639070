import React, { useState } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Container,
    Box,
    Typography,
    Alert,
    Paper,
    CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'; // To get token from URL and navigate after reset
import { useTranslation } from 'react-i18next'; // Translation hook

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation(); // Get the current URL location
    const navigate = useNavigate(); // To redirect after success
    const { t } = useTranslation(); // Translation hook

    // Function to extract the token from query parameters
    const getTokenFromQuery = () => {
        const params = new URLSearchParams(location.search); // Parse the query string
        return params.get('token'); // Get the 'token' parameter
    };

    const token = getTokenFromQuery(); // Extract the token from the URL query params

    // Log the token for debugging
    console.log('Reset token:', token);

    // Handle password input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'password') setPassword(value);
        if (name === 'confirmPassword') setConfirmPassword(value);
        setError(null); // Clear any previous errors
        setSuccess(null); // Clear any previous success message
    };

    // Submit the reset password form
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!token) {
            setError(t('resetPassword.tokenMissing')); // Display token missing error
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError(t('resetPassword.passwordMismatch'));
            setLoading(false);
            return;
        }

        try {
            await axios.post('/api/auth/reset-password', { token, password }); // Include token in the request
            setSuccess(t('resetPassword.success')); // Update success message
            setError(null); // Clear any errors
            setTimeout(() => navigate('/login'), 3000); // Redirect to login page after 3 seconds
        } catch (err) {
            console.error('Error resetting password:', err);
            setSuccess(null); // Clear success message
            if (err.response && err.response.data && err.response.data.msg) {
                setError(err.response.data.msg); // Set the error message from the backend
            } else {
                setError(t('resetPassword.failed')); // Fallback error message
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Paper elevation={6} sx={{ padding: 4, borderRadius: 3, width: '100%', maxWidth: 500, backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4" gutterBottom>{t('resetPassword.title')}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                        {t('resetPassword.instructions')}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2, borderRadius: 1 }}>
                                {error}
                            </Alert>
                        )}
                        {success && (
                            <Alert severity="success" sx={{ mb: 2, borderRadius: 1 }}>
                                {success}
                            </Alert>
                        )}
                        <TextField
                            margin="normal"
                            fullWidth
                            label={t('resetPassword.newPassword')}
                            name="password"
                            type="password"
                            value={password}
                            onChange={handleChange}
                            required
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label={t('resetPassword.confirmPassword')}
                            name="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={handleChange}
                            required
                            sx={{ mb: 2 }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            sx={{
                                mt: 2,
                                py: 1.5,
                                borderRadius: 2,
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('resetPassword.submit')}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
};

export default ResetPassword;
