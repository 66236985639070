import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Container,
    Box,
    Typography,
    Avatar,
    InputAdornment,
    Alert,
    Paper,
    CircularProgress,
    IconButton,
} from '@mui/material';
import { AuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { Email, Lock, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next'; // Import translation hook

function Login() {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resendingVerification, setResendingVerification] = useState(false); // New state for resend
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation(); // Initialize translation hook

    const { email, password } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setError(null); // Clear the error message when the user types
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await axios.post('/api/auth/login', formData);
            login(res.data.user, res.data.token);
            navigate('/');
        } catch (err) {
            console.error('Error logging in:', err);
            // Capture backend error message, fallback to default error if not present
            if (err.response && err.response.data && err.response.data.msg) {
                setError(err.response.data.msg); // Backend error message
            } else {
                setError(t('login.loginFailed')); // Fallback translation for generic error
            }
        } finally {
            setLoading(false);
        }
    };

    // Function to handle resending the verification email
    const resendVerificationEmail = async () => {
        try {
            setResendingVerification(true);
            await axios.post('/api/auth/resend-verification', { email });
            setError(t('login.verificationEmailSent')); // Update error message to show verification email was sent
        } catch (err) {
            console.error('Error resending verification email:', err);
            if (err.response && err.response.data && err.response.data.msg) {
                setError(err.response.data.msg); // Backend error message
            } else {
                setError(t('login.verificationEmailFailed')); // Error handling for failed resend
            }
        } finally {
            setResendingVerification(false);
        }
    };

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Paper elevation={6} sx={{ padding: 4, borderRadius: 3, width: '100%', maxWidth: 500, backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main', width: 56, height: 56 }}>
                        <Person sx={{ fontSize: 32 }} />
                    </Avatar>
                    <Typography variant="h4" gutterBottom>{t('login.signIn')}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                        {t('login.welcomeBack')}
                    </Typography>
                    <Box component="form" onSubmit={onSubmit} sx={{ width: '100%' }}>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2, borderRadius: 1 }}>
                                {error}
                                {error.includes('Email not verified') && (
                                    <Box mt={2}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={resendVerificationEmail} // Trigger the resend function
                                            disabled={resendingVerification} // Disable while resending
                                            sx={{ mt: 1 }}
                                        >
                                            {resendingVerification ? <CircularProgress size={24} /> : t('login.resendVerification')}
                                        </Button>
                                    </Box>
                                )}
                            </Alert>
                        )}
                        <TextField
                            margin="normal"
                            fullWidth
                            label={t('login.email')}
                            name="email"
                            value={email}
                            onChange={onChange}
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label={t('login.password')}
                            name="password"
                            value={password}
                            onChange={onChange}
                            type={showPassword ? 'text' : 'password'}
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ mb: 2 }}
                        />

                        <Typography variant="body2" align="end">
                            <Button
                                color="secondary"
                                onClick={() => navigate('/forgot-password')}  // Update to navigate to the forgot password page
                                sx={{ textTransform: 'none', fontWeight: 'bold' }}
                            >
                                {t('login.forgotPassword')}
                            </Button>
                        </Typography>


                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            sx={{
                                mt: 2,
                                py: 1.5,
                                borderRadius: 2,
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('login.login')}
                        </Button>
                        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                            {t('login.noAccount')}{' '}
                            <Button
                                color="secondary"
                                onClick={() => navigate('/register')}
                                sx={{ textTransform: 'none', fontWeight: 'bold' }}
                            >
                                {t('login.signUp')}
                            </Button>
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}

export default Login;
