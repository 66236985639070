import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Alert,
  Button,
  Grid,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const EmailVerification = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const requestSentRef = useRef(false); // Use useRef to track if the request has been sent
  const location = useLocation();

  // Extract token from query params
  const getTokenFromUrl = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    console.log('Token extracted from URL:', token); // Debug: log the token
    return token;
  };

  useEffect(() => {
    if (requestSentRef.current) return; // If the request has been sent, do nothing

    const verifyEmail = async () => {
      const token = getTokenFromUrl();

      if (!token) {
        setError(true);
        setMessage('Invalid or missing token.');
        setLoading(false);
        console.log('Invalid or missing token.'); // Debug: log missing token
        return;
      }

      try {
        requestSentRef.current = true; // Set the request as sent
        console.log('Sending verification request to backend with token:', token); // Debug: log request
        const response = await axios.post(`/api/auth/verify-email?token=${token}`);
        console.log('Verification response from backend:', response.data); // Debug: log response data

        setMessage(response.data.msg);
        setError(false);
      } catch (err) {
        if (err.response) {
          console.log('Error response from backend:', err.response.data); // Debug: log backend error response
          // Handle the "User is already verified" error
          if (err.response.data.msg === 'User is already verified') {
            setMessage('Your email is already verified. You can log in.');
          } else {
            setMessage(err.response.data.msg);
          }
          setError(true);
        } else {
          console.log('Server error, no response received from backend.'); // Debug: log server error
          setMessage('Server error. Please try again later.');
          setError(true);
        }
      } finally {
        setLoading(false);
        console.log('Verification process complete, loading state set to false.'); // Debug: log completion
      }
    };

    verifyEmail();
  }, [location]); // Use only location as a dependency, requestSentRef prevents multiple requests

  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
        textAlign: 'center',
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress size={80} />
          </Box>
        ) : (
          <Grid item xs={12} sm={10} md={8}>
            {error ? (
              <Alert severity="error" icon={<ErrorIcon />} style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom>
                  {message.includes('already verified') ? 'Already Verified' : 'Verification Failed'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {message}
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => window.location.href = '/login'}
                  style={{ marginTop: '1.5rem', padding: '10px 20px' }}
                >
                  {message.includes('already verified') ? 'Go to Login' : 'Go to Registration'}
                </Button>
              </Alert>
            ) : (
              <Alert severity="success" icon={<CheckCircleIcon />} style={{ padding: '20px', textAlign: 'center' }}>
                <Typography variant="h5" gutterBottom>
                  Email Verified Successfully
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {message}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => window.location.href = '/login'}
                  style={{ marginTop: '1.5rem', padding: '10px 20px' }}
                >
                  Go to Login
                </Button>
              </Alert>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default EmailVerification;
