import React, { useState } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Container,
    Box,
    Typography,
    Alert,
    Paper,
    CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next'; // Translation hook
import { useNavigate } from 'react-router-dom'; // Hook to navigate

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation(); // Translation hook
    const navigate = useNavigate(); // Initialize navigate

    const handleChange = (e) => {
        setEmail(e.target.value);
        setError(null); // Clear any previous errors
        setSuccess(null); // Clear any previous success message
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await axios.post('/api/auth/forgot-password', { email });
            setSuccess(t('forgotPassword.success')); // Update success message
            setError(null); // Clear any errors
        } catch (err) {
            console.error('Error sending forgot password email:', err);
            setSuccess(null); // Clear success message
            if (err.response && err.response.data && err.response.data.msg) {
                setError(err.response.data.msg); // Set the error message from the backend
            } else {
                setError(t('forgotPassword.failed')); // Fallback error message
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Paper elevation={6} sx={{ padding: 4, borderRadius: 3, width: '100%', maxWidth: 500, backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4" gutterBottom>{t('forgotPassword.title')}</Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                        {t('forgotPassword.instructions')}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2, borderRadius: 1 }}>
                                {error}
                            </Alert>
                        )}
                        {success && (
                            <Alert severity="success" sx={{ mb: 2, borderRadius: 1 }}>
                                {success}
                            </Alert>
                        )}
                        <TextField
                            margin="normal"
                            fullWidth
                            label={t('forgotPassword.email')}
                            name="email"
                            value={email}
                            onChange={handleChange}
                            required
                            sx={{ mb: 2 }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            sx={{
                                mt: 2,
                                py: 1.5,
                                borderRadius: 2,
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('forgotPassword.sendLink')}
                        </Button>

                        {/* Add "Return to Login" Button here */}
                        <Button
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            onClick={() => navigate('/login')} // Navigate to login page
                            sx={{
                                mt: 2,
                                textTransform: 'none',
                                fontWeight: 'bold',
                            }}
                        >
                            {t('forgotPassword.returnToLogin')}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
};

export default ForgotPassword;
