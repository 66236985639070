import React, { useEffect, useState } from 'react';
import {
    Typography,
    Container,
    Box,
    AppBar,
    Toolbar,
    CircularProgress,
    Button,
    Grid,
    Card,
    CardContent,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ReplayIcon from '@mui/icons-material/Replay';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import VerifiedIcon from '@mui/icons-material/Verified';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';

// Keyframes for the shake animation
const shake = keyframes`
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(0px, 0px) rotate(0deg); }
`;

// Card flip styling with shake
const FlipCard = styled(Box)(({ shaking }) => ({
    perspective: '1000px',
    width: '100%',
    height: '250px',
    cursor: 'pointer',
    '& .innerCard': {
        position: 'relative',
        width: '100%',
        height: '100%',
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
    },
    '&.flipped .innerCard': {
        transform: 'rotateY(180deg)',
    },
}));

const FlipCardFront = styled(Card)(({ shaking }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    borderRadius: '16px',
    backgroundColor: 'rgba(240, 240, 240, 0.9)',
    ...(shaking && {
        animation: `${shake} 0.5s cubic-bezier(.36,.07,.19,.97) both`,
    }),
}));

const FlipCardBack = styled(Card)(() => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    borderRadius: '16px',
    transform: 'rotateY(180deg)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
}));

function Home() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flippedCards, setFlippedCards] = useState({});
    const [shakingCards, setShakingCards] = useState({});

    useEffect(() => {
        // Random shaking logic
        const shakeInterval = setInterval(() => {
            setShakingCards((prevShakingCards) => {
                const newShakingCards = { ...prevShakingCards };
                products.forEach((_, index) => {
                    if (!flippedCards[index] && Math.random() < 0.7) { // 30% chance to shake
                        newShakingCards[index] = true;
                    } else {
                        newShakingCards[index] = false;
                    }
                });
                return newShakingCards;
            });
        }, 2500); // Every 5 seconds
    
        return () => clearInterval(shakeInterval); // Cleanup on unmount
    }, [products, flippedCards]);

    useEffect(() => {
        // Fetch products from API
        const fetchProducts = async () => {
            try {
                const res = await axios.get('/api/products');
                setProducts(res.data.products);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching products:', err);
                setLoading(false);
            }
        };
        fetchProducts();
    }, []);

    const handleFlip = (index) => {
        setFlippedCards((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#f5f5f5'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            {/* Hero Section */}
            <Box
                sx={{
                    position: 'relative',
                    height: '75vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    overflow: 'hidden',
                }}
            >
                <img
                    src={require('./assets/Banner_hd.png')}
                    alt="Hero"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
            </Box>

            {/* Secondary Navbar for Categories */}
            <AppBar
                position="static"
                sx={{ bgcolor: theme.palette.accent.main, boxShadow: 'none', borderRadius: '0px' }}
            >
                <Toolbar sx={{ justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Button
                        color="inherit"
                        component={Link}
                        to="/products?category=men"
                        sx={{ mx: 1 }}
                    >
                        Men
                    </Button>
                    <Button
                        color="inherit"
                        component={Link}
                        to="/products?category=women"
                        sx={{ mx: 1 }}
                    >
                        Women
                    </Button>
                    <Button
                        color="inherit"
                        component={Link}
                        to="/products?category=sale"
                        sx={{ mx: 1 }}
                    >
                        Sale
                    </Button>
                </Toolbar>
            </AppBar>

            {/* Welcome Section */}
            <Box
                sx={{
                    width: '100%',
                    height: '80vh',
                    py: 6,
                    px: 2,
                    background: 'rgba(255, 255, 255, 0.6)',
                    backdropFilter: 'blur(10px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography variant="h3" fontFamily={"Times New Roman"} gutterBottom sx={{ mb: 4 }}>
                    Welcome to Yesmall – Your Fashion Destination
                </Typography>
                <Typography variant="body1" sx={{ maxWidth: '800px', mb: 2 }}>
                    At Yesmall, we bring you the latest and most stylish clothing for men, women, and children.
                    Whether you're looking for casual wear, formal outfits, or something trendy, we've got it all.
                    Our carefully curated collection combines quality, comfort, and modern designs at affordable prices.
                </Typography>
                <Button
                    component={Link}
                    to="/products"
                    variant="outlined"
                    size='large'
                    color="primary"
                    sx={{ mt: 2 }}
                >
                    Shop Now
                </Button>
            </Box>

            {/* Why Shop from Us Section */}
            <Box sx={{ width: '100%', py: 8, backgroundColor: theme.palette.secondary.main }}>
                <Container>
                    <Typography variant="h3" color="white" gutterBottom align="center" sx={{ mb: 4 }}>
                        Why Shop from Us?
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {[
                            {
                                icon: <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 50 }} />,
                                title: 'Unique Selections',
                                description: 'Explore exclusive fashion pieces you won’t find anywhere else.',
                            },
                            {
                                icon: <AttachMoneyIcon color="primary" sx={{ fontSize: 50 }} />,
                                title: 'Affordable Fashion',
                                description: 'Trendy clothing at prices that suit your budget.',
                            },
                            {
                                icon: <LocalShippingIcon color="primary" sx={{ fontSize: 50 }} />,
                                title: 'Fast Shipping',
                                description: 'Get your items delivered quickly, right to your doorstep.',
                            },
                            {
                                icon: <SupportAgentIcon color="primary" sx={{ fontSize: 50 }} />,
                                title: 'Customer Support',
                                description: 'Our team is here to assist you with any questions or concerns.',
                            },
                            {
                                icon: <AccessibilityNewIcon color="primary" sx={{ fontSize: 50 }} />,
                                title: 'Size Inclusive',
                                description: 'Our clothing comes in various sizes, so everyone can find something that fits them perfectly.',
                            },
                            {
                                icon: <ReplayIcon color="primary" sx={{ fontSize: 50 }} />,
                                title: 'Easy Returns & Exchanges',
                                description: 'We offer hassle-free returns and exchanges to ensure your satisfaction.',
                            },
                        ].map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card
                                    sx={{
                                        p: 2,
                                        height: '100%',
                                        boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                                        borderRadius: '16px',
                                    }}
                                >
                                    <CardContent sx={{ textAlign: 'center' }}>
                                        {item.icon}
                                        <Typography variant="h6" gutterBottom>
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body2">
                                            {item.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            {/* Why Choose Reloved Section */}
            <Grid container sx={{ boxShadow: '0 4px 10px rgba(0,0,0,0.1)' }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            py: 8,
                            px: 2,
                            background: 'rgba(255, 255, 255, 0.6)',
                            backdropFilter: 'blur(10px)',
                            display: 'flex',
                        }}
                        alignItems={'center'}
                    >
                        <Container sx={{ textAlign: 'start' }}>
                            <Typography variant="h3" fontFamily={"Times New Roman"} gutterBottom sx={{ mb: 4 }}>
                                Yesmall Reloved: Pre-owned, Like New!
                            </Typography>
                            <Typography variant="body1" sx={{ maxWidth: '800px', mx: 'auto' }}>
                                We believe in sustainability and giving clothes a second life. Discover our Reloved
                                collection, featuring gently used, high-quality items that are still in great condition, ready
                                for their next chapter with you. Whether you're looking for a trendy piece or a classic staple,
                                our Reloved section has it all at unbeatable prices.
                            </Typography>
                        </Container>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box sx={{ width: '100%', height: '100%', p: 8, backgroundColor: theme.palette.accent.main }}>
                        <Container>
                            <Grid container spacing={2} justifyContent="center">
                                {[
                                    {
                                        icon: <AttachMoneyIcon color="secondary" sx={{ fontSize: 50 }} />,
                                        title: 'Affordable',
                                        description: 'Enjoy designer styles and premium quality for a fraction of the original price.',
                                    },
                                    {
                                        icon: <EnergySavingsLeafIcon color="secondary" sx={{ fontSize: 50 }} />,
                                        title: 'Eco-Friendly',
                                        description: 'Reduce waste and contribute to a more sustainable planet by giving pre-loved items a new home.',
                                    },
                                    {
                                        icon: <VerifiedIcon color="secondary" sx={{ fontSize: 50 }} />,
                                        title: 'Carefully Selected',
                                        description: 'Each item is inspected to ensure it meets our high standards of quality and cleanliness.',
                                    },
                                    {
                                        icon: <ShoppingBasketIcon color="secondary" sx={{ fontSize: 50 }} />,
                                        title: 'Shop Smarter',
                                        description: 'Join the movement and shop smarter with Yesmall Reloved—great fashion doesn’t have to be new to be fabulous!',
                                    },
                                ].map((item, index) => (
                                    <Grid item xs={12} sm={3} md={6} key={index}>
                                        <FlipCard
                                            className={flippedCards[index] ? 'flipped' : ''}
                                            shaking={shakingCards[index]} // Apply shaking if true
                                            onClick={() => handleFlip(index)}
                                            sx={{
                                                width: '100%',
                                                height: '250px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <Box className="innerCard">
                                                <FlipCardFront shaking={shakingCards[index]}>
                                                    {item.icon}
                                                </FlipCardFront>
                                                <FlipCardBack>
                                                    <Box sx={{p:2}}>
                                                        <Typography variant="h6">{item.title}</Typography>
                                                        <Typography variant="body2">{item.description}</Typography>
                                                    </Box>
                                                </FlipCardBack>
                                            </Box>
                                        </FlipCard>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    </Box>
                </Grid>
            </Grid>

            {/* Featured Products Section */}
            <Box
                maxWidth
                sx={{
                    pt: 8,
                    backdropFilter: 'blur(10px)',
                    backgroundColor: theme.palette.secondary.main,
                }}
            >
                <Typography variant="h3" color="white" gutterBottom align="center">
                    Featured Products
                </Typography>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 30,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                    className="mySwiper"
                    style={{ padding: '100px' }}
                >
                    {products.map((product) => (
                        <SwiperSlide key={product._id} style={{ width: '600px' }}>
                            <Link to={`/products/${product._id}`} style={{ textDecoration: 'none' }}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: '16px',
                                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                                        width: '100%',
                                        height: '400px',
                                        transition: 'transform 0.5s',
                                    }}
                                >
                                    <img
                                        src={product.images[0] ? `/api/images/${product._id}/${product.images[0]}` : '/noimg/400x250.png'}
                                        alt={product.name}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            transition: 'transform 0.5s',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                            backdropFilter: 'blur(10px)',
                                            color: 'white',
                                            padding: '10px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography variant="h6">{product.name}</Typography>
                                        <Typography variant="body2">{product.price} MAD</Typography>
                                    </Box>
                                </Box>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </>
    );
}

export default Home;
