import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar, CircularProgress, Alert, Button, Pagination } from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation

function FavoriteProducts() {
    const { t } = useTranslation(); // Initialize useTranslation
    const [favorites, setFavorites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { authState } = useContext(AuthContext);
    const navigate = useNavigate();

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const favoritesPerPage = 5; // Number of favorites to display per page

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const response = await axios.get('/api/favorite', {
                    headers: { Authorization: `Bearer ${authState.token}` }
                });
                setFavorites(response.data.favorites);
            } catch (err) {
                setError(t('favoriteProducts.fetchError'));
                console.error('Error fetching favorites:', err);
            } finally {
                setLoading(false);
            }
        };

        if (authState.isAuthenticated) {
            fetchFavorites();
        }
    }, [authState, t]);

    const handleRemoveFavorite = async (productId) => {
        try {
            await axios.delete(`/api/favorite/${productId}`, {
                headers: { Authorization: `Bearer ${authState.token}` }
            });
            setFavorites(favorites.filter((favorite) => favorite.product._id !== productId));
        } catch (err) {
            console.error('Error removing favorite:', err);
            setError(t('favoriteProducts.removeError'));
        }
    };

    const indexOfLastFavorite = currentPage * favoritesPerPage;
    const indexOfFirstFavorite = indexOfLastFavorite - favoritesPerPage;
    const currentFavorites = favorites.slice(indexOfFirstFavorite, indexOfLastFavorite);

    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box sx={{ backdropFilter: 'blur(10px)', p: 4, backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', minHeight: '80vh' }}>
            <Typography variant="h4" sx={{ mb: 2 }}>{t('favoriteProducts.title')}</Typography>
            {favorites.length === 0 ? (
                <Typography>{t('favoriteProducts.noFavorites')}</Typography>
            ) : (
                <>
                    <List>
                        {currentFavorites.map((favorite) => (
                            <Paper key={favorite.product._id} sx={{ mb: 2, p: 2, borderRadius: '16px' }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar
                                            variant="square"
                                            src={favorite.product.imageUrl || '/noimg/300x300.png'}
                                            alt={favorite.product.name}
                                            sx={{ width: 50, height: 50, marginRight: 2 }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={favorite.product.name}
                                        secondary={`${t('favoriteProducts.price')}: ${favorite.product.price} MAD`}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => navigate(`/products/${favorite.product._id}`)}
                                        sx={{ marginRight: 2 }}
                                    >
                                        {t('favoriteProducts.view')}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleRemoveFavorite(favorite.product._id)}
                                    >
                                        {t('favoriteProducts.remove')}
                                    </Button>
                                </ListItem>
                            </Paper>
                        ))}
                    </List>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Pagination
                            count={Math.ceil(favorites.length / favoritesPerPage)}
                            page={currentPage}
                            onChange={(e, value) => setCurrentPage(value)}
                            color="primary"
                        />
                    </Box>
                </>
            )}
        </Box>
    );
}

export default FavoriteProducts;
