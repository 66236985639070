import React, { createContext, useReducer, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

const initialState = {
    isAuthenticated: false,
    user: null,
    loading: true,
    token: null,
};

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token,
                loading: false,
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null,
                loading: false,
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        case 'SET_USER':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token,
                loading: false,
            };
        case 'CLEAR_USER':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null,
                loading: false,
            };
        default:
            return state;
    }
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            axios.get('/api/auth/me')
                .then((res) => {
                    if (res.status === 200) {
                        dispatch({ type: 'SET_USER', payload: { user: res.data.user, token } });
                    } else {
                        throw new Error('Failed to authenticate');
                    }
                })
                .catch((err) => {
                    console.error('Authentication error:', err);
                    localStorage.removeItem('token');
                    delete axios.defaults.headers.common['Authorization'];
                    dispatch({ type: 'CLEAR_USER' });
                });
        } else {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    }, []);

    const login = (userData, token) => {
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        dispatch({ type: 'LOGIN', payload: { user: userData, token } });
    };

    const logout = () => {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        dispatch({ type: 'LOGOUT' });
    };

    const refetchUser = async () => {
        try {
            const res = await axios.get('/api/auth/me');
            if (res.status === 200) {
                dispatch({ type: 'SET_USER', payload: { user: res.data.user, token: state.token } });
            }
        } catch (error) {
            console.error('Error refetching user data:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ authState: state, login, logout, refetchUser }}>
            {!state.loading && children}
        </AuthContext.Provider>
    );
};
