import React from 'react';
import { Container, Box, Typography } from '@mui/material';

function ReLoved() {
    return (
        <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '90vh' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                    Coming Soon
                </Typography>
                <Typography variant="h5" sx={{ mt: 2, color: 'text.secondary' }}>
                    Stay tuned for our collection of pre-loved, sustainable fashion.
                </Typography>
            </Box>
        </Container>
    );
}

export default ReLoved;
