import React from 'react';
import { Box, Typography } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Box sx={{
            p: 3, maxWidth: '800px', mx: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(10px)', my: 4
        }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
                Welcome to Yesmall! We are committed to protecting your personal data and your privacy.
                This Privacy Policy outlines how we collect, use, and protect your information.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
                1. Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
                We may collect various types of information from you, including:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">
                        Personal Identification Information (Name, email address, phone number, etc.)
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        Usage Data (IP address, browser type, browsing history, etc.)
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">Cookies and tracking technologies.</Typography>
                </li>
            </ul>

            <Typography variant="h5" component="h2" gutterBottom>
                2. How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
                We use the information we collect in the following ways:
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">To provide and maintain our services.</Typography>
                </li>
                <li>
                    <Typography variant="body1">To personalize your experience on our platform.</Typography>
                </li>
                <li>
                    <Typography variant="body1">To communicate with you regarding updates and promotions.</Typography>
                </li>
            </ul>

            <Typography variant="h5" component="h2" gutterBottom>
                3. Sharing of Information
            </Typography>
            <Typography variant="body1" paragraph>
                We do not sell your personal information. However, we may share your information with third-party service providers
                to help us deliver our services (e.g., payment processing, analytics).
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
                4. Security
            </Typography>
            <Typography variant="body1" paragraph>
                We take the security of your personal data seriously and implement appropriate technical and organizational measures to protect it.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
                5. Your Data Protection Rights
            </Typography>
            <Typography variant="body1" paragraph>
                Depending on your location, you may have certain data protection rights, such as the right to access, update, or delete your personal information.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
                6. Changes to This Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
                We may update this Privacy Policy from time to time, and the latest version will always be available on our website.
            </Typography>

            <Typography variant="h5" component="h2" gutterBottom>
                7. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
                If you have any questions about this Privacy Policy, you can contact us at:
            </Typography>
            <Typography variant="body1">
                Email: support@yesmall.com
            </Typography>
            <Typography variant="body1">
                Address: 123 Yesmall St, Fashion City, Morocco
            </Typography>
        </Box>
    );
};

export default PrivacyPolicy;
