import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    CircularProgress,
    List,
    ListItem,
    Paper,
    Alert,
} from '@mui/material';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function Tracking() {
    const location = useLocation(); // Get location object
    const [trackingNumber, setTrackingNumber] = useState(location.state?.trackingNumber || ''); // Set trackingNumber from state if provided
    const [loading, setLoading] = useState(false);
    const [trackingData, setTrackingData] = useState(null);
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        setTrackingNumber(e.target.value);
    };

    const handleTrackingSubmit = async (e) => {
        if (e) e.preventDefault(); // Prevent form submission if event exists

        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(
                `/api/orders/track-order`,
                { trackingNumber },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const data = response.data;

            if (data.success) {
                setTrackingData(data);
            } else {
                setError(data.message || 'Tracking number not found');
            }
        } catch (err) {
            setError('An error occurred while fetching the tracking information.');
        } finally {
            setLoading(false);
        }
    };

    // Automatically submit if trackingNumber is provided via state
    useEffect(() => {
        if (location.state?.trackingNumber) {
            handleTrackingSubmit();
        }
    }, [location.state?.trackingNumber]);

    return (
        <Box
            sx={{
                maxWidth: { xs: '100%', sm: '600px' },
                margin: 'auto',
                my: { xs: 3, sm: 4 },
                p: { xs: 2, sm: 3 },
                textAlign: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(10px), '
            }}
        >
            <Typography variant="h4" gutterBottom>
                Track Your Order
            </Typography>
            <Typography variant="body1" sx={{ mb: { xs: 2, sm: 3 } }}>
                Enter your tracking number to view the latest status of your shipment.
            </Typography>

            <form onSubmit={handleTrackingSubmit}>
                <TextField
                    label="Tracking Number"
                    fullWidth
                    value={trackingNumber}
                    onChange={handleInputChange}
                    variant="outlined"
                    sx={{ mb: { xs: 2, sm: 3 } }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading || !trackingNumber}
                    sx={{ minWidth: '150px', mb: { xs: 2, sm: 3 } }}
                >
                    {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Track'}
                </Button>
            </form>

            {error && (
                <Alert severity="error" sx={{ mt: { xs: 2, sm: 3 } }}>
                    {error}
                </Alert>
            )}

            {trackingData && !error && (
                <Box sx={{ mt: { xs: 3, sm: 4 } }}>
                    <Paper elevation={2} sx={{ p: { xs: 2, sm: 3 }, textAlign: 'center', borderRadius: 4 }}>
                        <Typography variant="h5" sx={{ mb: { xs: 2, sm: 3 } }}>
                            Tracking Number: {trackingData.trackingNumber}
                        </Typography>

                        {/* Display last tracking status */}
                        <Typography variant="h6" color="primary" sx={{ mb: { xs: 2, sm: 3 } }}>
                            Last Status: {trackingData.lastStatus.status} - {trackingData.lastStatus.timestamp}
                        </Typography>

                        {/* Tracking History */}
                        <Typography variant="body1" sx={{ mb: { xs: 2, sm: 3 } }}>
                            Tracking History:
                        </Typography>
                        <List sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 3 }}>
                            {trackingData.history.map((event, index) => (
                                <ListItem
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        bgcolor: 'background.paper',
                                        p: 3,
                                    }}
                                    divider
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: 'bold', mb: 1 }}
                                    >
                                        {event.status}
                                        <Typography variant="body2" color="textSecondary">
                                            {event.timestamp}
                                        </Typography>
                                    </Typography>

                                    {event.comment && (
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{ whiteSpace: 'pre-line' }}
                                        >
                                            {event.comment}
                                        </Typography>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Box>
            )}
        </Box>
    );
}

export default Tracking;
