import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
    Container,
    Box,
    Typography,
    Paper,
    TextField,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { AuthContext } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


function SearchOrder() {
    const { authState } = useContext(AuthContext);
    const [trackingNumber, setTrackingNumber] = useState('');
    const [orderId, setOrderId] = useState(''); // New state for orderId
    const [searchedOrder, setSearchedOrder] = useState(null);
    const [searchLoading, setSearchLoading] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation(); // Initialize useTranslation

    const handleSearchOrder = async () => {
        if (!trackingNumber && !orderId) {
            setError(t('productManagement.provideTrackingOrOrderId'));
            return;
        }

        setSearchLoading(true);
        setSearchedOrder(null); // Clear previous search
        setError(null); // Clear previous errors

        try {
            const params = {};

            // Add trackingNumber or orderId to params if provided
            if (trackingNumber) params.trackingNumber = trackingNumber;
            if (orderId) params.orderId = orderId;

            const res = await axios.get('/api/orders/order-search', {
                params,
                headers: { Authorization: `Bearer ${authState.token}` },
            });

            if (res.data.order) {
                setSearchedOrder(res.data); // Store both order and delivery details
            } else {
                setError(t('productManagement.fetchOrderError'));
            }
        } catch (err) {
            console.error('Error fetching order:', err);
            setError(t('productManagement.fetchOrderError'));
        } finally {
            setSearchLoading(false);
        }
    };

    return (
        <Container maxWidth="lg" sx={{ minHeight: '80vh' }}>
            <Paper elevation={3} sx={{
                height: '100%',
                width: '100%',
                backdropFilter: 'blur(10px)',
                p: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: '16px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)'
            }}>
                <Typography variant="h5" gutterBottom>
                    {t('productManagement.searchOrder')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                    <TextField
                        label={t('productManagement.trackingNumber')}
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        label={t('productManagement.orderId')} // Add input for orderId
                        value={orderId}
                        onChange={(e) => setOrderId(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearchOrder}
                        disabled={searchLoading}
                    >
                        {searchLoading ? <CircularProgress size={24} /> : t('productManagement.search')}
                    </Button>
                </Box>

                {searchedOrder && (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            {t('productManagement.orderDetails')}
                        </Typography>
                        <TableContainer component={Paper} sx={{ borderRadius: '8px', mt: 2 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('productManagement.orderId')}</TableCell>
                                        <TableCell>{t('productManagement.totalAmount')}</TableCell>
                                        <TableCell>{t('productManagement.status')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{searchedOrder.order._id}</TableCell>
                                        <TableCell>{searchedOrder.order.totalAmount} MAD</TableCell>
                                        <TableCell>{searchedOrder.order.status}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Order Items */}
                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                {t('productManagement.orderItems')}
                            </Typography>
                            <TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('productManagement.productId')}</TableCell>
                                            <TableCell>{t('productManagement.productName')}</TableCell>
                                            <TableCell>{t('productManagement.quantity')}</TableCell>
                                            <TableCell>{t('productManagement.price')}</TableCell>
                                            <TableCell>{t('productManagement.total')}</TableCell>
                                            <TableCell>{t('productManagement.variant')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {searchedOrder.order.items.map((item) => (
                                            <TableRow key={item.product?._id || item._id}>
                                                <TableCell>                                                    {item.product?._id ? (
                                                    <Link
                                                        to={`/products/${item.product._id}`}
                                                        style={{ color: '#A27B5C', textDecoration: 'none', fontWeight: 'bold' }} // Add custom styles
                                                        target="_blank"  // Open link in a new tab
                                                        rel="noopener noreferrer" // Security best practice
                                                    >
                                                        {item.product._id}
                                                    </Link>
                                                ) : (
                                                    '-'
                                                )}
                                                </TableCell>
                                                <TableCell>{item.product?.name || '-'}</TableCell>
                                                <TableCell>{item.quantity}</TableCell>
                                                <TableCell>{item.priceAtPurchase} MAD</TableCell>
                                                <TableCell>{(item.quantity * item.priceAtPurchase).toFixed(2)} MAD</TableCell>
                                                <TableCell>{item.variant || '-'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        {/* Redeemed Items */}
                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                {t('productManagement.redeemedItems')}
                            </Typography>
                            <TableContainer component={Paper} sx={{ borderRadius: '8px' }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('productManagement.itemName')}</TableCell>
                                            <TableCell>{t('productManagement.pointsSpent')}</TableCell>
                                            <TableCell>{t('productManagement.product')}</TableCell>
                                            <TableCell>{t('productManagement.size')}</TableCell>
                                            <TableCell>{t('productManagement.color')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {searchedOrder.order.redeemedItems.map((redeemedItem) => (
                                            <TableRow key={redeemedItem.item?._id || redeemedItem._id}>
                                                <TableCell>{redeemedItem.item?.name || '-'}</TableCell>
                                                <TableCell>{redeemedItem.pointsSpent}</TableCell>
                                                <TableCell>{redeemedItem.product || t('productManagement.notApplicable')}</TableCell>
                                                <TableCell>{redeemedItem.size || t('productManagement.notApplicable')}</TableCell>
                                                <TableCell>{redeemedItem.color || t('productManagement.notApplicable')}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        {/* Shipping Details */}
                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                {t('productManagement.shippingDetails')}
                            </Typography>
                            <Box sx={{ ml: 2 }}>
                                <Typography><strong>{t('productManagement.fullName')}:</strong> {searchedOrder.order.shipping.fullName}</Typography>
                                <Typography><strong>{t('productManagement.address')}:</strong> {searchedOrder.order.shipping.addressLine1} {searchedOrder.order.shipping.addressLine2}</Typography>
                                <Typography><strong>{t('productManagement.city')}:</strong> {searchedOrder.order.shipping.city}</Typography>
                                <Typography><strong>{t('productManagement.state')}:</strong> {searchedOrder.order.shipping.state}</Typography>
                                <Typography><strong>{t('productManagement.postalCode')}:</strong> {searchedOrder.order.shipping.postalCode}</Typography>
                                <Typography><strong>{t('productManagement.country')}:</strong> {searchedOrder.order.shipping.country}</Typography>
                                <Typography><strong>{t('productManagement.phoneNumber')}:</strong> {searchedOrder.order.shipping.phoneNumber}</Typography>
                            </Box>
                        </Box>

                        {/* Payment Details */}
                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                {t('productManagement.paymentDetails')}
                            </Typography>
                            <Box sx={{ ml: 2 }}>
                                <Typography><strong>{t('productManagement.method')}:</strong> {searchedOrder.order.payment.method}</Typography>
                                <Typography><strong>{t('productManagement.status')}:</strong> {searchedOrder.order.payment.status}</Typography>
                                <Typography><strong>{t('productManagement.transactionId')}:</strong> {searchedOrder.order.payment.transactionId}</Typography>
                                <Typography><strong>{t('productManagement.amount')}:</strong> {searchedOrder.order.payment.amount} MAD</Typography>
                                <Typography><strong>{t('productManagement.paymentDate')}:</strong> {new Date(searchedOrder.order.payment.paymentDate).toLocaleDateString()}</Typography>
                            </Box>
                        </Box>

                        {/* Delivery Details */}
                        {searchedOrder.delivery && (
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {t('productManagement.deliveryDetails')}
                                </Typography>
                                <Box sx={{ ml: 2 }}>
                                    <Typography><strong>{t('productManagement.receiver')}:</strong> {searchedOrder.delivery.receiver}</Typography>
                                    <Typography><strong>{t('productManagement.address')}:</strong> {searchedOrder.delivery.address}</Typography>
                                    <Typography><strong>{t('productManagement.city')}:</strong> {searchedOrder.delivery.cityName}</Typography>
                                    <Typography><strong>{t('productManagement.phone')}:</strong> {searchedOrder.delivery.phone}</Typography>
                                    <Typography><strong>{t('productManagement.trackingNumber')}:</strong> {searchedOrder.delivery.trackingNumber}</Typography>
                                    <Typography><strong>{t('productManagement.price')}:</strong> {searchedOrder.delivery.price} MAD</Typography>
                                    <Typography><strong>{t('productManagement.deliveredPrice')}:</strong> {searchedOrder.delivery.deliveredPrice} MAD</Typography>
                                    <Typography><strong>{t('productManagement.returnedPrice')}:</strong> {searchedOrder.delivery.returnedPrice} MAD</Typography>
                                    <Typography><strong>{t('productManagement.refusedPrice')}:</strong> {searchedOrder.delivery.refusedPrice} MAD</Typography>
                                    <Typography><strong>{t('productManagement.note')}:</strong> {searchedOrder.delivery.note}</Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}

                {error && (
                    <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Paper>
        </Container>
    );
}

export default SearchOrder;
