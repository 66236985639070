import React, { useState, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    TextField,
    MenuItem,
    Alert,
    Container
} from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../AuthContext';

function AddRedeemItem() {
    const { authState } = useContext(AuthContext);
    const [newItem, setNewItem] = useState({
        name: '', description: '', type: 'FreeDelivery', pointsRequired: '', product: '', discountValue: '', stock: 0,
    });
    const [imageFile, setImageFile] = useState(null); // To store the selected image for Article
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleFileChange = (event) => {
        setImageFile(event.target.files[0]); // Store the selected image file
    };

    const handleAddNewItem = async () => {
        setError(null);
        setSuccess(null);

        if (!newItem.name || !newItem.description || !newItem.pointsRequired || 
            (newItem.type === 'Product' && !newItem.product) || 
            (newItem.type === 'Discount' && !newItem.discountValue)) {
            setError('All fields are required to add a new redeemable item.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('name', newItem.name);
            formData.append('description', newItem.description);
            formData.append('type', newItem.type);
            formData.append('pointsRequired', newItem.pointsRequired);
            formData.append('product', newItem.product);
            formData.append('discountValue', newItem.discountValue);
            formData.append('stock', newItem.stock);

            if (newItem.type === 'Article' && imageFile) {
                formData.append('image', imageFile);
            }

            const response = await axios.post('/api/redeemable-items', formData, {
                headers: {
                    Authorization: `Bearer ${authState.token}`,
                    'Content-Type': 'multipart/form-data', // Ensure the correct content type for file upload
                },
            });

            if (response.status === 201) {
                setSuccess('New redeemable item added successfully!');
                setNewItem({ name: '', description: '', type: 'FreeDelivery', pointsRequired: '', product: '', discountValue: '', stock: 0 });
                setImageFile(null);
            } else {
                setError('Failed to add new item. Please try again.');
            }
        } catch (err) {
            console.error('Error adding new item:', err);
            setError('Error adding new item. Please try again.');
        }
    };

    return (
        <Container maxWidth="lg" sx={{ minHeight: '80vh' }}>
            <Paper elevation={3} sx={{
                p: 4,
                backdropFilter: 'blur(10px)',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: '16px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
            }}>
                <Typography variant="h5" gutterBottom>
                    Add New Redeemable Item
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                {success && (
                    <Alert severity="success" sx={{ mb: 3 }}>
                        {success}
                    </Alert>
                )}

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={newItem.name}
                            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Description"
                            value={newItem.description}
                            onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            select
                            fullWidth
                            label="Type"
                            value={newItem.type}
                            onChange={(e) => setNewItem({ ...newItem, type: e.target.value })}
                        >
                            <MenuItem value="FreeDelivery">Free Delivery</MenuItem>
                            <MenuItem value="Article">Article</MenuItem>
                            <MenuItem value="Product">Product</MenuItem>
                            <MenuItem value="Discount">Discount</MenuItem>
                        </TextField>
                    </Grid>
                    {newItem.type === 'Product' && (
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                label="Product ID"
                                value={newItem.product}
                                onChange={(e) => setNewItem({ ...newItem, product: e.target.value })}
                            />
                        </Grid>
                    )}
                    {newItem.type === 'Discount' && (
                        <Grid item xs={12} sm={3}>
                            <TextField
                                fullWidth
                                label="Discount Value"
                                type="number"
                                value={newItem.discountValue}
                                onChange={(e) => setNewItem({ ...newItem, discountValue: e.target.value })}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Stock"
                            type="number"
                            value={newItem.stock}
                            onChange={(e) => setNewItem({ ...newItem, stock: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            label="Points Required"
                            type="number"
                            value={newItem.pointsRequired}
                            onChange={(e) => setNewItem({ ...newItem, pointsRequired: e.target.value })}
                        />
                    </Grid>

                    {/* Image upload for Article type */}
                    {newItem.type === 'Article' && (
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" sx={{ mb: 1 }}>Upload Image</Typography>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </Grid>
                    )}
                </Grid>

                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleAddNewItem}
                >
                    Add Item
                </Button>
            </Paper>
        </Container>
    );
}

export default AddRedeemItem;
