import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Button, Container, CircularProgress, LinearProgress, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './AuthContext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


function ThankYou() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    // Retrieve order data from location.state
    const orderData = location.state || {};

    const handleContinueShopping = () => {
        navigate('/products');
    };

    return (
        <Container
            maxWidth="md"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '80vh',
                textAlign: 'center',
                py: 4,
                px: 2,
                my: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(10px)'
            }}
        >
            <CheckCircleOutlineIcon
                sx={{
                    fontSize: 64,
                    color: 'success.main',
                    mb: 2,
                    animation: 'fadeIn 0.6s ease-in-out',
                }}
            />

            <Typography variant="h4" sx={{ mb: 2, fontWeight: 400 }}>
                {t('thankYou.title', `Thank you for your order, ${authState.user?.fullName || 'Customer'}!`)}
            </Typography>

            {loading ? (
                <CircularProgress sx={{ mt: 4, color: 'primary.main' }} />
            ) : (
                <Box sx={{ mb: 4, mt: 3 }}>
                    <Typography variant="body1" sx={{ mb: 1, color: 'text.secondary' }}>
                        {t('thankYou.orderId', 'Order ID')}: <strong>{orderData?.orderId}</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1, color: 'text.secondary' }}>
                        {t('thankYou.totalAmount', 'Total')}: <strong>{orderData?.totalAmount} MAD</strong>
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
                        {t('thankYou.estimatedDelivery', 'Estimated Delivery')}: {orderData?.estimatedDelivery}
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleContinueShopping}
                        sx={{
                            width: '80%',
                            mb: 2,
                            textTransform: 'none',
                            boxShadow: 'none',
                            borderRadius: 16,
                            fontSize: '16px',
                        }}
                    >
                        {t('thankYou.continueShopping', 'Continue Shopping')}
                    </Button>

                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate('/')}
                        sx={{
                            width: '80%',
                            textTransform: 'none',
                            borderRadius: 16,
                            fontSize: '16px',
                        }}
                    >
                        {t('thankYou.backToHome', 'Back to Home')}
                    </Button>
                </Box>
            )}
        </Container>
    );
}

export default ThankYou;
