import React from 'react';
import { Box, Typography } from '@mui/material';

const Terms = () => {
  return (
    <Box sx={{ p: 3, maxWidth: '800px', mx: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px',boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',backdropFilter: 'blur(10px)', my:4}}>
      <Typography variant="h4" component="h1" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to Yesmall! By accessing or using our services, you agree to comply with and be bound by the following Terms of Service.
        Please read these terms carefully before using our platform.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing Yesmall or purchasing any products from us, you agree to be bound by these terms, including any updates we may make in the future.
        If you do not agree with any of the terms, you should not use our services.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        2. Accounts and Security
      </Typography>
      <Typography variant="body1" paragraph>
        To access certain features of our site, you may be required to create an account. You are responsible for maintaining the confidentiality of your account
        and password, and for restricting access to your device. You agree to accept responsibility for all activities that occur under your account.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        3. Orders and Payment
      </Typography>
      <Typography variant="body1" paragraph>
        All orders placed through Yesmall are subject to acceptance and availability. We reserve the right to refuse or cancel any order at any time
        for reasons such as product availability, errors in pricing or description, or other issues. You agree to provide accurate and complete
        payment information when placing an order.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        4. Shipping and Delivery
      </Typography>
      <Typography variant="body1" paragraph>
        Shipping times and costs are displayed at checkout. Yesmall is not responsible for any delays caused by customs, shipping carriers, or other external factors.
        We strive to provide accurate delivery estimates, but these are not guaranteed.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        5. Returns and Refunds
      </Typography>
      <Typography variant="body1" paragraph>
        We offer returns and refunds for eligible products under specific conditions. Please refer to our <a href="/return-policy">Return Policy</a> for detailed
        information on how to initiate a return.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        6. User Conduct
      </Typography>
      <Typography variant="body1" paragraph>
        You agree not to use our services for any unlawful purpose or in violation of these Terms of Service. Prohibited activities include, but are not limited to,
        impersonating another person, infringing on intellectual property rights, or engaging in fraudulent activities.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        7. Intellectual Property
      </Typography>
      <Typography variant="body1" paragraph>
        All content on Yesmall, including but not limited to text, graphics, logos, and images, is the property of Yesmall or its content suppliers. You may not
        reproduce, distribute, or create derivative works from any of the content on our platform without express written permission.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        8. Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" paragraph>
        Our services and products are provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the operation
        of our services, the information, content, or materials included on our platform.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        9. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        Yesmall shall not be liable for any damages arising out of or related to your use of our services. This includes, but is not limited to,
        direct, indirect, incidental, punitive, and consequential damages.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        10. Changes to the Terms
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to modify these Terms of Service at any time. Any changes will be posted on this page, and your continued use of our services
        following the posting of changes constitutes your acceptance of those changes.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        11. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        These Terms of Service and any disputes arising from your use of our services shall be governed by and construed in accordance with the laws of Morocco.
      </Typography>

      <Typography variant="h5" component="h2" gutterBottom>
        12. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about these Terms of Service, please contact us at:
      </Typography>
      <Typography variant="body1">
        Email: support@yesmall.com
      </Typography>
      <Typography variant="body1">
        Address: 123 Yesmall St, Fashion City, Morocco
      </Typography>
    </Box>
  );
};

export default Terms;
