import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    Alert,
    Grid,
    IconButton,
    Tooltip,
    CircularProgress,
    Button,
    Snackbar,
    TextField,
    Divider,
    MenuItem,
    Pagination,
} from '@mui/material';
import { AuthContext } from './AuthContext';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DiscountIcon from '@mui/icons-material/Percent';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import axios from 'axios';

function RedeemPage() {
    const { authState } = useContext(AuthContext);
    const [points, setPoints] = useState(authState.user?.points || 0);
    const [redeemableItems, setRedeemableItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchName, setSearchName] = useState('');
    const [searchId, setSearchId] = useState('');
    const [userRedeemedItems, setUserRedeemedItems] = useState([]);
    const [selectedType, setSelectedType] = useState('FreeDelivery');

    const [productDetails, setProductDetails] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Can be 'success' or 'error'

    useEffect(() => {
        fetchRedeemableItems();
    }, [currentPage, searchName, searchId, selectedType]);

    useEffect(() => {
        // Fetch the user's redeemed items when the component loads
        fetchUserRedeemedItems();
    }, []);

    const fetchUserRedeemedItems = async () => {
        try {
            const response = await axios.get('/api/user-redeemed-items', {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            setUserRedeemedItems(response.data);
        } catch (err) {
            console.error('Error fetching user redeemed items:', err);
        }
    };

    const fetchRedeemableItems = async () => {
        setLoading(true);
        setSnackbarMessage(null);

        try {
            let query = `/api/redeemable-items?page=${currentPage}&limit=10&isActive=true`;
            if (searchName) query += `&name=${searchName}`;
            if (searchId) query += `&id=${searchId}`;
            if (selectedType !== 'All') query += `&type=${selectedType}`;

            const response = await axios.get(query, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });

            const { items, totalPages: total } = response.data;
            setRedeemableItems(items);
            setTotalPages(total);

            // Fetch product details for items of type "Product"
            const productPromises = items
                .filter(item => item.type === 'Product' && item.product)
                .map(item => {
                    if (!productDetails[item._id]) {
                        return fetchProductDetails(item.product, item._id);
                    }
                    return null;
                });

            await Promise.all(productPromises);
        } catch (err) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Error fetching redeemable items. Please try again.');
            setOpenSnackbar(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchProductDetails = async (productId, redeemableItemId) => {
        try {
            const productResponse = await axios.get(`/api/products/${productId}`);
            const productData = productResponse.data;

            setProductDetails(prevDetails => ({
                ...prevDetails,
                [redeemableItemId]: {
                    productId: productData._id,
                    sizes: [...new Set(productData.variants.map(variant => variant.size))],
                    colors: [...new Set(productData.variants.map(variant => variant.color))],
                    images: productData.variants.reduce((acc, variant) => {
                        acc[variant.color] = variant.imageUrl[0];
                        return acc;
                    }, {}),
                    defaultImage: `/api/images/${productData._id}/${productData.images[0]}`,
                },
            }));
        } catch (error) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Error fetching product details. Please try again.');
            setOpenSnackbar(true);
        }
    };

    const handleRedeemClick = async (item, selectedSize, selectedColor) => {
        if (points < item.pointsRequired) {
            setSnackbarSeverity('error');
            setSnackbarMessage(`You need at least ${item.pointsRequired} points to redeem this reward.`);
            setOpenSnackbar(true);
        } else {
            // Check how many discounts the user has already redeemed
            const redeemedDiscountsCount = userRedeemedItems.filter(redeemedItem => redeemedItem.redeemItem?.type === 'Discount').length;

            if (item.type === 'Discount' && redeemedDiscountsCount >= 5) {
                setSnackbarSeverity('error');
                setSnackbarMessage('You can only redeem a maximum of 5 discounts.');
                setOpenSnackbar(true);
                return; // Prevent redeeming more discounts
            }
            const hasFreeDelivery = userRedeemedItems.some(redeemedItem => redeemedItem.redeemItem?.type === 'FreeDelivery');

            if (item.type === 'FreeDelivery' && hasFreeDelivery) {
                setSnackbarSeverity('error');
                setSnackbarMessage('You have already redeemed a free delivery. You cannot redeem another one.');
                setOpenSnackbar(true);
                return;
            }

            const data = { redeemItemId: item._id };
            if (item.type === 'Product') {
                data.size = selectedSize;
                data.color = selectedColor;
                data.productId = item.product;
            }

            try {
                const response = await axios.post('/api/redeem', data, {
                    headers: { Authorization: `Bearer ${authState.token}` },
                });

                if (response.data.message === 'Item redeemed successfully') {
                    setSnackbarSeverity('success');
                    setSnackbarMessage(`Successfully redeemed ${item.pointsRequired} points for ${item.name} ! (This item will be included in your next purchase)`);
                    setPoints(points - item.pointsRequired);
                    setOpenSnackbar(true);
                    await fetchUserRedeemedItems();
                } else {
                    setSnackbarSeverity('error');
                    setSnackbarMessage('Failed to redeem points. Please try again.');
                    setOpenSnackbar(true);
                }
            } catch (error) {
                setSnackbarSeverity('error');
                setSnackbarMessage('Error redeeming points. Please try again.');
                setOpenSnackbar(true);
            }
        }
    };

    const renderIcon = (type) => {
        switch (type) {
            case 'Discount':
                return <DiscountIcon sx={{ fontSize: 40 }} />;
            case 'FreeDelivery':
                return <LocalShippingIcon sx={{ fontSize: 40 }} />;
            case 'Product':
                return <EmojiEventsIcon sx={{ fontSize: 40 }} />;
            case 'Article':
                return <EmojiEventsIcon sx={{ fontSize: 40 }} />;
            default:
                return <EmojiEventsIcon sx={{ fontSize: 40 }} />;
        }
    };

    const groupItemsByType = (type) => redeemableItems.filter(item => item.type === type);

    return (
        <Box sx={{ mt: { xs: 3, md: 5 }, mb: { xs: 3, md: 5 }, px: { xs: 2, md: 4 }, maxWidth: 1200, mx: 'auto' }}>
            <Paper elevation={3} sx={{
                p: { xs: 2, sm: 4 },
                backdropFilter: 'blur(10px)',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: '16px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
            }}>
                <Box sx={{ textAlign: 'center', mb: { xs: 2, sm: 3 } }}>
                    <EmojiEventsIcon sx={{ fontSize: { xs: 60, sm: 80 }, color: 'primary.main' }} />
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'text.primary', fontSize: { xs: 'h5.fontSize', sm: 'h4.fontSize' } }}>
                        Redeem Your Points
                    </Typography>
                </Box>

                <Typography variant="h6" sx={{ mb: 4, textAlign: 'center', fontWeight: 'bold', color: 'text.secondary', fontSize: { xs: 'h6.fontSize', sm: 'h5.fontSize' } }}>
                    Your Points Balance: {points}
                </Typography>



                {/* Navbar to filter by type */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                    <Button variant={selectedType === 'FreeDelivery' ? 'contained' : 'outlined'} onClick={() => setSelectedType('FreeDelivery')}>
                        Free Delivery
                    </Button>
                    <Button variant={selectedType === 'Discount' ? 'contained' : 'outlined'} onClick={() => setSelectedType('Discount')}>
                        Discount
                    </Button>
                    <Button variant={selectedType === 'Article' ? 'contained' : 'outlined'} onClick={() => setSelectedType('Article')}>
                        Article
                    </Button>
                    <Button variant={selectedType === 'Product' ? 'contained' : 'outlined'} onClick={() => setSelectedType('Product')}>
                        Product
                    </Button>
                </Box>

                {/* Search Fields */}
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 3 }}>
                    <TextField
                        label="Search by Name"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        fullWidth
                        sx={{ mr: { sm: 2 }, mb: { xs: 2, sm: 0 } }}
                    />
                    <Button variant="contained" sx={{ ml: { sm: 2 }, mt: { xs: 2, sm: 0 } }} onClick={fetchRedeemableItems}>
                        Search
                    </Button>
                </Box>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ mb: 3 }}>
                            {groupItemsByType(selectedType).length > 0 && (
                                <Box sx={{ mb: 4 }}>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, fontSize: { xs: 'h6.fontSize', sm: 'h5.fontSize' } }}>
                                        {selectedType === 'Discount' ? 'Discounts' : selectedType === 'FreeDelivery' ? 'Free Delivery' : selectedType === 'Article' ? 'Articles' : 'Products'}
                                    </Typography>
                                    <Divider sx={{ mb: 3 }} />
                                    <Grid container spacing={2}>
                                        {groupItemsByType(selectedType).map(item => (
                                            <Grid item xs={12} sm={6} md={4} key={item._id}>
                                                {selectedType === 'Product' ? (
                                                    <RedeemableProductCard
                                                        item={item}
                                                        productDetails={productDetails}
                                                        points={points}
                                                        onRedeemClick={handleRedeemClick}
                                                    />
                                                ) : (
                                                    <RedeemableCard
                                                        item={item}
                                                        points={points}
                                                        onRedeemClick={handleRedeemClick}
                                                        renderIcon={renderIcon}
                                                    />
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            )}
                        </Box>

                        {/* Pagination */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={(e, value) => setCurrentPage(value)}
                                color="primary"
                            />
                        </Box>
                    </>
                )}
            </Paper>

            {/* Snackbar for Success/Error Messages */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Box>


    );


}

// Redeemable Card for non-product items (Discount, Free Delivery, Article)
const RedeemableCard = ({ item, points, onRedeemClick, renderIcon }) => {
    const imageUrl = `/api/redeemable-items/images/${item._id}/${item.imageUrl}`;

    return (
        <Paper sx={{
            p: 3,
            textAlign: 'center',
            backgroundColor: item.pointsRequired <= points ? 'primary.light' : 'grey.300',
            borderRadius: '12px',
            color: item.pointsRequired <= points ? 'primary.contrastText' : 'grey.700',
        }}>
            <Tooltip title={item.description}>
                <IconButton
                    onClick={() => onRedeemClick(item)}
                    sx={{ mt: 2, color: item.pointsRequired <= points ? 'primary.contrastText' : 'grey.500' }}
                    disabled={points < item.pointsRequired}
                >
                    {renderIcon(item.type)}
                </IconButton>
            </Tooltip>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, fontSize: { xs: 'body1.fontSize', sm: 'h6.fontSize' } }}>
                {item.name}
            </Typography>

            {/* Show the image only if the item type is 'Article' */}
            {item.type === 'Article' && item.imageUrl && (
                <Box sx={{ mt: 2 }}>
                    <img src={imageUrl} alt={item.name} style={{ width: '100%', borderRadius: '12px' }} />
                </Box>
            )}

            <Typography variant="body2">
                Redeem {item.pointsRequired} points for {item.name}
            </Typography>
        </Paper>
    );
};


// Redeemable Product Card for product items with size and color selection
const RedeemableProductCard = ({ item, productDetails, points, onRedeemClick }) => {
    const details = productDetails[item._id] || {};
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [image, setImage] = useState(details.defaultImage || '');

    useEffect(() => {
        if (selectedColor && details.images && details.productId) {
            const imageUrl = `/api/images/${details.productId}/${details.images[selectedColor]}`;
            setImage(imageUrl);
        }
    }, [selectedColor, details]);

    return (
        <Paper sx={{
            p: 3,
            textAlign: 'center',
            backgroundColor: item.pointsRequired <= points ? 'primary.light' : 'grey.300',
            borderRadius: '12px',
            color: item.pointsRequired <= points ? 'primary.contrastText' : 'grey.700',
        }}>
            {image && (
                <Box sx={{ mt: 1 }}>
                    <img
                        src={image}
                        alt={item.name}
                        style={{ width: '100%', borderRadius: '12px', height: 'auto' }}
                    />
                </Box>
            )}
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1, fontSize: { xs: 'body1.fontSize', sm: 'h6.fontSize' } }}>
                {item.name}
            </Typography>
            <Typography variant="body2">
                Redeem {item.pointsRequired} points for {item.name}
            </Typography>

            <TextField
                select
                label="Size"
                fullWidth
                margin="normal"
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
                required
            >
                {details.sizes?.map((size) => (
                    <MenuItem key={size} value={size}>
                        {size}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                select
                label="Color"
                fullWidth
                margin="normal"
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
                required
            >
                {details.colors?.map((color) => (
                    <MenuItem key={color} value={color}>
                        {color}
                    </MenuItem>
                ))}
            </TextField>

            <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => onRedeemClick(item, selectedSize, selectedColor)}
            >
                Confirm Redeem
            </Button>
        </Paper>
    );
};

export default RedeemPage;
