import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Snackbar
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  StarBorder as StarBorderIcon,
  AccountCircle as AccountCircleIcon,
  People as PeopleIcon,
  ShoppingCart as ShoppingCartIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  LocalOffer as LocalOfferIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  YouTube as YouTubeIcon,
  X as XIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';


function EarnPoints() {
  const { t } = useTranslation();
  const { authState, refetchUser } = useContext(AuthContext);
  const [state, setState] = useState({
    loading: false,
    message: '',
    messageType: '',
    loginStreak: authState.user?.loginStreak || 0,
    points: authState.user?.points || 0,
    socialMediaClicked: {
      facebook: false,
      twitter: false,
      instagram: false,
      linkedin: false,
      youtube: false,
    },
  });


  useEffect(() => {
    const fetchUserData = async () => {
      await refetchUser();
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    // Initialize state with user data
    if (authState.user) {

      setState((prevState) => ({
        ...prevState,
        loginStreak: authState.user.loginStreak,
        points: authState.user.points,
        socialMediaClicked: authState.user.socialMediaClicks || prevState.socialMediaClicked,
      }));
    }
  }, [authState.user]);

  const handleClaimPoints = useCallback(async () => {
    setState((prevState) => ({ ...prevState, loading: true, message: '', messageType: '' }));

    try {
      const response = await axios.post(
        '/api/claim-daily-points',
        {},
        { headers: { Authorization: `Bearer ${authState.token}` } }
      );

      setState((prevState) => ({
        ...prevState,
        message: response.data.message,
        messageType: 'success',
        points: response.data.points,
        loginStreak: response.data.loginStreak,
      }));

      await refetchUser(); // Refresh user data after claiming points
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        message: err.response?.data?.message || t('earnPoints.claimError'),
        messageType: 'error',
      }));
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  }, [authState.token, refetchUser, t]);


  const socialMediaLinks = {
    facebook: 'https://www.facebook.com/',
    x: 'https://x.com/Yesmall_',
    instagram: 'https://www.instagram.com/yesmall_official?igsh=MThvamlwcTEzY2twZA==',
    linkedin: 'https://www.linkedin.com/in/yesmall-mall-135761329/  ',
    youtube: 'https://www.youtube.com/@Yesmall-1',
    whatsapp: 'https://wa.me/message/M5XAGW2GN2TWA1',
  };

  const handleSocialMediaClick = useCallback(
    async (platform) => {
      if (state.socialMediaClicked[platform]) return; // Prevent duplicate clicks

      setState((prevState) => ({ ...prevState, message: '', messageType: '' }));

      try {
        const response = await axios.post(
          `/api/users/${authState.user._id}/social-click`,
          { platform },
          { headers: { Authorization: `Bearer ${authState.token}` } }
        );

        setState((prevState) => ({
          ...prevState,
          message: response.data.message,
          messageType: 'success',
          socialMediaClicked: { ...prevState.socialMediaClicked, [platform]: true },
          points: response.data.points,
        }));

        await refetchUser(); // Refresh user data after social media click
      } catch (err) {
        setState((prevState) => ({
          ...prevState,
          message: err.response?.data?.message || t('earnPoints.claimError'),
          messageType: 'error',
        }));
      }
    },
    [authState.user._id, authState.token, refetchUser, state.socialMediaClicked, t]
  );

  const renderStreakCalendar = useCallback(() => {
    const days = Array.from({ length: 7 }, (_, i) => i + 1);

    return (
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 3, mb: 4 }}>
        {days.map((day) => (
          <Grid item key={day}>
            <Paper
              elevation={3}
              sx={{
                width: 60,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: day <= state.loginStreak ? 'accent.main' : 'grey.300',
                color: day <= state.loginStreak ? 'white' : 'text.secondary',
                borderRadius: '50%',
                transition: 'background-color 0.3s ease',
              }}
            >
              {day <= state.loginStreak ? <CheckCircleIcon /> : <StarBorderIcon />}
            </Paper>
            <Typography variant="caption" align="center" sx={{ display: 'block', mt: 1 }}>
              {t('earnPoints.day')} {day}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  }, [state.loginStreak, t]);

  return (
    <>

      <Container
        maxWidth="md"
        sx={{
          mt: 5,
          mb: 4,
          backdropFilter: 'blur(10px)',
          p: 4,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          borderRadius: '16px',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>

            <Typography variant="h3" gutterBottom>
              {/* <AutoAwesomeIcon sx={{ fontSize: 40, color: 'primary.main', mx: 1 }} /> */}
              {t('earnPoints.title')}
              {/* <AutoAwesomeIcon sx={{ fontSize: 40, color: 'primary.main', mx: 1, transform: 'scaleX(-1)' }} /> */}
            </Typography>
          </Box>
          <Typography variant="body1" align="center">
            {t('earnPoints.description')}
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </Box>



        <Box sx={{ mb: 4 }}>
          <Paper
            elevation={3}

            sx={{
              p: 3,
              textAlign: 'center',
              backgroundColor: 'secondary.main',
              borderRadius: '16px',
              color: "white"
            }}
          >
            <Grid container spacing={2} justifyContent="center" alignItems="center">

              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  {t('earnPoints.totalPoints')}: {state.points}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  {t('earnPoints.currentStreak')}: {state.loginStreak}{' '}
                  {state.loginStreak === 1 ? t('earnPoints.daySingular') : t('earnPoints.dayPlural')}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" sx={{ mt: 2 }}>
              {state.loginStreak >= 7 ? t('earnPoints.greatJob') : t('earnPoints.logInDaily')}
            </Typography>

            {renderStreakCalendar()}

            <Box sx={{ textAlign: 'center', mb: 4 }}>

              <Button
                variant="contained"
                color="accent"
                onClick={handleClaimPoints}
                disabled={state.loading}
                sx={{ py: 1.5, px: 4, fontSize: '1rem', textTransform: 'none', color: 'white' }}
              >
                {state.loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('earnPoints.claimButton')}
              </Button>
            </Box>
          </Paper>


        </Box>






        <Divider sx={{ mb: 4 }} />


        <Typography variant="h5" gutterBottom align="center">
          {t('earnPoints.otherWaysToEarn')}
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <AccountCircleIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={t('earnPoints.register')}
              secondary={t('earnPoints.registerDescription')}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemIcon>
              <PeopleIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={t('earnPoints.referFriends')}
              secondary={t('earnPoints.referFriendsDescription')}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              component={Link}
              to="/profile/referrals"
              sx={{ ml: 2 }}
            >
              {t('earnPoints.viewReferrals')}
            </Button>
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemIcon>
              <ShoppingCartIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={t('earnPoints.makePurchases')}
              secondary={t('earnPoints.makePurchasesDescription')}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem>
            <ListItemIcon>
              <PlayCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={t('earnPoints.watchVideos')}
              secondary={t('earnPoints.watchVideosDescription')}
            />
            <Button variant="contained" color="primary" size="small">
              {t('earnPoints.watchNow')}
            </Button>
          </ListItem>
        </List>

        <Divider sx={{ my: 4 }} />

        {/* Social Media Buttons */}
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            {t('earnPoints.followUs')}
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {['facebook', 'x', 'instagram', 'linkedin', 'youtube', 'whatsapp'].map((platform) => (
              <Grid item key={platform}>
                <a
                  href={socialMediaLinks[platform]}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <IconButton
                    color="primary"
                    onClick={() => handleSocialMediaClick(platform)}
                    sx={{ opacity: state.socialMediaClicked[platform] ? 0.5 : 1 }}
                  >
                    {platform === 'facebook' && <FacebookIcon fontSize="large" />}
                    {platform === 'x' && <XIcon fontSize="large" />}
                    {platform === 'instagram' && <InstagramIcon fontSize="large" />}
                    {platform === 'linkedin' && <LinkedInIcon fontSize="large" />}
                    {platform === 'youtube' && <YouTubeIcon fontSize="large" />}
                    {platform === 'whatsapp' && <WhatsAppIcon fontSize="large" />}
                  </IconButton>
                </a>
              </Grid>
            ))}
          </Grid>
        </Box>

      </Container>

      <Snackbar
        open={!!state.message}
        autoHideDuration={6000}
        onClose={() => setState((prevState) => ({ ...prevState, message: '' }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setState((prevState) => ({ ...prevState, message: '' }))} severity={state.messageType}>
          {state.message}
        </Alert>
      </Snackbar>

    </>
  );
}

export default EarnPoints;
