import React, { useState } from 'react';
import { Container, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Box, Typography, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewListIcon from '@mui/icons-material/ViewList';
import SearchIcon from '@mui/icons-material/Search';
import AddProduct from './AddProduct'; // Your AddProduct component
import ProductList from './ProductList'; // Your ProductList component
import SearchOrder from './SearchOrder'; // Your SearchOrder component
import RedeemableItemsList from './RedeemableItemsList';
import AddRedeemItem from './AddRedeemItem';
import { useTranslation } from 'react-i18next';

function ProductManagement() {
    const [selectedAction, setSelectedAction] = useState('add'); // Default action is 'add'
    const { t } = useTranslation();

    const renderContent = () => {
        switch (selectedAction) {
            case 'add':
                return <AddProduct />;
            case 'addredeem':
                return <AddRedeemItem />;
            case 'redeemlist':
                return <RedeemableItemsList />;
            case 'view':
                return <ProductList onEdit={(product) => setSelectedAction('edit', product)} />;
            case 'search':
                return <SearchOrder />;
            default:
                return <Typography>{t('productManagement.invalidSelection')}</Typography>;
        }
    };

    // Sidebar style and selected item active styling
    const getActiveStyle = (isActive) => ({
        backgroundColor: isActive ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
        borderRadius: isActive ? '8px' : '0',
        color: isActive ? 'primary.main' : 'inherit',
        textDecoration: 'none',
        padding: '10px 20px',
        transition: 'all 0.3s ease',
    });

    return (
        <Container maxWidth="xl" sx={{ minHeight: '80vh', mb: 4 }}>
            <Grid container sx={{ mt: 5 }}>
                {/* Sidebar for navigation */}
                <Grid item xs={12} md={3} sx={{ mb: 4 }}>
                    <Paper elevation={3} sx={{
                        height: '100%',
                        width: '100%',
                        backdropFilter: 'blur(10px)',
                        p: 4,
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '16px',
                        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)'
                    }}>

                        {/* Navigation links */}
                        <List component="nav">
                            <ListItem
                                button
                                selected={selectedAction === 'add'}
                                onClick={() => setSelectedAction('add')}
                                sx={getActiveStyle(selectedAction === 'add')}
                            >
                                <ListItemIcon><AddIcon color="primary" /></ListItemIcon>
                                <ListItemText primary={t('productManagement.addProduct')} />
                            </ListItem>
                            <ListItem
                                button
                                selected={selectedAction === 'view'}
                                onClick={() => setSelectedAction('view')}
                                sx={getActiveStyle(selectedAction === 'view')}
                            >
                                <ListItemIcon><ViewListIcon color="primary" /></ListItemIcon>
                                <ListItemText primary={t('productManagement.viewProducts')} />
                            </ListItem>

                            <Divider sx={{ my: 1 }} />

                            <ListItem
                                button
                                selected={selectedAction === 'addredeem'}
                                onClick={() => setSelectedAction('addredeem')}
                                sx={getActiveStyle(selectedAction === 'addredeem')}
                            >
                                <ListItemIcon><AddIcon color="primary" /></ListItemIcon>
                                <ListItemText primary={t('productManagement.addRedeem')} />
                            </ListItem>
                            <ListItem
                                button
                                selected={selectedAction === 'redeemlist'}
                                onClick={() => setSelectedAction('redeemlist')}
                                sx={getActiveStyle(selectedAction === 'redeemlist')}
                            >
                                <ListItemIcon><ViewListIcon color="primary" /></ListItemIcon>
                                <ListItemText primary={t('productManagement.redeemList')} />
                            </ListItem>

                            <Divider sx={{ my: 1 }} />

                            <ListItem
                                button
                                selected={selectedAction === 'search'}
                                onClick={() => setSelectedAction('search')}
                                sx={getActiveStyle(selectedAction === 'search')}
                            >
                                <ListItemIcon><SearchIcon color="primary" /></ListItemIcon>
                                <ListItemText primary={t('productManagement.searchOrder')} />
                            </ListItem>

                        </List>
                    </Paper>
                </Grid>

                {/* Main content area */}
                <Grid item xs={12} md={9} sx={{ mb: 4 }}>
                    {renderContent()}
                </Grid>
            </Grid>
        </Container>
    );
}

export default ProductManagement;
