import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Typography,
    Container,
    Box,
    MenuItem,
    Collapse,
    Avatar,
    InputAdornment,
    Alert,
    Grid,
    Paper,
    CircularProgress,
    IconButton,
    Checkbox,
    Link,
    FormControlLabel
} from '@mui/material';
import {
    AccountCircle,
    Lock,
    Email,
    CalendarToday,
    LocationOn,
    Phone,
    Person,
    ArrowDropDown,
    ArrowDropUp,
    Visibility,
    VisibilityOff,
} from '@mui/icons-material';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import translation hook

function Register() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        dateOfBirth: '',
        gender: '',
        avatarUrl: '',
        referralCode: '',
        address: {
            fullName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
            country: "Morocco",
            phoneNumber: '',
            isDefault: true,
        },
    });

    const [showAddress, setShowAddress] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');  // This will handle the success message
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const { t } = useTranslation(); // Initialize translation hook

    useEffect(() => {
        if (error) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [error]);

    const { name, email, password, confirmPassword, dateOfBirth, gender, avatarUrl, referralCode, address } = formData;

    const onChange = (e) => {
        if (e.target.name.startsWith('address')) {
            setFormData({
                ...formData,
                address: { ...address, [e.target.name.split('.')[1]]: e.target.value },
            });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
        setError('');
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!agreeToTerms) {
            setError(t('register.mustAgreeToTerms'));
            return;
        }

        if (password !== confirmPassword) {
            setError(t('register.passwordsDoNotMatch'));
            return;
        }

        setLoading(true);
        try {
            await axios.post('/api/auth/register', formData);
            setSuccess(t('register.emailVerificationRequired')); // New success message
            setTimeout(() => {
                navigate('/login');
            }, 5000);
        } catch (err) {
            if (err.response && err.response.status === 400) {
                if (err.response.data.msg === 'User already exists') {
                    setError(t('register.emailExists'));
                }else if(err.response.data.msg){
                    setError(err.response.data.msg)
                }else if (err.response.data.errors) {
                    const errorMessages = err.response.data.errors.map(error => error.msg).join(', ');
                    setError(errorMessages);
                } else {
                    setError(t('register.registrationFailed'));
                }
            } else {
                setError(t('register.registrationFailed'));
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', my: 5 }}>
            <Paper
                elevation={6}
                sx={{
                    padding: { xs: 2, sm: 4 },
                    width: '100%',
                    maxWidth: 600,
                    backdropFilter: 'blur(10px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '16px',
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main', width: 56, height: 56 }}>
                        <Person sx={{ fontSize: 32 }} />
                    </Avatar>
                    <Typography variant="h4" gutterBottom>
                        {t('register.createAccount')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                        {t('register.fillDetails')}
                    </Typography>
                    {error && (
                        <Alert severity="error" sx={{ width: '100%', mb: 2, borderRadius: 1 }}>
                            {error}
                        </Alert>
                    )}
                    {success && (
                        <Alert severity="success" sx={{ width: '100%', mb: 2, borderRadius: 1 }}>
                            {success}
                        </Alert>
                    )}

                    <Box component="form" onSubmit={onSubmit} sx={{ width: '100%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('register.name')}
                                    name="name"
                                    value={name}
                                    onChange={onChange}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('register.email')}
                                    name="email"
                                    value={email}
                                    onChange={onChange}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Email />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('register.password')}
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={password}
                                    onChange={onChange}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} mb={4}>
                                <TextField
                                    fullWidth
                                    label={t('register.confirmPassword')}
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={onChange}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t('register.dateOfBirth')}
                                    type="date"
                                    name="dateOfBirth"
                                    value={dateOfBirth}
                                    onChange={onChange}
                                    InputLabelProps={{ shrink: true }}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CalendarToday />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    select
                                    label={t('register.gender')}
                                    name="gender"
                                    value={gender}
                                    onChange={onChange}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Person />
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    <MenuItem value="male">{t('register.male')}</MenuItem>
                                    <MenuItem value="female">{t('register.female')}</MenuItem>
                                    <MenuItem value="other">{t('register.other')}</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} mb={4}>
                                <TextField
                                    fullWidth
                                    label={t('register.phoneNumber')}
                                    name="address.phoneNumber"
                                    value={address.phoneNumber}
                                    onChange={onChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Phone />
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={t('register.referralCodeOptional')}
                                    name="referralCode"
                                    value={referralCode}
                                    onChange={onChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Person />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => setShowAddress(!showAddress)}
                            sx={{ mt: 3, mb: 2 }}
                            endIcon={showAddress ? <ArrowDropUp /> : <ArrowDropDown />}
                        >
                            {showAddress ? t('register.hideAddress') : t('register.addAddressOptional')}
                        </Button>

                        <Collapse in={showAddress}>
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    {t('register.addressInformation')}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('register.fullName')}
                                            name="address.fullName"
                                            value={address.fullName}
                                            onChange={onChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Person />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('register.addressLine1')}
                                            name="address.addressLine1"
                                            value={address.addressLine1}
                                            onChange={onChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LocationOn />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('register.addressLine2')}
                                            name="address.addressLine2"
                                            value={address.addressLine2}
                                            onChange={onChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LocationOn />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label={t('register.city')}
                                            name="address.city"
                                            value={address.city}
                                            onChange={onChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LocationOn />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label={t('register.state')}
                                            name="address.state"
                                            value={address.state}
                                            onChange={onChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LocationOn />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label={t('register.postalCode')}
                                            name="address.postalCode"
                                            value={address.postalCode}
                                            onChange={onChange}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LocationOn />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label={t('register.country')}
                                            name="address.country"
                                            value="Morocco"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LocationOn />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    {t('register.agreeTo')}{' '}
                                    <Link
                                        component={RouterLink}
                                        to="/terms-and-conditions" // Replace with your actual route
                                        target="_blank"
                                        rel="noopener"
                                        sx={{ textDecoration: 'none', color: 'primary.main' }}
                                    >
                                        {t('register.termsAndConditions')}
                                    </Link>
                                </Typography>
                            }

                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            sx={{
                                mt: 3,
                                py: 1.5,
                                borderRadius: 2,
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('register.register')}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}

export default Register;
