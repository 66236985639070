import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const { authState } = useContext(AuthContext);
    const [cartItems, setCartItems] = useState([]);
    const [isCartLoaded, setIsCartLoaded] = useState(false);

    // Helper function to get the cart key based on user
    const getCartKey = () => {
        return authState.isAuthenticated ? `cartItems_${authState.user._id}` : 'cartItems_guest';
    };

    // Load cart items from localStorage when the component mounts or user changes
    useEffect(() => {
        if (!authState.loading) {
            const storedCartItems = localStorage.getItem(getCartKey());
            if (storedCartItems) {
                setCartItems(JSON.parse(storedCartItems));
            }
            setIsCartLoaded(true);
        }
    }, [authState]);

    // Save cart items to localStorage whenever the cart is updated, after the cart is initialized
    useEffect(() => {
        if (isCartLoaded) {
            if (cartItems.length > 0) {
                localStorage.setItem(getCartKey(), JSON.stringify(cartItems));
            } else {
                localStorage.removeItem(getCartKey());
            }
        }
    }, [cartItems, authState, isCartLoaded]);

    // Function to refresh the cart from localStorage
    const refreshCart = () => {
        const storedCartItems = localStorage.getItem(getCartKey());
        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        } else {
            setCartItems([]);
        }
    };

    const addToCart = (product, selectedColor, selectedSize, quantity = 1) => {
        const selectedVariant = product.variants?.find(variant =>
            variant.color === selectedColor && variant.size === selectedSize
        );
    
        if (!selectedVariant) {
            console.error('Selected variant not found');
            return;
        }
    
        // Check if the available stock is sufficient for the requested quantity
        if (selectedVariant.stock < quantity) {
            console.error('Not enough stock for the selected product variant');
            return;
        }
    
        setCartItems((prevItems) => {
            const existingItem = prevItems.find(
                item =>
                    item._id === product._id &&
                    item.selectedColor === selectedColor &&
                    item.selectedSize === selectedSize
            );
    
            if (existingItem) {
                // Check if updating the existing quantity exceeds the available stock
                if (selectedVariant.stock < existingItem.quantity + quantity) {
                    console.error('Not enough stock to update the quantity');
                    return prevItems; // Return the current cart without updating
                }
    
                return prevItems.map(item =>
                    item._id === product._id &&
                        item.selectedColor === selectedColor &&
                        item.selectedSize === selectedSize
                        ? { ...item, quantity: item.quantity + quantity }
                        : item
                );
            }
    
            const variantItem = {
                _id: product._id,
                name: product.name,
                price: selectedVariant.price, // Use variant-specific price
                selectedColor,
                selectedSize,
                selectedVariant, // Include the selected variant
                image: product.images?.[0] || '',
                quantity
            };
    
            return [...prevItems, variantItem];
        });
    };
    

    const updateCartItemQuantity = (productId, quantity, selectedColor, selectedSize) => {
        setCartItems((prevItems) =>
            prevItems.map(item => {
                // Check if the current item matches the selected product variant (color and size)
                if (item._id === productId && item.selectedColor === selectedColor && item.selectedSize === selectedSize) {
                    // Access the variant info directly from the item stored in the cart
                    const selectedVariant = item.selectedVariant;
    
                    // Check if the available stock is sufficient for the requested quantity
                    if (selectedVariant.stock < quantity) {
                        console.error('Not enough stock for the selected product variant');
                        return item; // Return the item without updating the quantity
                    }
    
                    // Update the quantity while ensuring it doesn't exceed the available stock
                    return { ...item, quantity: Math.max(1, quantity) };
                }
                return item; // For all other items, return them unchanged
            })
        );
    };
    
    

    const removeFromCart = (productId, selectedColor, selectedSize) => {
        setCartItems((prevItems) => {
            const updatedItems = prevItems.filter(
                item => !(item._id === productId && item.selectedColor === selectedColor && item.selectedSize === selectedSize)
            );
            localStorage.setItem(getCartKey(), JSON.stringify(updatedItems));
            return updatedItems;
        });
    };

    const clearCart = () => {
        setCartItems([]);
        localStorage.removeItem(getCartKey());
    };

    return (
        <CartContext.Provider value={{ cartItems, addToCart, updateCartItemQuantity, removeFromCart, clearCart, refreshCart }}>
            {children}
        </CartContext.Provider>
    );
};
