// src/theme.js
import { createTheme } from '@mui/material/styles';

// Function to create a theme with dynamic direction
const createCustomTheme = (direction = 'ltr') => {
    return createTheme({
        direction, // Set the direction (ltr or rtl)
        palette: {
            primary: {
                main: '#2C3639', // Dark Green/Gray
            },
            secondary: {
                main: '#3F4E4F', // Slate Gray
            },
            accent: {
                main: '#A27B5C', // Warm Brown
            },
            tertiary: {
                main: '#DCD7C9', // Light Beige
            },
            background: {
                default: '#F2F2F2',
            },
        },
        typography: {
            fontFamily: 'Roboto, Arial, sans-serif',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none', // Keep the text in buttons in regular case
                    },
                },
            },
        },
    });
};

export default createCustomTheme;
