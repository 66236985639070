import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar, CircularProgress, Alert, Pagination, Grid, Button } from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


function OrderHistory() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { authState } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchOrders = async (page = 1) => {
        try {
            setLoading(true);
            const response = await axios.get('/api/orders', {
                headers: { Authorization: `Bearer ${authState.token}` },
                params: { page, limit: 4 }
            });
            setOrders(response.data.orders);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
        } catch (err) {
            setError(t('orderHistory.fetchError'));
            console.error('Error fetching orders:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (authState.isAuthenticated) {
            fetchOrders(currentPage);
        }
    }, [authState, currentPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box sx={{ backdropFilter: 'blur(10px)', p: 4, backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', minHeight: '80vh' }}>
            <Typography variant="h4" sx={{ mb: 2 }}>{t('orderHistory.title')}</Typography>
            {orders.length === 0 ? (
                <Typography>{t('orderHistory.noOrders')}</Typography>
            ) : (
                <>
                    {orders.map((order) => (
                        <Paper key={order._id} sx={{ mb: 2, p: 2, borderRadius: '16px' }}>
                            <Typography variant="h6">{t('orderHistory.order')} #{order._id}</Typography>
                            <Typography>{t('orderHistory.date')}: {new Date(order.createdAt).toLocaleDateString()}</Typography>
                            <Typography>{t('orderHistory.totalAmount')}: {order.totalAmount.toFixed(2)} MAD</Typography>

                            {/* Grid for responsive product layout */}
                            <Grid container spacing={2}>
                                {order.items.map((item) => (
                                    <Grid item xs={12} sm={6} md={4} key={item._id}>
                                        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                                            <Avatar
                                                variant="square"
                                                src={item.product && item.product.images[0] ? `/api/images/${item.product._id}/${item.product.images[0]}` : '/noimg/400x250.png'}
                                                alt={item.product ? item.product.name : t('orderHistory.noImageAvailable')}
                                                sx={{ width: 60, height: 60, marginRight: 2 }}
                                            />
                                            <Box>
                                                <Typography variant="body1">
                                                    {item.product ? item.product.name : t('orderHistory.unknownProduct')}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {item.variant || t('orderHistory.noVariant')}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {t('orderHistory.quantity')}: {item.quantity}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {t('orderHistory.price')}: {item.priceAtPurchase.toFixed(2)} MAD
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                            <Button 
                                variant="outlined"
                                color="primary"
                                sx={{ mt: 2 }}
                                onClick={() => navigate('/tracking', { state: { trackingNumber: order.trackingNumber } })}
                                disabled={!order.trackingNumber} // Disable if no tracking number
                            >
                                {t('orderHistory.trackOrder', 'Track Order')}
                            </Button>
                        </Paper>
                    ))}
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}
                    />
                </>
            )}
        </Box>
    );
}

export default OrderHistory;
