import React, { useContext, useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Grid,
    Paper,
    Divider,
    Avatar,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    IconButton,
    Alert,
    CircularProgress,
    Autocomplete,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from '@mui/material';
import { Add, Remove, Delete } from '@mui/icons-material';
import { CartContext } from './CartContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import citiesData from './assets/cities.json';
import { useTranslation } from 'react-i18next';

function Checkout() {
    const { cartItems, clearCart, updateCartItemQuantity, removeFromCart } = useContext(CartContext);
    const { authState, refetchUser } = useContext(AuthContext);
    const [total, setTotal] = useState(0);
    const [discountedTotal, setDiscountedTotal] = useState(0); // For total with discounts
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [shippingDetails, setShippingDetails] = useState({
        fullName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'Morocco',
        phoneNumber: '',
    });
    const [cities, setCities] = useState(
        Object.values(citiesData.CITIES).map(city => ({
            id: city.ID,
            name: city.NAME,
        }))
    );
    const [loadingCities, setLoadingCities] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [redeemedItems, setRedeemedItems] = useState([]); // Redeemed items state
    const [discountApplications, setDiscountApplications] = useState([]); // To track discounts and products
    const [discountedItems, setDiscountedItems] = useState([]); // For calculating discounted prices
    const [hasFreeDelivery, setHasFreeDelivery] = useState(false); // Track if free delivery is applied
    const [orderNotes, setOrderNotes] = useState(''); // To store the customer's note


    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchUserData = async () => {
            await refetchUser();
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const fetchRedeemedItems = async () => {
            try {
                const response = await axios.get('/api/user-redeemed-items', {
                    headers: { Authorization: `Bearer ${authState.token}` },
                });
                setRedeemedItems(response.data);
            } catch (err) {
                console.error('Error fetching redeemed items:', err);
            }
        };

        fetchRedeemedItems();

        const calculatedTotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
        setTotal(calculatedTotal + deliveryPrice);
    }, [cartItems, deliveryPrice, authState.token]);

    const handleInputChange = (e) => {
        setShippingDetails({
            ...shippingDetails,
            [e.target.name]: e.target.value,
        });
    };

    const handleCityChange = (event, value) => {
        if (value) {
            const selectedCity = citiesData.CITIES[value.id];
            setShippingDetails({
                ...shippingDetails,
                city: value.id,
            });

            setDeliveryPrice(selectedCity ? parseFloat(selectedCity['DELIVERED-PRICE']) : 0);
        } else {
            setShippingDetails({
                ...shippingDetails,
                city: '',
            });
            setDeliveryPrice(0);
        }
    };

    const handleUseSavedAddress = async () => {
        try {
            const res = await axios.get('/api/auth/me', {
                headers: { Authorization: `Bearer ${authState.token}` },
            });

            const user = res.data.user;

            if (user.addresses && Array.isArray(user.addresses)) {
                if (user.addresses.length > 0) {
                    const defaultAddress = user.addresses.find(address => address.isDefault);

                    if (defaultAddress) {
                        setShippingDetails({
                            fullName: defaultAddress.fullName || '',
                            addressLine1: defaultAddress.addressLine1 || '',
                            addressLine2: defaultAddress.addressLine2 || '',
                            city: defaultAddress.city || '',
                            state: defaultAddress.state || '',
                            postalCode: defaultAddress.postalCode || '',
                            country: defaultAddress.country || 'Morocco',
                            phoneNumber: defaultAddress.phoneNumber || '',
                        });

                        const savedCity = citiesData.CITIES[defaultAddress.city];
                        setDeliveryPrice(savedCity ? parseFloat(savedCity['DELIVERED-PRICE']) : 0);
                    } else {
                        setError(t('checkout.noDefaultAddress'));
                    }
                } else {
                    setError(t('checkout.noSavedAddresses'));
                }
            } else {
                setError(t('checkout.noSavedAddresses'));
            }
        } catch (err) {
            console.error('Error fetching user address:', err);
            setError(t('checkout.loadSavedAddressError'));
        }
    };

    const handleAddQuantity = (item) => {
        updateCartItemQuantity(item._id, item.quantity + 1, item.selectedColor, item.selectedSize);
    };

    const handleSubtractQuantity = (item) => {
        if (item.quantity > 1) {
            updateCartItemQuantity(item._id, item.quantity - 1, item.selectedColor, item.selectedSize);
        }
    };

    const handleRemoveItem = (item) => {
        removeFromCart(item._id, item.selectedColor, item.selectedSize);
    };

    // Update the discount application for each discount
    const handleDiscountChange = (productId, itemId) => {
        setDiscountApplications(prev => {
            const updated = [...prev];
            const index = updated.findIndex(app => app.itemId === itemId);

            // Update the existing discount with the selected product or add new
            if (index > -1) {
                updated[index].productId = productId === '' ? null : productId; // Allow clearing the selection
            } else {
                updated.push({ itemId, productId });
            }

            return updated;
        });
    };



    // Update total with discounted prices
    useEffect(() => {
        let newTotal = total; // Start with the original total
        const appliedDiscounts = [];

        // Check if any redeemed item is FreeDelivery and update hasFreeDelivery state
        const freeDeliveryItem = redeemedItems.some(item => item.redeemItem.type === 'FreeDelivery');
        setHasFreeDelivery(freeDeliveryItem); // Update the state

        if (freeDeliveryItem) {
            setDeliveryPrice(0); // Set delivery price to 0 if FreeDelivery is applied
        }

        cartItems.forEach((item) => {
            // Find if there's a discount applied to this specific item
            const appliedDiscount = discountApplications.find(app => app.productId === item._id);
            if (appliedDiscount) {
                // Find the corresponding redeem item and its discount value
                const discountItem = redeemedItems.find(r => r._id === appliedDiscount.itemId);
                const discountValue = discountItem?.redeemItem?.discountValue || 0;

                // Calculate the discounted price for the item
                const discountedPrice = item.price * (1 - discountValue / 100); // Apply discount to the item price
                newTotal -= (item.price * discountValue / 100) ;

                // Store the discounted item for later display
                appliedDiscounts.push({ productId: item._id, discountedPrice });
            }
        });

        setDiscountedItems(appliedDiscounts); // Set the items with discounted prices
        setDiscountedTotal(newTotal); // Update the total with the discounted prices
    }, [discountApplications, cartItems, total, redeemedItems]);





    const handlePlaceOrder = async () => {
        setError(null);
        setSuccess(null);
        setLoading(true);

        if (!authState.isAuthenticated) {
            setError(t('checkout.mustBeLoggedIn'));
            setLoading(false);
            navigate('/login');
            return;
        }

        try {
            const orderItems = cartItems.map(item => {
                if (!item._id || !item.price) {
                    setError(t('checkout.missingCartItemInfo'));
                    throw new Error('Cart item is missing required fields: product ID or price.');
                }
                return {
                    productId: item._id,
                    quantity: item.quantity,
                    price: item.price,
                    variant: { color : item.selectedColor , size : item.selectedSize},
                };
            });

            // Filter redeemItems to exclude unused discount items
            let filteredRedeemItems = redeemedItems.filter(item => {
                // Include all items that are not discounts or are discounts that have been applied
                return item.redeemItem.type !== 'Discount' || discountApplications.some(app => app.itemId === item._id);
            });
            // Iterate over filteredRedeemItems and set product for discounts
            filteredRedeemItems = filteredRedeemItems.map(item => {
                if (item.redeemItem.type === 'Discount') {
                    const discountApplication = discountApplications.find(app => app.itemId === item._id);
                    if (discountApplication) {
                        return {
                            ...item,
                            product: discountApplication.productId, // Set product to the selected product in discountApplications
                        };
                    }
                }
                return item;
            });
            const orderData = {
                items: orderItems,
                totalAmount: discountedTotal, // Use the discounted total
                shipping: { ...shippingDetails, deliveryPrice },
                payment: {
                    method: 'cash_on_delivery',
                    status: 'pending',
                    amount: discountedTotal,
                },
                redeemItems: filteredRedeemItems, // Use the filtered redeem items
                discountApplications,
                orderNotes,
            };

            const response = await axios.post('/api/orders/create', orderData, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });

            if (response.status === 201) {
                setError(null);
                setSuccess(t('checkout.orderPlacedSuccessfully'));
                clearCart();
                navigate('/thank-you', { state: { orderId: response.data.order._id, totalAmount: discountedTotal, estimatedDelivery: '1-3 business days' } });
            } else {
                setSuccess(null);
                setError(t('checkout.orderPlacementFailed'));
                console.error('Failed to place the order:', response.data);
            }
        } catch (error) {
            setSuccess(null);
            if (error.response) {
                if (error.response.status === 400) {
                    setError(error.response.data.message);
                } else if (error.response.status === 401) {
                    setError(t('checkout.sessionExpired'));
                    navigate('/login');
                } else if (error.response.status === 500) {
                    setError(t('checkout.serverError', { message: error.response.data.message || t('checkout.defaultServerError') }));
                } else {
                    setError(t('checkout.orderPlacementFailed'));
                }
            } else if (error.request) {
                setError(t('checkout.networkError'));
            } else {
                setError(t('checkout.orderPlacementError', { message: error.message }));
            }
            console.error('Error placing the order:', error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box sx={{ m: 5, backdropFilter: 'blur(10px)', p: 4, backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>{t('checkout.title')}</Typography>
            <Divider sx={{ mb: 4 }} />
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 3, borderRadius: '16px' }}>
                        <Typography variant="h6" sx={{ mb: 3 }}>{t('checkout.shippingDetails')}</Typography>
                        {authState.isAuthenticated && (
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                sx={{ mb: 3 }}
                                onClick={handleUseSavedAddress}
                            >
                                {t('checkout.useSavedAddress')}
                            </Button>
                        )}
                        <TextField
                            label={t('checkout.fullName')}
                            name="fullName"
                            value={shippingDetails.fullName}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label={t('checkout.country')}
                            name="country"
                            value={shippingDetails.country}
                            fullWidth
                            margin="normal"
                            required
                            disabled
                        />
                        <TextField
                            label={t('checkout.addressLine1')}
                            name="addressLine1"
                            value={shippingDetails.addressLine1}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label={t('checkout.addressLine2')}
                            name="addressLine2"
                            value={shippingDetails.addressLine2}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <Autocomplete
                            options={cities}
                            getOptionLabel={(option) => option.name}
                            loading={loadingCities}
                            onChange={handleCityChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('checkout.city')}
                                    name="city"
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                            )}
                        />
                        <TextField
                            label={t('checkout.state')}
                            name="state"
                            value={shippingDetails.state}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label={t('checkout.postalCode')}
                            name="postalCode"
                            value={shippingDetails.postalCode}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label={t('checkout.phoneNumber')}
                            name="phoneNumber"
                            value={shippingDetails.phoneNumber}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <Divider sx={{ mt: 1 }} />
                        <TextField
                            label={t('checkout.orderNote', 'Order Note')}
                            name="orderNotes"
                            value={orderNotes}
                            onChange={(e) => setOrderNotes(e.target.value)}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}  // Allows the user to enter longer notes
                            placeholder={t('checkout.orderNotePlaceholder', 'Leave any special instructions or comments about your order here')}
                        />

                    </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 3, borderRadius: '16px' }}>
                        <Typography variant="h6" sx={{ mb: 3 }}>{t('checkout.orderSummary')}</Typography>

                        <List>
                            {cartItems.map((item) => (
                                <ListItem key={`${item._id}-${item.selectedColor}-${item.selectedSize}`} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar
                                            variant="square"
                                            src={item.image ? `/api/images/${item._id}/${item.image}` : '/noimg/300x300.png'}
                                            alt={item.name}
                                            sx={{ width: 50, height: 50, marginRight: 2 }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.name}
                                        secondary={
                                            <>
                                                <Typography variant="body2" color="textSecondary">
                                                    {item.selectedColor}, {item.selectedSize}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {t('checkout.quantity')}: {item.quantity}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {t('checkout.price')}: {item.price * item.quantity} MAD
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <Box>
                                        <IconButton onClick={() => handleAddQuantity(item)}>
                                            <Add />
                                        </IconButton>
                                        <IconButton onClick={() => handleSubtractQuantity(item)}>
                                            <Remove />
                                        </IconButton>
                                        <IconButton onClick={() => handleRemoveItem(item)}>
                                            <Delete />
                                        </IconButton>
                                    </Box>
                                </ListItem>
                            ))}

                            {/* Display Redeemed Items if they exist */}
                            {redeemedItems.length > 0 && (
                                <>
                                    <Divider sx={{ my: 2 }} />
                                    <Typography variant="h6" sx={{ mb: 2 }}>{t('checkout.redeemedItems')}</Typography>
                                    {redeemedItems.map((item, index) => (
                                        <ListItem key={item._id} alignItems="flex-start">
                                            <ListItemText
                                                primary={item.redeemItem.name || t('checkout.redeemItem')}
                                                secondary={
                                                    <>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {item.redeemItem.description || t('checkout.noDescription')}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {t('checkout.pointsSpent')}: {item.redeemItem.pointsRequired}
                                                        </Typography>
                                                        {item.redeemItem.type === 'Discount' && item.redeemItem.discountValue && (
                                                            <Typography variant="body2" color="textSecondary">
                                                                {t('checkout.discountValue')}: {item.redeemItem.discountValue}%
                                                            </Typography>
                                                        )}
                                                        {item.redeemItem.type === 'FreeDelivery' && (
                                                            <Typography variant="body2" color="textSecondary">
                                                                {t('checkout.freeDelivery')}
                                                            </Typography>
                                                        )}
                                                        <Typography variant="body2" color="textSecondary">
                                                            {t('checkout.redeemedAt')}: {new Date(item.redeemedAt).toLocaleDateString()}
                                                        </Typography>

                                                        {/* Display select for product discount under each discount */}
                                                        {item.redeemItem.type === 'Discount' && (
                                                            <FormControl fullWidth margin="normal">
                                                                <InputLabel>{t('checkout.selectProductForDiscount')}</InputLabel>
                                                                <Select
                                                                    value={discountApplications.find(app => app.itemId === item._id)?.productId || ''}
                                                                    onChange={(e) => handleDiscountChange(e.target.value, item._id)}
                                                                >
                                                                    {/* Add an option to clear the discount */}
                                                                    <MenuItem value="">
                                                                        {t('checkout.clearDiscount')}
                                                                    </MenuItem>

                                                                    {cartItems.map((cartItem) => {
                                                                        // Check if this product is already selected by other discounts
                                                                        const isAlreadySelected = discountApplications.some(
                                                                            (app) => app.productId === cartItem._id && app.itemId !== item._id
                                                                        );

                                                                        return (
                                                                            <MenuItem
                                                                                key={cartItem._id}
                                                                                value={cartItem._id}
                                                                                disabled={isAlreadySelected} // Disable if already selected by another discount
                                                                            >
                                                                                {cartItem.name} ({cartItem.selectedColor}, {cartItem.selectedSize})
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>



                                                        )}



                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </>
                            )}
                        </List>

                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ textAlign: 'right' }}>
                            <Typography variant="body2" color="textSecondary">
                                {hasFreeDelivery ? t('checkout.freeDelivery') : `${t('checkout.deliveryPrice')}: ${deliveryPrice.toFixed(2)} MAD`}
                            </Typography>

                            {/* Show discounted price for each selected product */}
                            {discountedItems.length > 0 && discountedItems.map((item) => (
                                <Typography key={item.productId} variant="body2" color="textSecondary">
                                    {t('checkout.discountedPrice')}: {item.discountedPrice.toFixed(2)} MAD
                                </Typography>
                            ))}

                            <Typography variant="h6">{t('checkout.total')}: {discountedTotal.toFixed(2)} MAD</Typography>
                        </Box>


                    </Paper>

                    <Paper elevation={3} sx={{ p: 3, mt: 3, borderRadius: '16px' }}>
                        <Typography variant="h6" sx={{ mb: 3 }}>{t('checkout.paymentMethod')}</Typography>
                        <Typography variant="body1" color="textSecondary">{t('checkout.paymentOnDelivery')}</Typography>
                    </Paper>

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 3 }}
                        onClick={handlePlaceOrder}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('checkout.placeOrder')}
                    </Button>

                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error}
                        </Alert>
                    )}

                    {success && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            {success}
                        </Alert>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Checkout;
