// src/i18n/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import en from './locales/en.json';
import fr from './locales/fr.json';
import ar from './locales/ar.json';

// Initialize i18next
i18n
  .use(LanguageDetector) // Automatically detect the user's language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
      ar: { translation: ar }
    },
    fallbackLng: 'en', // Use English if detected language is not available
    debug: true, // Enable debug mode in development
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
