import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    CircularProgress,
    Alert,
} from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import translation hook

function ReferralSettings() {
    const [referralCode, setReferralCode] = useState('');
    const [referredUsers, setReferredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { t } = useTranslation(); // Initialize translation hook

    useEffect(() => {
        const fetchReferralData = async () => {
            try {
                const response = await axios.get('/api/referrals/me');
                setReferralCode(response.data.referralCode);
                setReferredUsers(response.data.referredUsers);
            } catch (err) {
                setError(t('referralSettings.fetchError')); // Use translation
            } finally {
                setLoading(false);
            }
        };

        fetchReferralData();
    }, [t]);

    const handleCopyReferralCode = () => {
        navigator.clipboard.writeText(referralCode);
        alert(t('referralSettings.copySuccess')); // Use translation
    };

    return (
        <Box
            sx={{
                backdropFilter: 'blur(10px)',
                p: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: '16px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                minHeight: '80vh',
            }}
        >
            <Typography variant="h4" sx={{ mb: 3 }}>
                {t('referralSettings.title')}
            </Typography>

            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Alert severity="error">{error}</Alert>
            ) : (
                <>
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6">{t('referralSettings.yourReferralCode')}</Typography>
                        <TextField
                            fullWidth
                            value={referralCode}
                            margin="normal"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCopyReferralCode}
                            sx={{ mt: 1 }}
                        >
                            {t('referralSettings.copyButton')}
                        </Button>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <Typography variant="h6" sx={{ mb: 2 }}>
                        {t('referralSettings.usersUsedCode')}
                    </Typography>
                    {referredUsers.length === 0 ? (
                        <Typography variant="body1" color="textSecondary">
                            {t('referralSettings.noUsers')}
                        </Typography>
                    ) : (
                        <List>
                            {referredUsers.map((user) => (
                                <ListItem key={user._id}>
                                    <ListItemAvatar>
                                        <Avatar src={user.avatarUrl || '/default-avatar.png'} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={user.name}
                                        secondary={`${t('referralSettings.joinedOn')} ${new Date(user.date).toLocaleDateString()}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </>
            )}
        </Box>
    );
}

export default ReferralSettings;
