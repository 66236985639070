import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Button, Box, CircularProgress, Grid, Chip, Rating, IconButton, TextField, Avatar, Divider } from '@mui/material';
import { CartContext } from './CartContext';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

// Separate Image and Thumbnail Section as a subcomponent
const ImageSection = ({ product, mainImage, setMainImage, hoveredImage, setHoveredImage, variantImages, selectedColor, fallbackImage, isSmallScreen }) => (
    <Grid item xs={12} lg={6}>
        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row' }}>
            {/* Thumbnails */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'row' : 'column',
                    gap: 1,
                    mb: isSmallScreen ? 2 : 0, // Adds margin-bottom for small screens
                    mr: !isSmallScreen ? 2 : 0, // Adds margin-right for larger screens
                    justifyContent: isSmallScreen ? 'center' : 'flex-start', // Center thumbnails horizontally for small screens
                }}
            >
                {product.images && product.images.map((image, index) => (
                    <img
                        key={index}
                        src={`/api/images/${product._id}/${image}`}
                        alt={product.name}
                        onClick={() => setMainImage(`/api/images/${product._id}/${image}`)}
                        onMouseEnter={() => setHoveredImage(`/api/images/${product._id}/${image}`)}
                        onMouseLeave={() => setHoveredImage(null)}
                        onError={(e) => (e.target.src = fallbackImage)} // Fallback if image not found
                        style={{
                            width: '80px',
                            height: '80px',
                            objectFit: 'cover',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            border: mainImage === `/api/images/${product._id}/${image}` ? '2px solid black' : '1px solid lightgrey',
                        }}
                    />
                ))}

                {selectedColor && variantImages[selectedColor] && variantImages[selectedColor].map((variantImage, index) => (
                    <img
                        key={index}
                        src={variantImage}
                        alt={`${selectedColor} variant`}
                        onClick={() => setMainImage(variantImage)}
                        onMouseEnter={() => setHoveredImage(variantImage)}
                        onMouseLeave={() => setHoveredImage(null)}
                        onError={(e) => (e.target.src = fallbackImage)}
                        style={{
                            width: '80px',
                            height: '80px',
                            objectFit: 'cover',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            border: mainImage === variantImage ? '2px solid black' : '1px solid lightgrey',
                        }}
                    />
                ))}
            </Box>

            {/* Main Image */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    position: 'relative', // Add this for overlay positioning
                }}
            >
                <img
                    src={hoveredImage || mainImage}
                    alt={product.name}
                    onError={(e) => (e.target.src = fallbackImage)}
                    style={{
                        maxWidth: '420px',
                        maxHeight: '420px',
                        minWidth: '200px',
                        minHeight: '200px',
                        borderRadius: '8px',
                        objectFit: 'cover',
                        marginTop: isSmallScreen ? '16px' : '0',
                    }}
                />

                {product.totalStock === 0 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Grey transparent overlay
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                color: 'white',
                                fontWeight: 'bold',
                                backgroundColor: 'rgba(255, 0, 0, 0.8)', // Red background for "Sold Out"
                                padding: '8px 16px',
                                borderRadius: '4px',
                            }}
                        >
                            Sold Out
                        </Typography>
                    </Box>
                )}
            </Box>

        </Box>
    </Grid>

);


// Separate Product Details as a subcomponent
const ProductDetails = ({
    product,
    selectedColor,
    selectedSize,
    setSelectedColor,
    setSelectedSize,
    quantity,
    handleQuantityChange,
    handleAddToCart,
    isFavorite,
    toggleFavorite,
    t,
    isSmallScreen,
    authError,
    setHoveredImage,
    variantImages,
    setMainImage
}) => {
    // Calculate the selected variant inside the component
    const selectedVariant = product.variants.find(variant => variant.color === selectedColor && variant.size === selectedSize);
    const colors = [
        { name: 'Red', colorCode: '#f44336' },
        { name: 'Blue', colorCode: '#2196f3' },
        { name: 'Green', colorCode: '#4caf50' },
        { name: 'Black', colorCode: '#000000' },
        { name: 'White', colorCode: '#ffffff' },
        { name: 'Yellow', colorCode: '#ffeb3b' },
        { name: 'Orange', colorCode: '#ff9800' },
        { name: 'Purple', colorCode: '#9c27b0' },
        { name: 'Pink', colorCode: '#e91e63' },
        { name: 'Gray', colorCode: '#9e9e9e' },
        { name: 'Teal', colorCode: '#009688' },
        { name: 'Brown', colorCode: '#795548' },
    ];

    return (
        <Grid item xs={12} lg={6}>
            <Box sx={{ padding: isSmallScreen ? 1 : 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant={isSmallScreen ? 'h5' : 'h4'} sx={{ fontWeight: 'bold' }}>{product.name}</Typography>
                    <IconButton onClick={toggleFavorite} color="error">
                        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                    </IconButton>
                </Box>

                <Typography variant="body1" sx={{ mb: 3 }}>{product.description}</Typography>


                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    
                <Typography variant="body1" sx={{ mr: 2, fontWeight: 'bold' }}>
                    {t('productDetail.color')}:
                </Typography>
                {colors
    .filter(({ name }) => product.variants.some(variant => variant.color.toLowerCase() === name.toLowerCase()))
    .map(({ name, colorCode }, index) => {
        // Find all variants of the current color
        const variantsForColor = product.variants.filter(variant => variant.color.toLowerCase() === name.toLowerCase());

        // Check if any variant of this color has stock > 0
        const hasStock = variantsForColor.some(variant => variant.stock > 0);

        return (
            <Box
                key={index}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: hasStock ? 'pointer' : 'not-allowed',
                    opacity: hasStock ? 1 : 0.5, // Dim the color if there's no stock
                    margin: '0 8px',
                    paddingBottom: 2,
                }}
                onClick={() => {
                    if (hasStock) {
                        setSelectedColor(name);
                        setMainImage(variantImages[name]?.[0]); // Set main image to the first image of the selected color
                    }
                }}
                onMouseEnter={() => hasStock && setHoveredImage(variantImages[name]?.[0])}
                onMouseLeave={() => setHoveredImage(null)}
            >
                <Box
                    sx={{
                        bgcolor: colorCode, // Use colorCode from the colors array
                        border: `4px solid ${selectedColor === name ? 'white' : 'lightgrey'}`, // White border if selected
                        boxShadow: selectedColor === name ? '0 2px 8px rgba(0, 0, 0, 0.3)' : 'none', // Shadow if selected
                        width: '32px',
                        height: '32px',
                        borderRadius: '50%', // Make it a circle
                        transition: 'box-shadow 0.3s ease, border 0.3s ease', // Smooth transition for visual effect
                    }}
                />
                <Typography variant="caption" sx={{ mt: 1, color: 'text.primary', fontSize: '0.75rem' }}>
                    {name}
                </Typography>
            </Box>
        );
    })}

                </Box>

                {selectedColor && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <Typography variant="body1" sx={{ mr: 2, fontWeight: 'bold' }}>
                            {t('productDetail.size')}:
                        </Typography>

                        {/* Size Grid */}
                        <Grid container spacing={1}>
                            {product.variants &&
                                [...new Set(product.variants.map(variant => variant.size))].map((size, index) => {
                                    const hasStock = product.variants.some(variant => variant.color === selectedColor && variant.size === size && variant.stock > 0);
                                    return (
                                        <Grid item xs={6} md={4} lg={3} key={index}>
                                            <Button
                                                variant={selectedSize === size ? 'contained' : 'outlined'}
                                                onClick={() => hasStock && setSelectedSize(size)}
                                                sx={{
                                                    width: '100%', // Make the button full width within the grid item
                                                    borderRadius: '2px',
                                                    padding: '6px 12px',
                                                    cursor: hasStock ? 'pointer' : 'not-allowed',
                                                    opacity: hasStock ? 1 : 0.5,
                                                }}
                                                disabled={!hasStock}
                                            >
                                                {size}
                                            </Button>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </Box>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <Typography variant="body1" sx={{ mr: 2, fontWeight: 'bold' }}>{t('productDetail.quantity')}:</Typography>
                    <TextField
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        inputProps={{
                            min: 1,
                            max: selectedVariant ? selectedVariant.stock : 1, // Set the maximum to the stock of the selected variant
                        }}
                        sx={{ width: '80px' }}
                    />
                </Box>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 3 }}>{t('productDetail.price')}: {product.price} MAD</Typography>

                <Button
                    variant="contained"
                    color="primary"
                    size={isSmallScreen ? 'medium' : 'large'}
                    startIcon={<ShoppingCartIcon />}
                    sx={{
                        mb: 4,
                        '&:hover': {
                            backgroundColor: 'secondary.main',
                        }
                    }}
                    onClick={handleAddToCart}
                    disabled={product.totalStock === 0}
                >
                    {t('productDetail.addToCart')}
                </Button>

                {authError && <Typography color="error" sx={{ mb: 2 }}>{authError}</Typography>}

                <Box>
                    <Rating value={product.averageRating} precision={0.5} readOnly />
                    <Typography variant="body2" sx={{ mt: 1 }}>({product.reviews.length} {t('productDetail.customerReviews')})</Typography>
                </Box>
            </Box>
        </Grid>
    );
};




function ProductDetail() {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const { addToCart } = useContext(CartContext);
    const { authState } = useContext(AuthContext);
    const [quantity, setQuantity] = useState(1);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [isFavorite, setIsFavorite] = useState(false);
    const [mainImage, setMainImage] = useState('');
    const [hoveredImage, setHoveredImage] = useState(null);
    const [authError, setAuthError] = useState('');
    const { t } = useTranslation();
    const [variantImages, setVariantImages] = useState({}); // To hold images for each color
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [reviewError, setReviewError] = useState('');
    const [reviewSuccess, setReviewSuccess] = useState('');
    const [visibleReviews, setVisibleReviews] = useState(3);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fallbackImage = '/noimg/300x300.png'; // Fallback image for both thumbnails and main image

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const res = await axios.get(`/api/products/${id}`);
                setProduct(res.data);
                setLoading(false);

                // Set main image to product's primary image or fallback image
                setMainImage(res.data.images[0] ? `/api/images/${id}/${res.data.images[0]}` : fallbackImage);

                // Fetch and map variant images for each color, pick only one variant per color
                const variantImageMap = {};
                const seenColors = new Set();  // Track colors we’ve already processed

                res.data.variants.forEach((variant) => {
                    if (variant.imageUrl && variant.imageUrl.length > 0 && !seenColors.has(variant.color)) {
                        seenColors.add(variant.color);
                        variantImageMap[variant.color] = variant.imageUrl.map((img) => `/api/images/${id}/${img}`);
                    }
                });
                setVariantImages(variantImageMap);

                const firstAvailableColor = res.data.variants.find(variant => variant.stock > 0)?.color;
                if (firstAvailableColor) {
                    setSelectedColor(firstAvailableColor);

                    // Set the main image to the first image of the first available color
                    if (variantImageMap[firstAvailableColor]?.[0]) {
                        setMainImage(variantImageMap[firstAvailableColor][0]);
                    } else if (res.data.images[0]) {
                        // Fallback to product's primary image
                        setMainImage(`/api/images/${id}/${res.data.images[0]}`);
                    } else {
                        setMainImage(fallbackImage);
                    }
                }

            } catch (err) {
                console.error('Error fetching product:', err);
                setLoading(false);
            }
        };

        const checkFavoriteStatus = async () => {
            try {
                const res = await axios.get('/api/favorite', {
                    headers: { Authorization: `Bearer ${authState.token}` }
                });
                const favoriteProductIds = res.data.favorites.map(favorite => favorite.product._id);
                setIsFavorite(favoriteProductIds.includes(id));
            } catch (err) {
                console.error('Error checking favorite status:', err);
            }
        };

        fetchProduct();
        if (authState.isAuthenticated) {
            checkFavoriteStatus();
        }
    }, [id, authState.token, authState.isAuthenticated]);

    const handleAddToCart = () => {
        if (!authState.isAuthenticated) {
            setAuthError(t('productDetail.authErrorLoginCart'));
            return;
        }

        if (!selectedColor || !selectedSize) {
            setAuthError(t('productDetail.authErrorSelectOptions'));
            return;
        }

        const selectedVariant = product.variants.find(
            variant => variant.color === selectedColor && variant.size === selectedSize
        );
    
        if (!selectedVariant || selectedVariant.stock < quantity) {
            setAuthError(t('productDetail.authErrorInsufficientStock'));
            return;
        }

        setAuthError(''); // Clear any previous error

        // Pass the selected variant details (selectedColor, selectedSize) and quantity
        addToCart({ _id: product._id, name: product.name, price: product.price, images: product.images , variants: product.variants }, selectedColor, selectedSize, quantity);
    };


    const handleImageClick = (imageUrl) => {
        setMainImage(imageUrl);
        setHoveredImage(null); // Clear hovered image when clicked
    };

    const handleImageHover = (imageUrl) => {
        setHoveredImage(imageUrl);
    };

    const toggleFavorite = async () => {
        if (!authState.isAuthenticated) {
            setAuthError(t('productDetail.authErrorLoginFavorite'));
            return;
        }

        try {
            if (isFavorite) {
                await axios.delete(`/api/favorite/${id}`, {
                    headers: { Authorization: `Bearer ${authState.token}` }
                });
                setIsFavorite(false);
            } else {
                await axios.post(`/api/favorite/${id}`, {}, {
                    headers: { Authorization: `Bearer ${authState.token}` }
                });
                setIsFavorite(true);
            }
            setAuthError(''); // Clear any previous error
        } catch (err) {
            console.error('Error toggling favorite:', err);
            setAuthError(t('productDetail.favoriteError'));
        }
    };

    const handleQuantityChange = (e) => {
        let value = e.target.value;
    
        // Ensure the input is a valid number
        if (!/^\d*$/.test(value)) {
            return; // Prevent any non-numeric input
        }
    
        const numericValue = Number(value);
    
        // Find the selected variant based on the currently selected color and size
        const selectedVariant = product.variants.find(
            variant => variant.color === selectedColor && variant.size === selectedSize
        );
    
        if (value === '' || numericValue < 1) {
            setQuantity(''); // Set to minimum 1
        } else if (selectedVariant && numericValue > selectedVariant.stock) {
            setQuantity(selectedVariant.stock); // Set to max available stock
        } else {
            setQuantity(numericValue);
        }
    };
    
    

    const handleSubmitReview = async () => {
        if (rating === 0 || comment.trim() === '') {
            setReviewError(t('productDetail.reviewErrorIncomplete'));
            return;
        }

        try {
            await axios.post(
                `/api/products/${id}/reviews`,
                { rating, comment },
                {
                    headers: { Authorization: `Bearer ${authState.token}` },
                }
            );

            // Refetch the updated product data
            const res = await axios.get(`/api/products/${id}`);
            setProduct(res.data);

            setReviewSuccess(t('productDetail.reviewSuccess'));
            setReviewError('');
            setRating(0);
            setComment('');
        } catch (err) {
            console.error('Error submitting review:', err);
            setReviewError(t('productDetail.reviewError'));
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ pt: 4 }}>
            <Box sx={{ my: 4, p: 4, backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' }}>
                <Grid container spacing={isSmallScreen ? 2 : 6}>
                    <ImageSection
                        product={product}
                        mainImage={mainImage}
                        setMainImage={setMainImage}
                        hoveredImage={hoveredImage}
                        setHoveredImage={setHoveredImage}
                        variantImages={variantImages}
                        selectedColor={selectedColor}
                        fallbackImage={fallbackImage}
                        isSmallScreen={isSmallScreen}
                    />
                    <ProductDetails
                        product={product}
                        selectedColor={selectedColor}
                        selectedSize={selectedSize}
                        setSelectedColor={setSelectedColor}
                        setSelectedSize={setSelectedSize}
                        quantity={quantity}
                        handleQuantityChange={handleQuantityChange}
                        handleAddToCart={handleAddToCart}
                        isFavorite={isFavorite}
                        toggleFavorite={toggleFavorite}
                        t={t}
                        isSmallScreen={isSmallScreen}
                        authError={authError}
                        setHoveredImage={setHoveredImage}
                        variantImages={variantImages}
                        setMainImage={setMainImage}
                    />
                </Grid>
                <Divider sx={{ my: 4 }} />
                {/* Customer Reviews Section */}
                <Box sx={{ mt: isSmallScreen ? 3 : 5 }}>
                    <Typography variant={isSmallScreen ? 'h6' : 'h5'} gutterBottom>{t('productDetail.customerReviewsTitle')}</Typography>
                    {product.reviews.length > 0 ? (
                        <>
                            {product.reviews.slice(0, visibleReviews).map((review, index) => (
                                <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                    <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 2 }}>
                                        {review.user?.name?.charAt(0).toUpperCase() || 'U'}
                                    </Avatar>
                                    <Box>
                                        <Rating value={review.rating} readOnly />
                                        <Typography variant="body2" sx={{ mt: 1 }}>{review.comment}</Typography>
                                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>{new Date(review.date).toLocaleDateString()}</Typography>
                                    </Box>
                                </Box>
                            ))}
                            {visibleReviews < product.reviews.length && (
                                <Button variant="text" onClick={() => setVisibleReviews(prev => prev + 3)}>{t('productDetail.viewMore')}</Button>
                            )}
                        </>
                    ) : (
                        <Typography variant="body1">{t('productDetail.noReviews')}</Typography>
                    )}
                </Box>

                {/* Add Review Section */}
                {authState.isAuthenticated ? (
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom>{t('productDetail.addYourReview')}</Typography>
                        <Rating
                            name="user-rating"
                            value={rating}
                            onChange={(e, newValue) => setRating(newValue)}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label={t('productDetail.comment')}
                            fullWidth
                            multiline
                            rows={4}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            variant="outlined"
                            sx={{ mb: 2 }}
                        />
                        {reviewError && (
                            <Typography color="error" sx={{ mb: 2 }}>
                                {reviewError}
                            </Typography>
                        )}
                        {reviewSuccess && (
                            <Typography color="success.main" sx={{ mb: 2 }}>
                                {reviewSuccess}
                            </Typography>
                        )}
                        <Button variant="contained" color="primary" onClick={handleSubmitReview}>
                            {t('productDetail.submitReview')}
                        </Button>
                    </Box>
                ) : (
                    <Typography variant="body1" color="textSecondary" sx={{ mt: 4 }}>
                        {t('productDetail.loginToReview')}
                    </Typography>
                )}
            </Box>
        </Container>
    );
}

export default ProductDetail;
