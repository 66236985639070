// src/Footer.js
import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'; // Import useTranslation

function Footer() {
    const theme = useTheme();
    const { t } = useTranslation(); // Initialize useTranslation

    return (
        <Box sx={{ pt: 3, pb: 3, borderTop: '1px solid #ddd', backgroundColor: theme.palette.primary.main }}>
            <Container maxWidth="lg">
                <Typography variant="body2" color="white" align="center">
                    © {new Date().getFullYear()} Yesmall Store. {t('footer.allRightsReserved')}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 2 }}>
                    <Button component={Link} to="/contact" variant="text" color="accent">
                        {t('footer.contactUs')}
                    </Button>
                    <Button component={Link} to="/privacy-policy" variant="text" color="accent">
                        {t('footer.privacyPolicy')}
                    </Button>
                    <Button component={Link} to="/terms" variant="text" color="accent">
                        {t('footer.termsOfService')}
                    </Button>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer;
