import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
    Container,
    Box,
    Typography,
    Button,
    Paper,
    TextField,
    MenuItem,
    Collapse,
    Chip,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Snackbar,
    Alert,
    Card,
    CardContent,
    Grid,
    Divider
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CircleIcon from '@mui/icons-material/Circle';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { AuthContext } from '../AuthContext';
import { useTranslation } from 'react-i18next';

function AddProduct() {
    const { authState } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: 0,
        stock: 0,
        category: {
            mainCategory: '',
            subCategory: '',
        },
        tags: [],
        variants: [],
        discount: 0,
        brand: '',
        dimensions: {
            width: 0,
            height: 0,
            depth: 0,
        },
        weight: 0,
        productType: 'for_sale',
    });

    const [productImage, setProductImage] = useState(null);
    const [variantImages, setVariantImages] = useState({});
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [editingVariantIndex, setEditingVariantIndex] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [error, setError] = useState(null);
    const [showOptionalFields, setShowOptionalFields] = useState(false);
    const [tagInput, setTagInput] = useState('');

    const { t } = useTranslation();

    // Category options
    const mainCategories = [
        { label: 'Men', value: 'men' },
        { label: 'Women', value: 'women' },
        { label: 'Kids', value: 'kids' },
    ];

    const subCategories = {
        men: [
            { label: 'Shirts', value: 'shirts' },
            { label: 'Pants', value: 'pants' },
            { label: 'Accessories', value: 'accessories' },
        ],
        women: [
            { label: 'Dresses', value: 'dresses' },
            { label: 'Skirts', value: 'skirts' },
            { label: 'Shoes', value: 'shoes' },
        ],
        kids: [
            { label: 'T-Shirts', value: 'tshirts' },
            { label: 'Shorts', value: 'shorts' },
            { label: 'Toys', value: 'toys' },
        ]
    };

    const colorOptions = [
        { name: 'Red', code: '#f44336' },
        { name: 'Blue', code: '#2196f3' },
        { name: 'Green', code: '#4caf50' },
        { name: 'Black', code: '#000000' },
        { name: 'White', code: '#ffffff' },
        { name: 'Yellow', code: '#ffeb3b' },
        { name: 'Orange', code: '#ff9800' },
        { name: 'Purple', code: '#9c27b0' },
        { name: 'Pink', code: '#e91e63' },
        { name: 'Gray', code: '#9e9e9e' },
        { name: 'Teal', code: '#009688' },
        { name: 'Brown', code: '#795548' },
        { name: 'Multicolor', code: 'linear-gradient(135deg, red, orange, yellow, green, blue)' },
    ];

    const sizeOptions = ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', "Standard"];

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('dimensions')) {
            const dimensionKey = name.split('.')[1];
            setFormData({
                ...formData,
                dimensions: { ...formData.dimensions, [dimensionKey]: value },
            });
        } else if (name === 'mainCategory' || name === 'subCategory') {
            setFormData({
                ...formData,
                category: { ...formData.category, [name]: value },
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    // Function to handle adding the tag
    const addTag = () => {
        if (tagInput.trim()) {
            setFormData({ ...formData, tags: [...formData.tags, tagInput.trim()] });
            setTagInput(''); // Reset the tag input field after adding
        }
    };

    // function to remove the selected product image
    const removeProductImage = () => {
        setProductImage(null);
    };

    // function to remove specific variant image
    const removeVariantImage = (color) => {
        const updatedVariantImages = { ...variantImages };
        delete updatedVariantImages[color];
        setVariantImages(updatedVariantImages);
    };

    const handleColorSelect = (color) => {
        if (selectedColors.includes(color)) {
            setSelectedColors(selectedColors.filter(c => c !== color));
            const { [color]: _, ...rest } = variantImages;
            setVariantImages(rest);
        } else {
            setSelectedColors([...selectedColors, color]);
        }
    };

    const handleSizeSelect = (size) => {
        if (selectedSizes.includes(size)) {
            setSelectedSizes(selectedSizes.filter(s => s !== size));
        } else {
            setSelectedSizes([...selectedSizes, size]);
        }
    };

    const handleProductImageChange = (e) => {
        setProductImage(e.target.files[0]);
    };

    const handleVariantImageChange = (color, e) => {
        const files = Array.from(e.target.files); // Convert FileList to Array
        setVariantImages((prevState) => ({
            ...prevState,
            [color]: prevState[color] ? [...prevState[color], ...files] : files // Append new images
        }));
    };


    const createVariants = () => {
            // Ensure sizes and colors are selected
    if (selectedSizes.length === 0 || selectedColors.length === 0) {
        setError('Please select at least one size and one color.');
        setSnackbarOpen(true);
        return;
    }

    // Ensure the price and stock are valid
    if (formData.price <= 0) {
        setError('Product price must be greater than 0.');
        setSnackbarOpen(true);
        return;
    }

    if (formData.stock <= 0) {
        setError('Product stock must be greater than 0.');
        setSnackbarOpen(true);
        return;
    }

        const newVariants = [];
        selectedSizes.forEach(size => {
            selectedColors.forEach(color => {
                newVariants.push({
                    size,
                    color,
                    stock: formData.stock,
                    price: formData.price,
                    imageUrl: '',
                });
            });
        });
        setFormData({ ...formData, variants: newVariants });
    };

    const addVariant = () => {
        setFormData({ ...formData, variants: [...formData.variants, { size: '', color: '', stock: 0, price: 0 }] });
    };

    const removeVariant = (index) => {
        const updatedVariants = formData.variants.filter((_, i) => i !== index);
        setFormData({ ...formData, variants: updatedVariants });
    };

    const startEditingVariant = (index) => {
        setEditingVariantIndex(index);
    };

    const saveVariant = () => {
        setEditingVariantIndex(null);
    };

    const cancelEditingVariant = () => {
        setEditingVariantIndex(null);
    };

    const handleVariantChange = (index, e) => {
        const updatedVariants = [...formData.variants];
        updatedVariants[index] = { ...updatedVariants[index], [e.target.name]: e.target.value };
        setFormData({ ...formData, variants: updatedVariants });
    };


    const removeTag = (index) => {
        setFormData({ ...formData, tags: formData.tags.filter((_, i) => i !== index) });
    };

    const onSubmit = async (e) => {
        e.preventDefault();

    // Check if at least one product image is provided
    if (!productImage) {
        setError('Please upload at least one product image.');
        setSnackbarOpen(true);
        return;
    }

    // Check if variants are created
    if (formData.variants.length === 0) {
        setError('You must create at least one variant before adding the product.');
        setSnackbarOpen(true);
        return;
    }

    // Check if variant images are provided for each variant color
    const missingImagesForVariants = selectedColors.some(color => !variantImages[color] || variantImages[color].length === 0);
    if (missingImagesForVariants) {
        setError('Please upload at least one image for each variant.');
        setSnackbarOpen(true);
        return;
    }

    // Check if the product price is valid
    if (formData.price <= 0) {
        setError('The product price must be greater than 0.');
        setSnackbarOpen(true);
        return;
    }

    // Check if the product stock is valid
    if (formData.stock <= 0) {
        setError('The product stock must be greater than 0.');
        setSnackbarOpen(true);
        return;
    }

    // Check if any variant price is invalid
    const invalidVariantPrice = formData.variants.some(variant => variant.price <= 0);
    if (invalidVariantPrice) {
        setError('All variant prices must be greater than 0.');
        setSnackbarOpen(true);
        return;
    }

    // Check if any variant stock is invalid
    const invalidVariantStock = formData.variants.some(variant => variant.stock <= 0);
    if (invalidVariantStock) {
        setError('All variant stock quantities must be greater than 0.');
        setSnackbarOpen(true);
        return;
    }

        const data = new FormData();
        data.append('name', formData.name);
        data.append('description', formData.description);
        data.append('price', formData.price);
        data.append('productType', formData.productType);

        // Serialize the category object and append it
        const categoryData = JSON.stringify({
            mainCategory: formData.category.mainCategory,
            subCategory: formData.category.subCategory
        });

        data.append('category', categoryData);
        data.append('discount', formData.discount);
        data.append('brand', formData.brand);
        data.append('dimensions.width', formData.dimensions.width);
        data.append('dimensions.height', formData.dimensions.height);
        data.append('dimensions.depth', formData.dimensions.depth);
        data.append('weight', formData.weight);

        formData.tags.forEach((tag) => data.append('tags', tag));
        data.append('variants', JSON.stringify(formData.variants));

        if (productImage) {
            data.append('productImage', productImage);
        }


        Object.keys(variantImages).forEach((color) => {
            variantImages[color].forEach((image, index) => {
                data.append('variantImages', image);
                data.append('variantColor-variantImages', color); // Send the color along with the image
            });
        });


        try {
            const res = await axios.post('/api/products', data, {
                headers: {
                    Authorization: `Bearer ${authState.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Product added:', res.data);
            resetForm();
            setSnackbarMessage(t('productManagement.productAdded'));
        } catch (err) {
            console.error('Error adding product:', err);
            setError(t('productManagement.addError'));
        } finally {
            setSnackbarOpen(true);
        }
    };

    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            price: 0,
            stock: 0,
            category: {
                mainCategory: '',
                subCategory: '',
            },
            tags: [],
            variants: [],
            discount: 0,
            brand: '',
            dimensions: {
                width: 0,
                height: 0,
                depth: 0,
            },
            weight: 0,
            productType: 'for_sale',
        });
        setSelectedColors([]);
        setSelectedSizes([]);
        setVariantImages({});
        setProductImage(null);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="lg" sx={{ minHeight: '80vh' }}>
            <Paper elevation={3} sx={{
                height: '100%',
                width: '100%',
                backdropFilter: 'blur(10px)',
                p: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: '16px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)'
            }}>
                <Typography variant="h5" gutterBottom>
                    {t('productManagement.addNewProduct')}
                </Typography>
                <form onSubmit={onSubmit}>
                    {/* Required Fields */}
                    <TextField
                        select
                        label="Product Type"
                        name="productType"
                        value={formData.productType}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        required
                    >
                        <MenuItem value="for_sale">For Sale</MenuItem>
                        <MenuItem value="reloved">Reloved</MenuItem>
                        <MenuItem value="redeemable">Redeemable</MenuItem>
                    </TextField>

                    <TextField
                        label={t('productManagement.name')}
                        name="name"
                        value={formData.name}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label={t('productManagement.description')}
                        name="description"
                        value={formData.description}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        required
                    />
                    <TextField
                        label={t('productManagement.price')}
                        name="price"
                        value={formData.price}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        type="number"
                        required
                    />
                    <TextField
                        label={t('productManagement.stock')}
                        name="stock"
                        value={formData.stock}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        type="number"
                        required
                    />

                    {/* Main Category */}
                    <TextField
                        select
                        label={t('productManagement.mainCategory')}
                        name="mainCategory"
                        value={formData.category.mainCategory}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        required
                    >
                        {mainCategories.map((category) => (
                            <MenuItem key={category.value} value={category.value}>
                                {category.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    {/* Subcategory */}
                    <TextField
                        select
                        label={t('productManagement.subCategory')}
                        name="subCategory"
                        value={formData.category.subCategory}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        required

                    >
                        {formData.category.mainCategory &&
                            subCategories[formData.category.mainCategory]?.map((subCat) => (
                                <MenuItem key={subCat.value} value={subCat.value}>
                                    {subCat.label}
                                </MenuItem>
                            ))}
                    </TextField>


                    {/* Product Image Upload */}
                    <Card sx={{ my: 3, borderRadius: "16px" }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('productManagement.uploadProductImage')}
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={12}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        fullWidth
                                        sx={{ color: 'white' }}
                                        color='accent'
                                    >
                                        {t('productManagement.selectProductImage')}
                                        <input
                                            type="file"
                                            accept="image/*"
                                            hidden
                                            onChange={handleProductImageChange}
                                        />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    {productImage ? (
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '150px',
                                                borderRadius: '8px',
                                                overflow: 'hidden',
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                            }}
                                        >
                                            <img
                                                src={URL.createObjectURL(productImage)}
                                                alt="Product Preview"
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                            />
                                            <IconButton
                                                sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
                                                onClick={removeProductImage}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        <Typography variant="caption">
                                            {t('productManagement.noImageSelected')}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>


                    {/* Color and Variant Image Upload */}
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6">{t('productManagement.selectColors')}</Typography>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {colorOptions.map((color) => (
                                <Tooltip title={color.name} key={color.name}>
                                    <IconButton
                                        onClick={() => handleColorSelect(color.name)}
                                        sx={{
                                            border: '2px solid',
                                            backgroundColor: color.name === 'Multicolor' ? 'transparent' : color.code,
                                            backgroundImage:
                                                color.name === 'Multicolor'
                                                    ? 'linear-gradient(135deg, red, orange, yellow, green, blue)'
                                                    : 'none',
                                            color: selectedColors.includes(color.name) ? 'white' : color.code,
                                            borderColor: selectedColors.includes(color.name)
                                                ? '#FFFFFF'
                                                : 'lightgrey',
                                            width: 36,
                                            height: 36,
                                            borderRadius: '50%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            boxShadow: selectedColors.includes(color.name)
                                                ? '0 0 5px rgba(0, 0, 0, 0.5)'
                                                : 'none',
                                        }}
                                    >
                                        <CircleIcon
                                            style={{
                                                fontSize: 36,
                                                color: color.name === 'Multicolor' ? 'transparent' : color.code,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            ))}
                        </Box>
                    </Box>

                    {/* Variant Image Upload for Selected Colors */}
                    <Card sx={{ mb: 3, borderRadius: "16px" }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {t('productManagement.uploadVariantImages')}
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Grid container spacing={2}>
                                {selectedColors.map((color) => (
                                    <Grid item xs={12} sm={6} md={4} key={color}>
                                        <Typography variant="body1" gutterBottom>
                                            {`${t('productManagement.variantImage')} - ${color}`}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Button
                                                variant="contained"
                                                component="label"
                                                sx={{ color: 'white' }}
                                                color='accent'
                                                fullWidth
                                            >
                                                {t('productManagement.selectVariantImage')}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    multiple  // Allow selecting multiple files for each variant color
                                                    onChange={(e) => handleVariantImageChange(color, e)}
                                                />
                                            </Button>
                                        </Box>
                                        {variantImages[color] && (
                                            <Box
                                                sx={{
                                                    mt: 2,
                                                    position: 'relative',
                                                    width: '100%',
                                                    height: '150px',
                                                    borderRadius: '8px',
                                                    overflow: 'hidden',
                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                                }}
                                            >
                                                <img
                                                    src={URL.createObjectURL(variantImages[color][0])}
                                                    alt={`Variant - ${color}`}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                                <IconButton
                                                    sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
                                                    onClick={() => removeVariantImage(color)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </CardContent>
                    </Card>


                    {/* Size Selection */}
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6">{t('productManagement.selectSizes')}</Typography>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {sizeOptions.map(size => (
                                <Button
                                    key={size}
                                    variant={selectedSizes.includes(size) ? 'contained' : 'outlined'}
                                    onClick={() => handleSizeSelect(size)}
                                >
                                    {size}
                                </Button>
                            ))}
                        </Box>
                    </Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={createVariants}
                        sx={{ mt: 2, mb: 2 }}
                    >
                        {t('productManagement.createVariants')}
                    </Button>

                    {/* Display Created Variants */}
                    {formData.variants.length > 0 && (
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <Typography variant="h6">{t('productManagement.createdVariants')}</Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('productManagement.size')}</TableCell>
                                            <TableCell>{t('productManagement.color')}</TableCell>
                                            <TableCell>{t('productManagement.stock')}</TableCell>
                                            <TableCell>{t('productManagement.price')}</TableCell>
                                            <TableCell>{t('productManagement.actions')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {formData.variants.map((variant, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <TextField
                                                        name="size"
                                                        value={variant.size}
                                                        onChange={(e) => handleVariantChange(index, e)}
                                                        fullWidth
                                                        disabled={editingVariantIndex !== index}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="color"
                                                        value={variant.color}
                                                        onChange={(e) => handleVariantChange(index, e)}
                                                        fullWidth
                                                        disabled={editingVariantIndex !== index}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="stock"
                                                        value={variant.stock}
                                                        onChange={(e) => handleVariantChange(index, e)}
                                                        fullWidth
                                                        type="number"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="price"
                                                        value={variant.price}
                                                        onChange={(e) => handleVariantChange(index, e)}
                                                        fullWidth
                                                        type="number"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {editingVariantIndex === index ? (
                                                        <>
                                                            <IconButton onClick={saveVariant} color="primary">
                                                                <SaveIcon />
                                                            </IconButton>
                                                            <IconButton onClick={cancelEditingVariant} color="secondary">
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </>
                                                    ) : (
                                                        <IconButton onClick={() => startEditingVariant(index)} color="primary">
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                    <IconButton onClick={() => removeVariant(index)} color="secondary">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={addVariant}
                                sx={{ mt: 2 }}
                            >
                                <AddIcon />
                            </Button>
                        </Box>
                    )}

                    {/* Optional Fields */}
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => setShowOptionalFields(!showOptionalFields)}
                        sx={{ mt: 2, mb: 2 }}
                        endIcon={showOptionalFields ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    >
                        {showOptionalFields ? t('productManagement.hideOptionalFields') : t('productManagement.showOptionalFields')}
                    </Button>

                    <Collapse in={showOptionalFields}>
                        <TextField
                            label={t('productManagement.brand')}
                            name="brand"
                            value={formData.brand}
                            onChange={onChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label={t('productManagement.discount')}
                            name="discount"
                            value={formData.discount}
                            onChange={onChange}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{ min: 0, max: 100 }}
                        />
                        {/* Tags Input */}
                        <TextField
                            label={t('productManagement.tags')}
                            fullWidth
                            margin="normal"
                            value={tagInput}  // Bind the input value to tagInput state
                            onChange={(e) => setTagInput(e.target.value)}  // Update tagInput state on change
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={addTag} color="primary">
                                        <AddIcon />
                                    </IconButton>
                                )
                            }}
                        />

                        {/* Display added tags */}
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                            {formData.tags.map((tag, index) => (
                                <Chip
                                    key={index}
                                    label={tag}
                                    onDelete={() => removeTag(index)}
                                />
                            ))}
                        </Box>

                        <TextField
                            label={t('productManagement.dimensionsWidth')}
                            name="dimensions.width"
                            value={formData.dimensions.width}
                            onChange={onChange}
                            fullWidth
                            margin="normal"
                            type="number"
                        />
                        <TextField
                            label={t('productManagement.dimensionsHeight')}
                            name="dimensions.height"
                            value={formData.dimensions.height}
                            onChange={onChange}
                            fullWidth
                            margin="normal"
                            type="number"
                        />
                        <TextField
                            label={t('productManagement.dimensionsDepth')}
                            name="dimensions.depth"
                            value={formData.dimensions.depth}
                            onChange={onChange}
                            fullWidth
                            margin="normal"
                            type="number"
                        />
                        <TextField
                            label={t('productManagement.weight')}
                            name="weight"
                            value={formData.weight}
                            onChange={onChange}
                            fullWidth
                            margin="normal"
                            type="number"
                        />
                    </Collapse>

                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
                        {t('productManagement.addProduct')}
                    </Button>
                </form>
            </Paper>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={error ? 'error' : 'success'}>
                    {error || snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default AddProduct;
