import React, { useState, useContext } from 'react';
import { Box, Typography, TextField, Button, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AuthContext } from '../AuthContext'; // Assuming you have an AuthContext that provides user info

function AccountSettings() {
    const { authState } = useContext(AuthContext); // Get user info from context
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
    const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Snackbar severity
    const { t } = useTranslation();  // Initialize useTranslation

    // Function to handle changing the password
    const handleChangePassword = async () => {
        if (newPassword !== confirmNewPassword) {
            setError(t('accountSettings.passwordMismatch')); // Translation for password mismatch
            setSnackbarSeverity('error');
            setSnackbarMessage(t('accountSettings.passwordMismatch'));
            setSnackbarOpen(true); // Open Snackbar
            return;
        }

        setLoading(true);
        try {
            // API call to update password with userId included in the URL
            await axios.put(`/api/users/${authState.user._id}/change-password`, {
                currentPassword,
                newPassword,
            });
            setError('');  // Clear errors
            setSnackbarSeverity('success');
            setSnackbarMessage(t('accountSettings.passwordUpdated')); // Success message
            setSnackbarOpen(true); // Open Snackbar
        } catch (err) {
            setError(`${err.response?.data?.message || t('accountSettings.passwordChangeFailed')}`); // Set error from backend or fallback
            setSnackbarSeverity('error');
            setSnackbarMessage(`${err.response?.data?.message || t('accountSettings.passwordChangeFailed')}`);
            setSnackbarOpen(true); // Open Snackbar
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteAccount = () => {
        // Handle account deletion logic here
        setOpenDeleteDialog(false);
    };

    // Function to close Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (

        <>
            <Box sx={{ backdropFilter: 'blur(10px)', p: 4, backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', minHeight: '80vh' }}>
                <Typography variant="h4" sx={{ mb: 3 }}>{t('accountSettings.title')}</Typography>

                {/* Change Password */}
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6">{t('accountSettings.changePassword')}</Typography>

                    <TextField
                        label={t('accountSettings.currentPassword')}
                        type="password"
                        fullWidth
                        margin="normal"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                    <TextField
                        label={t('accountSettings.newPassword')}
                        type="password"
                        fullWidth
                        margin="normal"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        label={t('accountSettings.confirmNewPassword')}
                        type="password"
                        fullWidth
                        margin="normal"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={handleChangePassword}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : t('accountSettings.updatePassword')}
                    </Button>
                </Box>

                <Divider sx={{ my: 4 }} />

                {/* Delete Account */}
                <Box sx={{ textAlign: 'center' }}>
                    <Button variant="contained" color="secondary" onClick={() => setOpenDeleteDialog(true)}>
                        {t('accountSettings.deleteAccount')}
                    </Button>
                </Box>

                {/* Delete Account Confirmation Dialog */}
                <Dialog
                    open={openDeleteDialog}
                    onClose={() => setOpenDeleteDialog(false)}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">{t('accountSettings.confirmDeleteTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            {t('accountSettings.confirmDeleteMessage')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                            {t('accountSettings.cancel')}
                        </Button>
                        <Button onClick={handleDeleteAccount} color="secondary" autoFocus>
                            {t('accountSettings.delete')}
                        </Button>
                    </DialogActions>
                </Dialog>


            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>


    );
}

export default AccountSettings;
