import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    Button,
    Container,
    Box,
    Typography,
    IconButton,
    Paper,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
    Checkbox,
    Toolbar,
    Switch
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../AuthContext';
import { useTranslation } from 'react-i18next';

function RedeemableItemsList() {
    const { authState } = useContext(AuthContext);
    const [redeemableItems, setRedeemableItems] = useState([]); // Initialize with an empty array
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]); // Track selected items
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [editingItemId, setEditingItemId] = useState(null);
    const [editedItem, setEditedItem] = useState(null);
    const [searchName, setSearchName] = useState('');
    const [searchId, setSearchId] = useState(''); // Add this state for ID search


    const { t } = useTranslation();

    useEffect(() => {
        fetchRedeemableItems();
    }, [currentPage]);

    const fetchRedeemableItems = async () => {
        try {
            setLoading(true);
            let query = `/api/redeemable-items?page=${currentPage}&limit=${itemsPerPage}`;

            // Prioritize search by id
            if (searchId) {
                query = `/api/redeemable-items?id=${searchId}`;
            } else if (searchName) {
                query = `/api/redeemable-items?name=${searchName}`;
            }

            const res = await axios.get(query, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });

            // Ensure redeemableItems is always an array to prevent errors
            setRedeemableItems(res.data.items || []);
            setTotalPages(res.data.totalPages || 1);
        } catch (err) {
            console.error('Error fetching redeemable items:', err);
            setError(t('redeemManagement.fetchError'));
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (item) => {
        setEditingItemId(item._id);
        setEditedItem({ ...item });
    };

    const handleCancelEdit = () => {
        setEditingItemId(null);
        setEditedItem(null);
    };

    const handleSaveEdit = async () => {
        try {
            await axios.put(`/api/redeemable-items/${editedItem._id}`, editedItem, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            setSnackbarMessage(t('redeemManagement.itemUpdated'));
            setEditingItemId(null);
            fetchRedeemableItems(); // Refresh the item list
        } catch (err) {
            console.error('Error updating redeemable item:', err);
            setError(t('redeemManagement.updateError'));
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`/api/redeemable-items/${itemToDelete._id}`, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            setSnackbarMessage(t('redeemManagement.itemDeleted'));
            fetchRedeemableItems(); // Refresh the item list
            closeDeleteDialog();
        } catch (err) {
            console.error('Error deleting redeemable item:', err);
            setError(t('redeemManagement.deleteError'));
        } finally {
            setSnackbarOpen(true);
        }
    };

    const confirmBulkDelete = () => {
        setBulkDeleteDialogOpen(true);  // Open the bulk delete confirmation dialog
    };

    const handleBulkDelete = async () => {
        try {
            await Promise.all(
                selectedItems.map((itemId) =>
                    axios.delete(`/api/redeemable-items/${itemId}`, {
                        headers: { Authorization: `Bearer ${authState.token}` },
                    })
                )
            );
            setSnackbarMessage(t('redeemManagement.itemsDeleted'));
            fetchRedeemableItems(); // Refresh the item list
            setSelectedItems([]); // Clear selected items
        } catch (err) {
            console.error('Error deleting selected items:', err);
            setError(t('redeemManagement.deleteError'));
        } finally {
            setSnackbarOpen(true);
            setBulkDeleteDialogOpen(false);  // Close the bulk delete dialog
        }
    };

    const handleToggleActive = async (itemId, newIsActive) => {
        try {
            await axios.put(
                `/api/redeemable-items/${itemId}`,
                { isActive: newIsActive },
                { headers: { Authorization: `Bearer ${authState.token}` } }
            );
            setSnackbarMessage(t('redeemManagement.statusUpdated'));
            fetchRedeemableItems(); // Refresh the item list
        } catch (err) {
            console.error('Error updating item status:', err);
            setError(t('redeemManagement.updateError'));
        } finally {
            setSnackbarOpen(true);
        }
    };

    
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const openDeleteDialog = (item) => {
        setItemToDelete(item);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setItemToDelete(null);
    };

    const handleChange = (e) => {
        setEditedItem({ ...editedItem, [e.target.name]: e.target.value });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSearch = () => {
        setCurrentPage(1); // Reset to page 1 when searching
        fetchRedeemableItems();
    };

    const handleSelectItem = (itemId) => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter((id) => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedItems(redeemableItems.map((item) => item._id));
        } else {
            setSelectedItems([]);
        }
    };

    return (
        <Container maxWidth="lg" sx={{ minHeight: '80vh' }}>
            <Paper elevation={3} sx={{
                height: '100%',
                width: '100%',
                backdropFilter: 'blur(10px)',
                p: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: '16px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)'
            }}>
                <Typography variant="h5" gutterBottom>
                    {t('redeemManagement.itemList')}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                    <TextField
                        label={t('redeemManagement.searchById')}
                        value={searchId}
                        onChange={(e) => setSearchId(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label={t('redeemManagement.searchByName')}
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        fullWidth
                    />
                    <Button variant="contained" color="primary" onClick={handleSearch}>
                        {t('redeemManagement.search')}
                    </Button>
                </Box>


                {selectedItems.length > 0 && (
                    <Toolbar>
                        <Button variant="contained" color="secondary" onClick={confirmBulkDelete}>
                            {t('redeemManagement.bulkDelete')}
                        </Button>
                    </Toolbar>
                )}

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <TableContainer component={Paper} sx={{ borderRadius: '16px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={
                                                    selectedItems.length > 0 && selectedItems.length < redeemableItems.length
                                                }
                                                checked={
                                                    redeemableItems.length > 0 && selectedItems.length === redeemableItems.length
                                                }
                                                onChange={handleSelectAll}
                                            />
                                        </TableCell>
                                        <TableCell>{t('redeemManagement.state')}</TableCell>
                                        <TableCell>{t('redeemManagement.name')}</TableCell>
                                        <TableCell>{t('redeemManagement.type')}</TableCell>
                                        <TableCell>{t('redeemManagement.pointsRequired')}</TableCell>
                                        <TableCell>{t('redeemManagement.stock')}</TableCell>
                                        <TableCell>{t('redeemManagement.actions')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {redeemableItems.length > 0 ? (
                                        redeemableItems.map((item) => (
                                            <TableRow key={item._id}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={selectedItems.includes(item._id)}
                                                        onChange={() => handleSelectItem(item._id)}
                                                    />
                                                </TableCell>
                                                {editingItemId === item._id ? (
                                                    <>


                                                        {/* Edit Form */}
                                                        <TableCell>
                                                            <TextField
                                                                name="name"
                                                                value={editedItem.name}
                                                                onChange={handleChange}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="type"
                                                                value={editedItem.type}
                                                                disabled
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="pointsRequired"
                                                                type="number"
                                                                value={editedItem.pointsRequired}
                                                                onChange={handleChange}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="stock"
                                                                type="number"
                                                                value={editedItem.stock}
                                                                onChange={handleChange}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton onClick={handleSaveEdit} color="primary">
                                                                <SaveIcon />
                                                            </IconButton>
                                                            <IconButton onClick={handleCancelEdit} color="secondary">
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* View Mode */}
                                                        <TableCell>
                                                            <Switch
                                                                checked={item.isActive}
                                                                onChange={() => handleToggleActive(item._id, !item.isActive)}
                                                                color="primary"
                                                            />
                                                        </TableCell>
                                                        <TableCell>{item.name}</TableCell>
                                                        <TableCell>{item.type}</TableCell>
                                                        <TableCell>{item.pointsRequired}</TableCell>
                                                        <TableCell>{item.stock}</TableCell>
                                                        <TableCell>
                                                            <IconButton onClick={() => handleEditClick(item)} color="primary">
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton onClick={() => openDeleteDialog(item)} color="secondary">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                {t('redeemManagement.noItemsFound')}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {!searchName && (
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                            />
                        )}
                    </Box>
                )}
            </Paper>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{t('redeemManagement.confirmDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        {t('redeemManagement.deleteConfirmationMessage', { itemName: itemToDelete?.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        {t('redeemManagement.cancel')}
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        {t('redeemManagement.delete')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Bulk Delete Confirmation Dialog */}
            <Dialog
                open={bulkDeleteDialogOpen}
                onClose={() => setBulkDeleteDialogOpen(false)}
                aria-labelledby="bulk-delete-dialog-title"
                aria-describedby="bulk-delete-dialog-description"
            >
                <DialogTitle id="bulk-delete-dialog-title">{t('redeemManagement.confirmBulkDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="bulk-delete-dialog-description">
                        {t('redeemManagement.bulkDeleteConfirmationMessage', { count: selectedItems.length })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBulkDeleteDialogOpen(false)} color="primary">
                        {t('redeemManagement.cancel')}
                    </Button>
                    <Button onClick={handleBulkDelete} color="secondary" autoFocus>
                        {t('redeemManagement.delete')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for Success/Error Messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={error ? 'error' : 'success'}>
                    {error || snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default RedeemableItemsList;
