import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import {
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Container,
    Box,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Divider,
    IconButton,
    Tooltip,
    Drawer,
    InputAdornment,
    Pagination,
    Slider,
    useMediaQuery,
    Snackbar,
    Alert,
    CardActionArea,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { Circle, ChevronLeft, ChevronRight, Search, FavoriteBorder, Clear } from '@mui/icons-material';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { AuthContext } from './AuthContext';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

function Products() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const { t } = useTranslation(); // Initialize translation hook

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [mainCategory, setMainCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [sortOption, setSortOption] = useState('');
    const [colorFilter, setColorFilter] = useState([]);
    const [sizeFilter, setSizeFilter] = useState([]);
    const [priceRange, setPriceRange] = useState([0, 1000]);
    const [loading, setLoading] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [favorites, setFavorites] = useState([]);
    const [error, setError] = useState('');
    const [isMulticolorSelected, setIsMulticolorSelected] = useState(false);
    const [hoveredColors, setHoveredColors] = useState({});


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { authState } = useContext(AuthContext);

    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 16;

    const colors = [
        { name: 'Red', colorCode: '#f44336' },
        { name: 'Blue', colorCode: '#2196f3' },
        { name: 'Green', colorCode: '#4caf50' },
        { name: 'Black', colorCode: '#000000' },
        { name: 'White', colorCode: '#ffffff' },
        { name: 'Yellow', colorCode: '#ffeb3b' },
        { name: 'Orange', colorCode: '#ff9800' },
        { name: 'Purple', colorCode: '#9c27b0' },
        { name: 'Pink', colorCode: '#e91e63' },
        { name: 'Gray', colorCode: '#9e9e9e' },
        { name: 'Teal', colorCode: '#009688' },
        { name: 'Brown', colorCode: '#795548' },
    ];

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const res = await axios.get('/api/products', {
                    params: {
                        productType: 'for_sale',
                        isActive: true,
                    },
                });
                setProducts(res.data.products);
                setFilteredProducts(res.data.products);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching products:', err);
                setLoading(false);
            }
        };

        const fetchFavorites = async () => {
            try {
                const res = await axios.get('/api/favorite', {
                    headers: { Authorization: `Bearer ${authState.token}` },
                });
                setFavorites(res.data.favorites.map((fav) => fav.product._id));
            } catch (err) {
                console.error('Error fetching favorites:', err);
            }
        };

        fetchProducts();
        fetchFavorites();
    }, [authState.token]);

    useEffect(() => {
        // Extract category from query params on initial load
        const params = new URLSearchParams(location.search);
        const category = params.get('category');
        if (category) {
            setMainCategory(category.charAt(0).toUpperCase() + category.slice(1));
        }
    }, [location.search]);

    useEffect(() => {
        let filtered = products;

        if (mainCategory) {
            filtered = filtered.filter(product => product.category.mainCategory.toLowerCase() === mainCategory.toLowerCase());
        }

        if (subCategory) {
            filtered = filtered.filter(product => product.category.subCategory.toLowerCase() === subCategory.toLowerCase());
        }

        if (sizeFilter.length > 0) {
            filtered = filtered.filter(product =>
                product.variants.some(variant => sizeFilter.includes(variant.size))
            );
        }

        if (colorFilter.length > 0) {
            filtered = filtered.filter(product =>
                product.variants.some(variant => colorFilter.includes(variant.color))
            );
        }

        if (searchQuery) {
            filtered = filtered.filter(product =>
                product.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        filtered = filtered.filter(product => product.price >= priceRange[0] && product.price <= priceRange[1]);

        if (sortOption) {
            if (sortOption === 'price-asc') {
                filtered.sort((a, b) => a.price - b.price);
            } else if (sortOption === 'price-desc') {
                filtered.sort((a, b) => b.price - a.price);
            } else if (sortOption === 'name-asc') {
                filtered.sort((a, b) => a.name.localeCompare(b.name));
            } else if (sortOption === 'name-desc') {
                filtered.sort((a, b) => b.name.localeCompare(a.name));
            }
        }

        setFilteredProducts(filtered);
    }, [products, mainCategory, subCategory, sizeFilter, colorFilter, searchQuery, priceRange, sortOption]);

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const handleSizeChange = (size) => {
        if (sizeFilter.includes(size)) {
            setSizeFilter(sizeFilter.filter((s) => s !== size));
        } else {
            setSizeFilter([...sizeFilter, size]);
        }
    };


    const handleColorChange = (color) => {
        if (colorFilter.includes(color)) {
            setColorFilter(colorFilter.filter((c) => c !== color));
        } else {
            setColorFilter([...colorFilter, color]);
        }
    };


    const handleClearColors = () => {
        setColorFilter([]);
        setIsMulticolorSelected(false);

    };

    const handleMultipleColors = () => {
        setColorFilter([...colorFilter, 'Multicolor']);
        setIsMulticolorSelected(!isMulticolorSelected);
        if (colorFilter.includes('Multicolor')) {
            setColorFilter(colorFilter.filter((c) => c !== 'Multicolor'));
        } else {
            setColorFilter([...colorFilter, 'Multicolor']);
        }
    };

    const toggleFavorite = async (productId) => {
        if (!authState.isAuthenticated) {
            setError(t('products.errorLoginFavorite')); // Use translation key
            setSnackbarOpen(true);
            return;
        }

        try {
            if (favorites.includes(productId)) {
                await axios.delete(`/api/favorite/${productId}`, {
                    headers: { Authorization: `Bearer ${authState.token}` },
                });
                setFavorites(favorites.filter((id) => id !== productId));
            } else {
                await axios.post(`/api/favorite/${productId}`, {}, {
                    headers: { Authorization: `Bearer ${authState.token}` },
                });
                setFavorites([...favorites, productId]);
            }
        } catch (err) {
            console.error('Error toggling favorite:', err);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handlePriceInputChange = (index, value) => {
        if (value === '' || /^\d*$/.test(value)) {
            const newPriceRange = [...priceRange];
            newPriceRange[index] = value === '' ? '' : value;
            setPriceRange(newPriceRange);
        }
    };

    const handlePriceBlur = (index) => {
        const newPriceRange = [...priceRange];
        newPriceRange[index] = newPriceRange[index] === '' ? 0 : Math.max(0, Math.min(500, parseInt(newPriceRange[index], 10)));
        setPriceRange(newPriceRange);
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column', mb: 4 }}>
            <Box sx={{ width: '100%', minHeight: '100vh' }}>
                <Box sx={{ display: 'block', mt: 5 }}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backdropFilter: 'blur(10px)',
                            p: 4,
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            borderRadius: '16px',
                            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mb: 4 }}>
                            {/* Filter Button on the Left */}
                            <Box
                                sx={{
                                    zIndex: 1300,
                                    transition: 'left 0.3s ',
                                    mr: 2,
                                    display: 'flex',
                                    alignItems: 'center', // Ensures the button is centered vertically
                                    flexDirection: 'column',
                                }}
                            >
                                <IconButton
                                    onClick={() => setDrawerOpen(!drawerOpen)}
                                    sx={{
                                        backgroundColor: theme.palette.secondary.main,
                                        color: 'white',
                                        width: 60,  // Set desired width
                                        height: 60, // Set desired height
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '2.5rem', // Change icon size if needed
                                        },
                                    }}
                                >
                                    {drawerOpen ? <PendingRoundedIcon /> : <PendingRoundedIcon />}
                                </IconButton>
                                {/* <Typography variant="caption">{t('products.filter')}</Typography> */}
                            </Box>

                            {/* Search Bar Centered */}
                            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    placeholder={t('products.searchPlaceholder')} // Use translation key
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    sx={{
                                        width: '100%',
                                        //maxWidth: '800px',
                                        backgroundColor: 'white',
                                        borderRadius: '50px',
                                        mb: 0, // Ensure margin doesn't affect alignment
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '50px',
                                            height: '60px', // Match the height of the button
                                            display: 'flex',
                                            alignItems: 'center', // Vertically center the input
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            paddingLeft: '20px',
                                            paddingRight: '16px',
                                        },
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>


                        {filteredProducts.length === 0 ? (
                            <Typography variant="h6" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {t('products.noProductsFound')} {/* Use translation key */}
                            </Typography>
                        ) : (
                            <Grid container spacing={isMobile ? 2 : 4} sx={{ maxWidth: '1200px' }}>
                                {currentProducts.map((product) => (
                                    <Grid item key={product._id} xs={6} sm={6} md={3}>
                                        <Box
                                            sx={{
                                                height: {
                                                    xs: '250px', // Smaller height for mobile screens (extra-small)
                                                    sm: '300px', // Slightly larger for tablets
                                                    md: '320px', // Full height for desktop and larger
                                                    lg: '400px',
                                                },
                                                display: 'flex',
                                                flexDirection: 'column',
                                                borderRadius: '16px',
                                                bgcolor: theme.palette.accent.main,
                                                color: 'white',
                                                boxShadow: '0 4px 12px rgba(0,0,0,0.1)', // Subtle shadow for depth
                                                transition: 'box-shadow 0.3s ease-in-out', // Animate shadow on hover
                                                '&:hover': { boxShadow: '0 6px 16px rgba(0,0,0,0.3)' }, // Increase shadow on hover
                                            }}
                                        >
                                            {/* Image Box */}
                                            <Box
                                                component={product.totalStock > 0 ? Link : 'div'}
                                                to={product.totalStock > 0 ? `/products/${product._id}` : undefined}
                                                sx={{
                                                    flexGrow: 2,
                                                    width: '100%',
                                                    height: {
                                                        xs: '60%', // Image takes 60% of the height on small screens
                                                        md: '66%', // Image takes 66% on larger screens
                                                    },
                                                    position: 'relative',
                                                    overflow: 'hidden', // Ensure image stays within bounds
                                                    cursor: product.totalStock > 0 ? 'pointer' : 'default', // Pointer only if stock > 0
                                                }}
                                            >
                                                {/* Product Image */}
                                                <Box
                                                    component="img"
                                                    src={
                                                        hoveredColors[product._id]
                                                            ? `/api/images/${product._id}/${product.variants.find(variant => variant.color === hoveredColors[product._id])?.imageUrl[0]}`
                                                            : `/api/images/${product._id}/${product.images[0]}`
                                                    }
                                                    alt={product.name}
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px 16px 0 0',
                                                        filter: product.totalStock === 0 ? 'grayscale(100%)' : 'none',
                                                    }}
                                                />

                                                {/* Grey Transparent Layer if Sold Out */}
                                                {product.totalStock === 0 && (
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Grey transparent overlay
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: '16px 16px 0 0',
                                                        }}
                                                    >
                                                        {/* "Sold Out" Label */}
                                                        <Typography
                                                            variant="h7"
                                                            sx={{
                                                                color: 'white',
                                                                fontWeight: 'bold',
                                                                position: 'absolute',
                                                                top: 10,
                                                                right: 10,
                                                                backgroundColor: 'rgba(255, 0, 0, 0.8)', // Red background
                                                                padding: '4px 8px',
                                                                borderRadius: '4px',
                                                            }}
                                                        >
                                                            Sold Out
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>


                                            {/* Content Box */}
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                    padding: {
                                                        xs: 1, // Smaller padding on mobile
                                                        md: 2, // Full padding on larger screens
                                                    },
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between', // Evenly space content
                                                    position: 'relative',
                                                }}
                                            >
                                                {/* Product Name */}
                                                <Typography gutterBottom variant="h6" sx={{ fontSize: { xs: '1rem', md: '1.2rem' } }}>
                                                    {product.name}
                                                </Typography>

                                                {/* Product Price */}
                                                <Typography variant="body2" sx={{ color: "white", fontWeight: 'medium', fontSize: { xs: '0.9rem', md: '1rem' } }}>
                                                    {product.price} MAD
                                                </Typography>

                                                {/* Color Variants */}
                                                <Grid container spacing={1} sx={{ mt: 1 }}>
                                                    {[...new Set(product.variants
                                                        .filter(variant => variant.color !== 'Multicolor')
                                                        .map(variant => variant.color)
                                                    )].map((color, index) => (
                                                        <Grid item key={index}>
                                                            <Tooltip title={color}>
                                                                <IconButton
                                                                    onMouseEnter={() => setHoveredColors((prev) => ({ ...prev, [product._id]: color }))}  // Set hovered color for this product
                                                                    onMouseLeave={() => setHoveredColors((prev) => ({ ...prev, [product._id]: null }))}  // Reset hovered color  
                                                                    sx={{

                                                                        backgroundColor: colors.find(c => c.name === color)?.colorCode || '#d3d3d3',
                                                                        border: colors.find(c => c.name === color)?.name === 'White' ? '2px solid wlack' : '2px solid white',
                                                                        width: {
                                                                            xs: 16, // Smaller circles on mobile screens
                                                                            sm: 20, // Slightly larger on tablets
                                                                            md: 24, // Full size on larger screens
                                                                        },
                                                                        height: {
                                                                            xs: 16,
                                                                            sm: 20,
                                                                            md: 24,
                                                                        },
                                                                        '&:hover': { transform: 'scale(1.1)', backgroundColor: colors.find(c => c.name === color)?.colorCode || '#d3d3d3', }, // Slight zoom on hover
                                                                    }}
                                                                >

                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    ))}

                                                    {/* Multicolor Variant */}
                                                    {product.variants.some(variant => variant.color === 'Multicolor') && (
                                                        <Grid item>
                                                            <Tooltip title="Multicolor">
                                                                <IconButton
                                                                    onMouseEnter={() => setHoveredColors((prev) => ({ ...prev, [product._id]: 'Multicolor' }))}  // Set hovered color for this product
                                                                    onMouseLeave={() => setHoveredColors((prev) => ({ ...prev, [product._id]: null }))}
                                                                    sx={{
                                                                        background: 'linear-gradient(135deg, red, orange, yellow, green, blue)',
                                                                        border: '2px solid white',
                                                                        width: {
                                                                            xs: 16,
                                                                            sm: 20,
                                                                            md: 24,
                                                                        },
                                                                        height: {
                                                                            xs: 16,
                                                                            sm: 20,
                                                                            md: 24,
                                                                        },
                                                                        '&:hover': { transform: 'scale(1.1)' }, // Slight zoom on hover
                                                                    }}
                                                                >
                                                                    <Circle sx={{ color: 'transparent' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                {product.totalStock === 0 && (
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Grey transparent overlay
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            borderRadius: ' 0 0 16px 16px',
                                                        }}
                                                    >
                                                    </Box>
                                                )}
                                                {/* Favorite Icon */}
                                                <IconButton
                                                    sx={{ position: 'absolute', top: 8, right: 8 }}
                                                    onClick={() => toggleFavorite(product._id)}
                                                >
                                                    {favorites.includes(product._id) ? <FavoriteIcon color="error" /> : <FavoriteBorder />}
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Grid>

                                ))}
                            </Grid>
                        )}
                    </Box>

                    {filteredProducts.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                            <Pagination
                                count={Math.ceil(filteredProducts.length / productsPerPage)}
                                page={currentPage}
                                onChange={(e, value) => setCurrentPage(value)}
                                color="primary"
                            />
                        </Box>
                    )}
                </Box>



                <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    sx={{ '& .MuiDrawer-paper': { width: 320, padding: 3, backgroundColor: 'secondary.main', color: 'white' } }}
                >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        {t('products.filters')}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {/* Main Category Filter */}
                    <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                        <InputLabel
                            sx={{
                                color: 'white',
                                '&.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        >
                            {t('products.mainCategory')}
                        </InputLabel>
                        <Select
                            value={mainCategory}
                            onChange={(e) => setMainCategory(e.target.value)}
                            label={t('products.mainCategory')}
                            sx={{
                                color: 'white', // Text color inside Select
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white', // White border color
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                            }}
                        >
                            <MenuItem value="">{t('products.allMainCategories')}</MenuItem>
                            <MenuItem value="Men">{t('products.mensClothing')}</MenuItem>
                            <MenuItem value="Women">{t('products.womensClothing')}</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Subcategory Filter */}
                    <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                        <InputLabel
                            sx={{
                                color: 'white',
                                '&.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        >
                            {t('products.subCategory')}
                        </InputLabel>
                        <Select
                            value={subCategory}
                            onChange={(e) => setSubCategory(e.target.value)}
                            label={t('products.subCategory')}
                            sx={{
                                color: 'white', // Text color inside Select
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                            }}
                        >
                            <MenuItem value="">{t('products.allSubcategories')}</MenuItem>
                            <MenuItem value="Shirts">{t('products.shirts')}</MenuItem>
                            <MenuItem value="Pants">{t('products.pants')}</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Price Range Filter */}
                    <Typography variant="subtitle1" sx={{ mb: 1, color: 'white' }}>
                        {t('products.priceRange')}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                        <TextField
                            label={t('products.min')}
                            type="text"
                            value={priceRange[0]}
                            onChange={(e) => handlePriceInputChange(0, e.target.value)}
                            onBlur={() => handlePriceBlur(0)}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            sx={{
                                width: '48%',
                                color: 'white',
                                '& .MuiInputLabel-root': { color: 'white' }, // Label color
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: 'white' }, // White border
                                    '&:hover fieldset': { borderColor: 'white' },
                                    '&.Mui-focused fieldset': { borderColor: 'white' },
                                },
                                '& input': {
                                    color: 'white', // Text color
                                },
                            }}
                        />

                        <TextField
                            label={t('products.max')}
                            type="text"
                            value={priceRange[1]}
                            onChange={(e) => handlePriceInputChange(1, e.target.value)}
                            onBlur={() => handlePriceBlur(1)}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            sx={{
                                width: '48%',
                                color: 'white',
                                '& .MuiInputLabel-root': { color: 'white' },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: 'white' },
                                    '&:hover fieldset': { borderColor: 'white' },
                                    '&.Mui-focused fieldset': { borderColor: 'white' },
                                },
                                '& input': {
                                    color: 'white',
                                },
                            }}
                        />
                    </Box>
                    <Slider
                        value={priceRange}
                        onChange={(e, newValue) => setPriceRange(newValue)}
                        valueLabelDisplay="auto"
                        min={0}
                        max={1000}
                        sx={{ mb: 1, color: 'accent.main' }}
                    />

                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        {t('products.filterBySize')}
                    </Typography>

                    <Grid container spacing={1} sx={{ mb: 3, justifyContent: 'start' }}>
                        {['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'Standard'].map((size) => (
                            <Grid item key={size}>
                                <Tooltip title={size}>
                                    <IconButton
                                        onClick={() => handleSizeChange(size)}
                                        sx={{
                                            backgroundColor: sizeFilter.includes(size) ? theme.palette.accent.main : 'inherit',
                                            border: sizeFilter.includes(size) ? `2px solid ${theme.palette.accent.main}` : '1px solid #d3d3d3',
                                            borderRadius: '20px', // Makes the button pill-shaped
                                            px: 2, // Padding for width
                                            py: 1, // Padding for height
                                            color: sizeFilter.includes(size) ? 'white' : 'white',
                                            transition: 'background-color 0.3s, border-color 0.3s', // Smooth transition
                                            '&:hover': {
                                                backgroundColor: sizeFilter.includes(size) ? theme.palette.accent.light : '#f0f0f0',
                                            },
                                            width: '75px'
                                        }}
                                    >
                                        <Typography variant="body2">
                                            {size}
                                        </Typography>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        ))}

                        <Grid item>
                            <Tooltip title={t('products.clearSizes')}>
                                <IconButton
                                    size='small'
                                    onClick={() => setSizeFilter([])}
                                    sx={{
                                        width: '75px',
                                        border: '2px solid red',
                                        borderRadius: '50px', // Makes the clear button pill-shaped
                                        transition: 'background-color 0.3s, border-color 0.3s', // Smooth transition

                                    }}
                                >
                                    <Clear style={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>



                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        {t('products.filterByColor')}
                    </Typography>
                    <Grid container spacing={1} sx={{ mb: 3 }}>
                        {colors.map((color) => (
                            <Grid item key={color.name}>
                                <Tooltip title={color.name}>
                                    <IconButton
                                        onClick={() => handleColorChange(color.name)}
                                        sx={{
                                            backgroundColor: color.colorCode,
                                            border: colorFilter.includes(color.name)
                                                ? (color.name === "White" ? '4px solid #222' : '4px solid #f3f3f3')
                                                : '2px solid #d3d3d3',
                                            boxShadow: colorFilter.includes(color.name) ? '0 2px 10px rgba(0, 0, 0, 0.4)' : '0',
                                            width: 30,
                                            height: 30,
                                        }}
                                    >
                                        <Circle style={{ color: color.colorCode }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        ))}
                        <Grid item>
                            <Tooltip title={t('products.multipleColors')}>
                                <IconButton
                                    onClick={handleMultipleColors}
                                    sx={{
                                        background: 'linear-gradient(135deg, red, orange, yellow, green, blue)',
                                        border: isMulticolorSelected ? '4px solid #f3f3f3' : '2px solid #d3d3d3',
                                        boxShadow: isMulticolorSelected ? '0 2px 10px rgba(0, 0, 0, 0.4)' : '0',
                                        width: 30,
                                        height: 30,
                                    }}
                                >
                                    <Circle style={{ color: 'transparent' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t('products.clearColors')}>
                                <IconButton
                                    onClick={handleClearColors}
                                    sx={{
                                        border: '2px solid red',
                                        width: 30,
                                        height: 30,
                                    }}
                                >
                                    <Clear style={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel
                            sx={{
                                color: 'white',
                                '&.Mui-focused': {
                                    color: 'white',
                                },
                            }}
                        >
                            {t('products.sortBy')}
                        </InputLabel>
                        <Select
                            value={sortOption}
                            onChange={(e) => setSortOption(e.target.value)}
                            label={t('products.sortBy')}
                            sx={{
                                color: 'white', // Text color inside Select
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white', // White border color
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                            }}
                        >
                            <MenuItem value="price-asc">{t('products.priceLowHigh')}</MenuItem>
                            <MenuItem value="price-desc">{t('products.priceHighLow')}</MenuItem>
                            <MenuItem value="name-asc">{t('products.nameAToZ')}</MenuItem>
                            <MenuItem value="name-desc">{t('products.nameZToA')}</MenuItem>
                        </Select>
                    </FormControl>

                </Drawer>

                {/* Snackbar for Error Message */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    );
}

export default Products;
