import React, { useContext, useEffect } from 'react';
import {
    Box,
    Avatar,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AuthContext } from '../AuthContext';
import { useTranslation } from 'react-i18next';

function ProfileSidebar() {
    const { authState, logout, refetchUser } = useContext(AuthContext);
    const { t } = useTranslation(); // Import translation hook

    useEffect(() => {
        refetchUser(); // Refetch user data when the component mounts
    }, []);

    // Function to return styles for active links
    const getActiveStyle = ({ isActive }) => ({
        backgroundColor: isActive ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
        borderRadius: isActive ? '8px' : '0',
        color: isActive ? 'primary.main' : 'inherit',
        textDecoration: 'none',
    });

    return (
        <Box sx={{ height: '100%', borderRight: '1px solid #ddd', width: '100%', backdropFilter: 'blur(10px)', p: 4, backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 3 }}>
                <Avatar
                    sx={{
                        width: 80,
                        height: 80,
                        marginBottom: 1,
                        backgroundColor: theme => theme.palette.secondary.main
                    }}>
                    {authState.user.name[0].toUpperCase()}
                </Avatar>
                <Typography variant="h6">{authState.user.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                    {t('profileSidebar.points')}: {authState.user.points}
                </Typography>
            </Box>

            <List>
                <ListItem button component={NavLink} to="/profile/edit" style={getActiveStyle}>
                    <ListItemIcon>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('profileSidebar.editProfile')} />
                </ListItem>
                <ListItem button component={NavLink} to="/profile/orders" style={getActiveStyle}>
                    <ListItemIcon>
                        <HistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('profileSidebar.orderHistory')} />
                </ListItem>
                <ListItem button component={NavLink} to="/profile/favorites" style={getActiveStyle}>
                    <ListItemIcon>
                        <FavoriteIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('profileSidebar.favoriteProducts')} />
                </ListItem>
                <ListItem button component={NavLink} to="/profile/settings" style={getActiveStyle}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('profileSidebar.accountSettings')} />
                </ListItem>
                <ListItem button component={NavLink} to="/profile/referrals" style={getActiveStyle}>
                    <ListItemIcon>
                        <ShareIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('profileSidebar.inviteFriend')} />
                </ListItem>
            </List>

            <Box sx={{ mt: 'auto', pt: 3 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<ExitToAppIcon />}
                    onClick={logout}
                    fullWidth
                >
                    {t('profileSidebar.logout')}
                </Button>
            </Box>
        </Box>
    );
}

export default ProfileSidebar;
