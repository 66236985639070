import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    Button,
    Container,
    Box,
    Typography,
    IconButton,
    Paper,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
    Checkbox,
    Toolbar,
    Switch,
    MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../AuthContext';
import { useTranslation } from 'react-i18next';

function ProductList() {
    const { authState } = useContext(AuthContext);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState([]); // Track selected products
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [editingProductId, setEditingProductId] = useState(null);
    const [editedProduct, setEditedProduct] = useState(null);
    const [searchId, setSearchId] = useState('');
    const [searchName, setSearchName] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        fetchProducts();
    }, [currentPage]);

    const fetchProducts = async () => {
        try {
            setLoading(true);
            let query = `/api/products?page=${currentPage}&limit=${productsPerPage}`;

            if (searchId) {
                query = `/api/products?id=${searchId}`;
            } else if (searchName) {
                query = `/api/products?name=${searchName}`;
            }

            const res = await axios.get(query, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });

            if (searchId || searchName) {
                setProducts(res.data.length ? res.data : [res.data]);
                setTotalPages(1); // If searching, ignore pagination
            } else {
                setProducts(res.data.products);
                setTotalPages(res.data.totalPages);
            }
        } catch (err) {
            console.error('Error fetching products:', err);
            setError(t('productManagement.fetchError'));
        } finally {
            setLoading(false);
        }
    };


    const handleToggleActive = async (productId, isActive) => {
        try {
            await axios.put(`/api/products/${productId}`, { isActive: !isActive }, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            fetchProducts(); // Refresh the product list after changing the state
        } catch (err) {
            console.error('Error toggling product active state:', err);
            setError(t('productManagement.toggleActiveError'));
            setSnackbarOpen(true);
        }
    };


    const handleEditClick = (product) => {
        setEditingProductId(product._id);
        setEditedProduct({ ...product });
    };

    const handleCancelEdit = () => {
        setEditingProductId(null);
        setEditedProduct(null);
    };


    const handleSaveEdit = async () => {
        try {
            await axios.put(`/api/products/${editedProduct._id}`, editedProduct, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            setSnackbarMessage(t('productManagement.productUpdated'));
            setEditingProductId(null);
            fetchProducts(); // Refresh the product list
        } catch (err) {
            console.error('Error updating product:', err);
            setError(t('productManagement.updateError'));
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.delete(`/api/products/${productToDelete._id}`, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            setSnackbarMessage(t('productManagement.productDeleted'));
            fetchProducts(); // Refresh the product list
            closeDeleteDialog();
        } catch (err) {
            console.error('Error deleting product:', err);
            setError(t('productManagement.deleteError'));
        } finally {
            setSnackbarOpen(true);
        }
    };

    const confirmBulkDelete = () => {
        setBulkDeleteDialogOpen(true);  // Open the bulk delete confirmation dialog
    };

    const handleBulkDelete = async () => {
        try {
            await Promise.all(
                selectedProducts.map((productId) =>
                    axios.delete(`/api/products/${productId}`, {
                        headers: { Authorization: `Bearer ${authState.token}` },
                    })
                )
            );
            setSnackbarMessage(t('productManagement.productsDeleted'));
            fetchProducts(); // Refresh the product list
            setSelectedProducts([]); // Clear selected products
        } catch (err) {
            console.error('Error deleting selected products:', err);
            setError(t('productManagement.deleteError'));
        } finally {
            setSnackbarOpen(true);
            setBulkDeleteDialogOpen(false);  // Close the bulk delete dialog
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const openDeleteDialog = (product) => {
        setProductToDelete(product);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setProductToDelete(null);
    };

    const handleChange = (e) => {
        setEditedProduct({ ...editedProduct, [e.target.name]: e.target.value });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSearch = () => {
        setCurrentPage(1); // Reset to page 1 when searching
        fetchProducts();
    };

    const handleSelectProduct = (productId) => {
        if (selectedProducts.includes(productId)) {
            setSelectedProducts(selectedProducts.filter((id) => id !== productId));
        } else {
            setSelectedProducts([...selectedProducts, productId]);
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedProducts(products.map((product) => product._id));
        } else {
            setSelectedProducts([]);
        }
    };

    return (
        <Container sx={{ minHeight: '80vh', width: '100%' }}>
            <Paper elevation={3} sx={{
                height: '100%',
                width: '100%',
                backdropFilter: 'blur(10px)',
                p: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius: '16px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)'
            }}>
                <Typography variant="h5" gutterBottom>
                    {t('productManagement.productList')}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                    <TextField
                        label={t('productManagement.searchById')}
                        value={searchId}
                        onChange={(e) => setSearchId(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        label={t('productManagement.searchByName')}
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        fullWidth
                    />
                    <Button variant="contained" color="primary" onClick={handleSearch}>
                        {t('productManagement.search')}
                    </Button>
                </Box>

                {selectedProducts.length > 0 && (
                    <Toolbar>
                        <Button variant="contained" color="secondary" onClick={confirmBulkDelete}>
                            {t('productManagement.bulkDelete')}
                        </Button>

                    </Toolbar>
                )}

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        <TableContainer component={Paper} sx={{ borderRadius: '16px' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
                                                checked={products.length > 0 && selectedProducts.length === products.length}
                                                onChange={handleSelectAll}
                                            />
                                        </TableCell>

                                        <TableCell>{t('productManagement.state')}</TableCell>
                                        <TableCell>{t('productManagement.name')}</TableCell>
                                        <TableCell>{t('productManagement.category')}</TableCell>
                                        <TableCell>{t('productManagement.type')}</TableCell>
                                        <TableCell>{t('productManagement.price')}</TableCell>
                                        <TableCell>{t('productManagement.stock')}</TableCell>
                                        <TableCell>{t('productManagement.variants')}</TableCell>
                                        <TableCell>{t('productManagement.brand')}</TableCell>
                                        <TableCell>{t('productManagement.rating')}</TableCell>
                                        <TableCell>{t('productManagement.discount')}</TableCell>
                                        <TableCell>{t('productManagement.weight')}</TableCell>
                                        <TableCell>{t('productManagement.actions')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {products.map((product) => (
                                        <TableRow key={product._id}>

                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={selectedProducts.includes(product._id)}
                                                    onChange={() => handleSelectProduct(product._id)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={product.isActive}
                                                    onChange={() => handleToggleActive(product._id, product.isActive)}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'Toggle product active state' }}
                                                />
                                            </TableCell>
                                            {editingProductId === product._id ? (
                                                <>
                                                    {/* Edit Form */}
                                                    <TableCell>
                                                        <TextField
                                                            name="name"
                                                            value={editedProduct.name}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="category"
                                                            value={`${editedProduct.category.mainCategory} - ${editedProduct.category.subCategory}`}
                                                            disabled
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            select
                                                            name="productType"
                                                            label="Product Type"
                                                            value={editedProduct.productType}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="for_sale">For Sale</MenuItem>
                                                            <MenuItem value="reloved">Reloved</MenuItem>
                                                            <MenuItem value="redeemable">Redeemable</MenuItem>
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="price"
                                                            type="number"
                                                            value={editedProduct.price}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="TotalStock"
                                                            type="number"
                                                            //value={editedProduct.totalStock}
                                                            onChange={handleChange}
                                                            fullWidth
                                                            disabled
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="variants"
                                                            value={editedProduct.variants.map(variant => `${variant.size} / ${variant.color}`).join(', ')}
                                                            disabled
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="brand"
                                                            value={editedProduct.brand}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="averageRating"
                                                            type="number"
                                                            value={editedProduct.averageRating}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="discount"
                                                            type="number"
                                                            value={editedProduct.discount}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            name="weight"
                                                            type="number"
                                                            value={editedProduct.weight}
                                                            onChange={handleChange}
                                                            fullWidth
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={handleSaveEdit} color="primary">
                                                            <SaveIcon />
                                                        </IconButton>
                                                        <IconButton onClick={handleCancelEdit} color="secondary">
                                                            <CancelIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    {/* View Mode */}
                                                    <TableCell>{product.name}</TableCell>
                                                    <TableCell>{`${product.category.mainCategory} - ${product.category.subCategory}`}</TableCell>
                                                    <TableCell>{product.productType}</TableCell>
                                                    <TableCell>{product.price} MAD</TableCell>
                                                    <TableCell>{product.totalStock}</TableCell>
                                                    <TableCell>{product.variants.map(variant => `${variant.size} / ${variant.color}`).join(', ')}</TableCell> {/* New */}
                                                    <TableCell>{product.brand || '-'}</TableCell> {/* New */}
                                                    <TableCell>{product.averageRating.toPrecision(3) || '-'}</TableCell> {/* New */}
                                                    <TableCell>{product.discount || 0}%</TableCell> {/* New */}
                                                    <TableCell>{product.weight || '-'} kg</TableCell> {/* New */}
                                                    <TableCell>
                                                        <IconButton onClick={() => handleEditClick(product)} color="primary">
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => openDeleteDialog(product)} color="secondary">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                        {!searchId && !searchName && (
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
                            />
                        )}
                    </Box>
                )}
            </Paper>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">{t('productManagement.confirmDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        {t('productManagement.deleteConfirmationMessage', { productName: productToDelete?.name })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        {t('productManagement.cancel')}
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        {t('productManagement.delete')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={bulkDeleteDialogOpen}
                onClose={() => setBulkDeleteDialogOpen(false)}
                aria-labelledby="bulk-delete-dialog-title"
                aria-describedby="bulk-delete-dialog-description"
            >
                <DialogTitle id="bulk-delete-dialog-title">{t('productManagement.confirmBulkDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="bulk-delete-dialog-description">
                        {t('productManagement.bulkDeleteConfirmationMessage', { count: selectedProducts.length })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBulkDeleteDialogOpen(false)} color="primary">
                        {t('productManagement.cancel')}
                    </Button>
                    <Button onClick={handleBulkDelete} color="secondary" autoFocus>
                        {t('productManagement.delete')}
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Snackbar for Success/Error Messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={error ? 'error' : 'success'}>
                    {error || snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default ProductList;
