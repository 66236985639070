import React, { useState } from 'react';
import { Box, Container, IconButton, Drawer } from '@mui/material';
import ProfileSidebar from './ProfileSidebar';
import { Route, Routes } from 'react-router-dom';
import EditProfile from './EditProfile';
import OrderHistory from './OrderHistory';
import AccountSettings from './AccountSettings';
import FavoriteProducts from './FavoriteProducts';
import ReferralSettings from './ReferralSettings'; // Import the new ReferralSettings component
import MenuIcon from '@mui/icons-material/Menu';

function Profile() {
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    return (
        <Container maxWidth="xl" sx={{ display: 'flex', paddingLeft: 0, paddingRight: 0, flexDirection: { xs: 'column', md: 'row' }, pt:4, px: 2, mb:4 }}>
            {/* Sidebar for Desktop */}
            <Box sx={{ display: { xs: 'none', md: 'block' }, flexBasis: '20%', flexGrow: 1, flexShrink: 0, minWidth: 240 }}>
                <ProfileSidebar />
            </Box>
            
            {/* Menu Button for Mobile */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-start', mt: 2 }}>
                <IconButton onClick={toggleDrawer}>
                    <MenuIcon />
                </IconButton>
            </Box>

            {/* Drawer for Mobile */}
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer}
                sx={{ '& .MuiDrawer-paper': { width: '80%', maxWidth: 300 } }}
            >
                <ProfileSidebar />
            </Drawer>

            {/* Main Content */}
            <Box sx={{ flexBasis: { xs: '100%', md: '80%' }, flexGrow: 3, paddingLeft: { xs: 0, md: 3 }, mt: { xs: 2, md: 0 } }}>
                <Routes>
                    <Route path="edit" element={<EditProfile />} />
                    <Route path="orders" element={<OrderHistory />} />
                    <Route path="settings" element={<AccountSettings />} />
                    <Route path="favorites" element={<FavoriteProducts />} />
                    <Route path="referrals" element={<ReferralSettings />} /> {/* Add the route for ReferralSettings */}
                </Routes>
            </Box>
        </Container>
    );
}

export default Profile;
