import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography, Alert, InputAdornment, Avatar, CircularProgress } from '@mui/material';
import { Email, Person, Message, Subject } from '@mui/icons-material';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation

function ContactForm() {
    const { t } = useTranslation(); // Initialize useTranslation

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '', // New subject field
        message: ''
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false); // State for loading

    const { name, email, subject, message } = formData;

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        try {
            await axios.post('/api/contact-us', formData); // Updated route to match backend
            setIsSubmitted(true);
            setError(null);
            setFormData({ name: '', email: '', subject: '', message: '' });
        } catch (err) {
            setError(t('contactForm.error')); // Use translation for error message
            setIsSubmitted(false);
        } finally {
            setLoading(false); // Stop loading after submission
        }
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 5, textAlign: 'center', backdropFilter: 'blur(10px)', p: 4, backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' }}>
                <Avatar sx={{ bgcolor: 'primary.main', m: 'auto', width: 56, height: 56 }}>
                    <Message />
                </Avatar>
                <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                    {t('contactForm.title')}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                    {t('contactForm.description')}
                </Typography>
                {isSubmitted && <Alert severity="success" sx={{ mb: 3 }}>{t('contactForm.successMessage')}</Alert>}
                {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
                <form onSubmit={onSubmit}>
                    <TextField
                        label={t('contactForm.name')}
                        name="name"
                        value={name}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label={t('contactForm.email')}
                        name="email"
                        value={email}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        type="email"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label={t('contactForm.subject')} // New subject field
                        name="subject"
                        value={subject}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Subject />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        label={t('contactForm.message')}
                        name="message"
                        value={message}
                        onChange={onChange}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Message />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 3 }}
                        disabled={loading} // Disable button while loading
                    >
                        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('contactForm.sendMessage')}
                    </Button>
                </form>
            </Box>
        </Container>
    );
}

export default ContactForm;
