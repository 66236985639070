import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../AuthContext';
import { Box, TextField, Button, Typography, Grid, Paper, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Import useTranslation

function EditProfile() {
    const { t } = useTranslation(); // Initialize useTranslation
    const { authState, refetchUser } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: authState.user.name,
        email: authState.user.email,
        addresses: authState.user.addresses || [],
    });
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get('/api/auth/me', {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
            if (response.status === 200) {
                setFormData({
                    name: response.data.user.name,
                    email: response.data.user.email,
                    addresses: response.data.user.addresses || [],
                });
            }
        };
    
        fetchData();
    }, [authState.token]);

    const handleChange = (e, index = null) => {
        if (index !== null) {
            const updatedAddresses = formData.addresses.map((address, i) =>
                i === index ? { ...address, [e.target.name]: e.target.value } : address
            );
            setFormData({ ...formData, addresses: updatedAddresses });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleAddAddress = () => {
        setFormData({
            ...formData,
            addresses: [...formData.addresses, {
                fullName: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                postalCode: '',
                country: 'Morocco',
                phoneNumber: '',
                isDefault: false,
            }],
        });
    };

    const handleRemoveAddress = (index) => {
        setFormData({
            ...formData,
            addresses: formData.addresses.filter((_, i) => i !== index),
        });
    };

    const handleSetDefaultAddress = (index) => {
        const updatedAddresses = formData.addresses.map((address, i) => ({
            ...address,
            isDefault: i === index,
        }));
        setFormData({ ...formData, addresses: updatedAddresses });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.put(`/api/users/${authState.user._id}/profile`, formData, {
                headers: { Authorization: `Bearer ${authState.token}` },
            });
    
            if (response.status === 200) {
                refetchUser();
                alert(t('editProfile.updateSuccess'));
            } else {
                alert(t('editProfile.updateFailed'));
            }
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data.message === 'Email is already in use by another account') {
                alert(t('editProfile.emailInUse'));
            } else {
                console.error('Error updating profile:', error);
                alert(t('editProfile.updateError'));
            }
        }
    };

    return (
        <Box sx={{  boxSizing: 'border-box', backdropFilter: 'blur(10px)', p:4, backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '16px',boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)', minHeight:'80vh' }}>
            <Typography variant="h4" sx={{ mb: 4 }}>{t('editProfile.title')}</Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t('editProfile.name')}
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t('editProfile.email')}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            disabled
                            margin="normal"
                        />
                    </Grid>

                    {formData.addresses.map((address, index) => (
                        <Grid item xs={12} key={index}>
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                {t('editProfile.address')} {index + 1}
                                {address.isDefault && (
                                    <Typography component="span" sx={{ ml: 2, color: 'green' }}>
                                        ({t('editProfile.default')})
                                    </Typography>
                                )}
                            </Typography>
                            <TextField
                                label={t('editProfile.fullName')}
                                name="fullName"
                                value={address.fullName}
                                onChange={(e) => handleChange(e, index)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label={t('editProfile.addressLine1')}
                                name="addressLine1"
                                value={address.addressLine1}
                                onChange={(e) => handleChange(e, index)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label={t('editProfile.addressLine2')}
                                name="addressLine2"
                                value={address.addressLine2}
                                onChange={(e) => handleChange(e, index)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label={t('editProfile.city')}
                                name="city"
                                value={address.city}
                                onChange={(e) => handleChange(e, index)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label={t('editProfile.state')}
                                name="state"
                                value={address.state}
                                onChange={(e) => handleChange(e, index)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label={t('editProfile.postalCode')}
                                name="postalCode"
                                value={address.postalCode}
                                onChange={(e) => handleChange(e, index)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label={t('editProfile.country')}
                                name="country"
                                value={address.country}
                                onChange={(e) => handleChange(e, index)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label={t('editProfile.phoneNumber')}
                                name="phoneNumber"
                                value={address.phoneNumber}
                                onChange={(e) => handleChange(e, index)}
                                fullWidth
                                margin="normal"
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleRemoveAddress(index)}
                                    startIcon={<Delete />}
                                >
                                    {t('editProfile.remove')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSetDefaultAddress(index)}
                                >
                                    {t('editProfile.setAsDefault')}
                                </Button>
                            </Box>
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleAddAddress}
                            startIcon={<Add />}
                        >
                            {t('editProfile.addNewAddress')}
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                padding: '10px 20px',
                                fontSize: '16px',
                                mt: 4,
                            }}
                        >
                            {t('editProfile.saveChanges')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default EditProfile;
