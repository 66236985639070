import React, { useContext, useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    IconButton,
    Avatar,
    Tooltip,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Badge,
    Container,
    ListItemAvatar,
    Avatar as MuiAvatar,
    useMediaQuery,
    Menu,
    MenuItem,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    Person,
    AccountCircle,
    Favorite,
    ShoppingCart,
    Close,
    Remove,
    Add,
    Delete,
    Menu as MenuIcon,
    Language as LanguageIcon,
} from '@mui/icons-material';
import { AuthContext } from './AuthContext';
import { CartContext } from './CartContext';
import Logo from './assets/logo.svg';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';  // Import useTranslation

function Navbar() {
    const { authState, logout, refetchUser } = useContext(AuthContext);
    const { cartItems, removeFromCart, updateCartItemQuantity, clearCart } = useContext(CartContext);
    const [cartOpen, setCartOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null); // For language menu
    const location = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();  // Initialize useTranslation

    const total = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

    useEffect(() => {
        const loadCartAndUser = async () => {
            // Refresh the user information before interacting with the cart
            await refetchUser();
    
            if (!authState.loading && authState.isAuthenticated) {
                // Once the user is refreshed and authenticated, allow interaction with the cart
                setCartOpen(false); // Keep cart closed until this process is done
            }
        };
    
        loadCartAndUser();
    }, [authState.isAuthenticated]);
    

    const toggleCart = () => {
        if (authState.isAuthenticated) {
            setCartOpen(!cartOpen);
        } else {
            navigate('/login'); // Redirect to login if not authenticated
        }
    };

    const handleCheckout = () => {
        setCartOpen(false);
        navigate('/checkout');
    };

    const handleMobileMenuOpen = () => {
        setMobileMenuOpen(true);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuOpen(false);
    };

    const handleLanguageMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        handleLanguageMenuClose();
    };

    const renderMenuButtons = (isDrawer = false) => (
        <>
            {isDrawer ? (
                <>
                    <ListItem
                        button
                        component={Link}
                        to="/"
                        sx={{
                            bgcolor: location.pathname === '/' ? 'accent.main' : 'transparent', // Changed to accent.main
                            borderRadius: 1,
                            px: 2,
                            py: 1,
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            color: location.pathname === '/' ? 'white' : 'inherit', // Text color white if selected
                            '&:hover': {
                                bgcolor: 'accent.light', // Adjust hover color
                            },
                            mb: 1,
                        }}
                    >
                        <ListItemText primary={t('navbar.home')} />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/products"
                        sx={{
                            bgcolor: location.pathname === '/products' ? 'accent.main' : 'transparent', // Changed to accent.main
                            borderRadius: 1,
                            px: 2,
                            py: 1,
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            color: location.pathname === '/products' ? 'white' : 'inherit', // Text color white if selected
                            '&:hover': {
                                bgcolor: 'accent.light', // Adjust hover color
                            },
                            mb: 1,
                        }}
                    >
                        <ListItemText primary={t('navbar.products')} />
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/re-loved"
                        sx={{
                            bgcolor: location.pathname === '/re-loved' ? 'accent.main' : 'transparent', // Changed to accent.main
                            borderRadius: 1,
                            px: 2,
                            py: 1,
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            color: location.pathname === '/re-loved' ? 'white' : 'inherit', // Text color white if selected
                            '&:hover': {
                                bgcolor: 'accent.light', // Adjust hover color
                            },
                            mb: 1,
                        }}
                    >
                        <ListItemText primary={t('navbar.reLoved')} />
                    </ListItem>

                    {authState.isAuthenticated && (
                        <ListItem
                            button
                            component={Link}
                            to="/earn-points"
                            sx={{
                                bgcolor: location.pathname === '/earn-points' ? 'accent.main' : 'transparent', // Changed to accent.main
                                borderRadius: 1,
                                px: 2,
                                py: 1,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                color: location.pathname === '/earn-points' ? 'white' : 'inherit', // Text color white if selected
                                '&:hover': {
                                    bgcolor: 'accent.light', // Adjust hover color
                                },
                                mb: 1,
                            }}
                        >
                            <ListItemText primary={t('navbar.earnPoints')} />
                        </ListItem>
                    )}

                    {authState.isAuthenticated && (
                        <ListItem
                            button
                            component={Link}
                            to="/redeem"
                            sx={{
                                bgcolor: location.pathname === '/redeem' ? 'accent.main' : 'transparent', // Changed to accent.main
                                borderRadius: 1,
                                px: 2,
                                py: 1,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                color: location.pathname === '/redeem' ? 'white' : 'inherit', // Text color white if selected
                                '&:hover': {
                                    bgcolor: 'accent.light', // Adjust hover color
                                },
                                mb: 1,
                            }}
                        >
                            <ListItemText primary={t('navbar.redeem')} />
                        </ListItem>
                    )}

                    {authState.isAuthenticated && authState.user.isAdmin && (
                        <ListItem
                            button
                            component={Link}
                            to="/add-product"
                            sx={{
                                bgcolor: location.pathname === '/add-product' ? 'accent.main' : 'transparent', // Changed to accent.main
                                borderRadius: 1,
                                px: 2,
                                py: 1,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                color: location.pathname === '/add-product' ? 'white' : 'inherit', // Text color white if selected
                                '&:hover': {
                                    bgcolor: 'accent.light', // Adjust hover color
                                },
                                mb: 1,
                            }}
                        >
                            <ListItemText primary={t('navbar.addProduct')} />
                        </ListItem>
                    )}
                </>

            ) : (
                <>
                    <Button
                        color="inherit"
                        variant='text'
                        component={Link}
                        to="/"
                        sx={{
                            //bgcolor: location.pathname === '/' ? 'secondary.main' : 'transparent',
                            borderBottom: location.pathname === '/' ? `3px solid ${theme.palette.accent.main}` : '0',
                            borderRadius: 0,
                            px: 1,
                            py: 1,
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            my: 1,
                        }}
                    >
                        {t('navbar.home')}
                    </Button>
                    <Button
                        color="inherit"
                        component={Link}
                        to="/products"
                        sx={{
                            //bgcolor: location.pathname === '/' ? 'secondary.main' : 'transparent',
                            borderBottom: location.pathname === '/products' ? `3px solid ${theme.palette.accent.main}` : '0',
                            borderRadius: 0,
                            px: 1,
                            py: 1,
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            my: 1,
                        }}
                    >
                        {t('navbar.products')}
                    </Button>
                    <Button
                        color="inherit"
                        component={Link}
                        to="/re-loved"
                        sx={{
                            //bgcolor: location.pathname === '/' ? 'secondary.main' : 'transparent',
                            borderBottom: location.pathname === '/re-loved' ? `3px solid ${theme.palette.accent.main}` : '0',
                            borderRadius: 0,
                            px: 1,
                            py: 1,
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            my: 1,
                        }}
                    >
                        {t('navbar.reLoved')}
                    </Button>
                    {authState.isAuthenticated && (
                        <Button
                            color="inherit"
                            component={Link}
                            to="/earn-points"
                            sx={{
                                //bgcolor: location.pathname === '/' ? 'secondary.main' : 'transparent',
                                borderBottom: location.pathname === '/earn-points' ? `3px solid ${theme.palette.accent.main}` : '0',
                                borderRadius: 0,
                                px: 1,
                                py: 1,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                my: 1,
                            }}
                        >
                            {t('navbar.earnPoints')}
                        </Button>
                    )}
                    {authState.isAuthenticated && (
                        <Button
                            color="inherit"
                            component={Link}
                            to="/redeem"
                            sx={{
                                //bgcolor: location.pathname === '/' ? 'secondary.main' : 'transparent',
                                borderBottom: location.pathname === '/redeem' ? `3px solid ${theme.palette.accent.main}` : '0',
                                borderRadius: 0,
                                px: 1,
                                py: 1,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                my: 1,
                            }}
                        >
                            {t('navbar.redeem')}
                        </Button>
                    )}
                    {authState.isAuthenticated && authState.user.isAdmin && (
                        <Button
                            color="inherit"
                            component={Link}
                            to="/add-product"
                            sx={{
                                //bgcolor: location.pathname === '/' ? 'secondary.main' : 'transparent',
                                borderBottom: location.pathname === '/add-product' ? `3px solid ${theme.palette.accent.main}` : '0',
                                borderRadius: 0,
                                px: 1,
                                py: 1,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                my: 1,
                            }}
                        >
                            {t('navbar.addProduct')}
                        </Button>
                    )}
                </>
            )}
        </>
    );

    const renderUserButtons = () => (
        <>
            <Tooltip title={t('navbar.favorites')}>
                <IconButton color="inherit" component={Link} to="/profile/favorites">
                    <Favorite />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('navbar.cart')}>
                <IconButton color="inherit" onClick={toggleCart}>
                    <Badge
                        badgeContent={cartItems.length}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: theme.palette.accent.main,
                                color: 'white',
                            },
                        }}
                    >
                        <ShoppingCart />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Tooltip title={t('navbar.profile')}>
                <IconButton color="inherit" component={Link} to="/profile/edit">
                    <Person />
                </IconButton>
            </Tooltip>
        </>
    );

    return (
        <>
            <AppBar
                position="sticky"
                sx={{
                    bgcolor: 'primary.main',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                    py: 1
                }}
            >
                <Container maxWidth>
                    <Toolbar sx={{ minHeight: 80, justifyContent: 'space-between', alignItems: 'center' }} >
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>

                            <img
                                onClick={() => { window.location.href = '/' }}
                                src={Logo}
                                alt="YESMALL"
                                style={{
                                    maxHeight: '40px',
                                    alignSelf: 'center',
                                    marginRight: '24px',
                                    marginLeft: '24px',
                                    cursor: 'pointer'  // This will show the hand cursor on hover
                                }}
                            />

                            {!isMobile && (
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    {renderMenuButtons()}
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <IconButton
                                color="inherit"
                                aria-label="language"
                                onClick={handleLanguageMenuOpen}
                            >
                                <LanguageIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleLanguageMenuClose}
                            >
                                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                                <MenuItem onClick={() => changeLanguage('fr')}>Français</MenuItem>
                                <MenuItem onClick={() => changeLanguage('ar')}>العربية</MenuItem>
                            </Menu>
                            {isMobile ? (
                                <>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={handleMobileMenuOpen}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Drawer
                                        anchor="right"
                                        open={mobileMenuOpen}
                                        onClose={handleMobileMenuClose}
                                        sx={{ '& .MuiDrawer-paper': { padding: 2 } }}
                                    >
                                        <Box
                                            sx={{ width: 250 }}
                                            role="presentation"
                                            onClick={handleMobileMenuClose}
                                            onKeyDown={handleMobileMenuClose}
                                        >
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                                <Typography variant="h6">{t('navbar.menu')}</Typography>
                                                <IconButton onClick={handleMobileMenuClose}>
                                                    <Close />
                                                </IconButton>
                                            </Box>
                                            <Divider sx={{ mb: 2 }} />
                                            <List sx={{ paddingX: 2 }}>
                                                {renderMenuButtons(true)}
                                                <Divider sx={{ my: 2 }} />
                                                {authState.isAuthenticated ? (
                                                    renderUserButtons()
                                                ) : (
                                                    <>
                                                        <Button
                                                            color="inherit"
                                                            component={Link}
                                                            to="/login"
                                                            sx={{ width: '100%', justifyContent: 'flex-start', textTransform: 'none', my: 1 }}
                                                        >
                                                            {t('navbar.login')}
                                                        </Button>
                                                        <Button
                                                            color="inherit"
                                                            component={Link}
                                                            to="/register"
                                                            sx={{ width: '100%', justifyContent: 'flex-start', textTransform: 'none', my: 1 }}
                                                        >
                                                            {t('navbar.register')}
                                                        </Button>
                                                    </>
                                                )}
                                            </List>
                                        </Box>
                                    </Drawer>
                                </>
                            ) : (
                                <>
                                    {authState.isAuthenticated ? (
                                        renderUserButtons()
                                    ) : (
                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                            <Button color="inherit" component={Link} to="/register">
                                                {t('navbar.register')}
                                            </Button>
                                            <Button color="inherit" component={Link} to="/login">
                                                {t('navbar.login')}
                                            </Button>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            {/* Cart Drawer */}
            <Drawer anchor="right" open={cartOpen} onClose={toggleCart}>
                <Box sx={{ width: 350, p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h6">{t('navbar.cart')}</Typography>
                        <IconButton onClick={toggleCart}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    {cartItems.length > 0 ? (
                        <>
                            <List>
                                {cartItems.map((item) => (
                                    <ListItem
                                        key={`${item._id}-${item.selectedColor}-${item.selectedSize}`}
                                        alignItems="flex-start"
                                        sx={{ mb: 2 }}
                                    >
                                        <ListItemAvatar>
                                            <MuiAvatar
                                                variant="square"
                                                src={item.image ? `/api/images/${item._id}/${item.image}` : '/noimg/300x300.png'}
                                                alt={item.name}
                                                sx={{ width: 50, height: 50, marginRight: 2 }}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primaryTypographyProps={{ variant: 'body1', noWrap: true }}
                                            primary={item.name}
                                            secondary={
                                                <>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {item.selectedColor}, {item.selectedSize}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {item.price * item.quantity} MAD
                                                    </Typography>
                                                </>
                                            }
                                            sx={{ maxWidth: '150px' }}
                                        />
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    updateCartItemQuantity(
                                                        item._id,
                                                        item.quantity - 1,
                                                        item.selectedColor,
                                                        item.selectedSize
                                                        
                                                    )
                                                }
                                            >
                                                <Remove fontSize="small" />
                                            </IconButton>
                                            <Typography>{item.quantity}</Typography>
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    updateCartItemQuantity(
                                                        item._id,
                                                        item.quantity + 1,
                                                        item.selectedColor,
                                                        item.selectedSize
                                                        
                                                    )
                                                }
                                            >
                                                <Add fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    removeFromCart(
                                                        item._id,
                                                        item.selectedColor,
                                                        item.selectedSize
                                                    )
                                                }
                                            >
                                                <Delete fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                            <Divider sx={{ mb: 2 }} />
                            <Box sx={{ textAlign: 'center', p: 2 }}>
                                <Typography variant="h6">
                                    {t('navbar.total')}: {total.toFixed(2)} MAD
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    onClick={handleCheckout}
                                >
                                    {t('navbar.checkout')}
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <Box sx={{ textAlign: 'center', mt: 4 }}>
                            <Typography variant="body1">{t('navbar.cartEmpty')}</Typography>
                        </Box>
                    )}
                </Box>
            </Drawer>
        </>
    );
}

export default Navbar;